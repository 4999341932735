import { orderBy } from 'lodash'
import IndexedDBStore from '@/store/stores/IndexedDBstore/IndexedDBStore'
import {
  DisplayedOnboardingForHighlightType,
  LastSeenHighlightDataType,
  DisplayedNewArrivalsType,
  DisplayedConfirmMissionRanking,
  DisplayedDateType,
} from '@/store/stores/IndexedDBstore/IndexedDBAccessorType'

type DisplayedConfirmMissionRankingKeyTypes =
  | 'DISPLAYED_CONFIRM_MONTHLY_RANKING'
  | 'DISPLAYED_CONFIRM_SEASON_RANKING'

/**
 * IndexedDBを操作するための処理を提供する。
 */
export default function IndexedDBAccessor() {
  /**
   * ハイライト・コメントに関するオンボーディングを表示したかどうかを設定する
   */
  const setDisplayedOnboardingForHighlight = (displayed: DisplayedOnboardingForHighlightType) => {
    IndexedDBStore.value.add('DISPLAYED_ONBOARDING_FOR_HIGHLIGHT', displayed)
  }

  /**
   * ハイライト・コメントに関するオンボーディングを表示したかどうかを取得する
   */
  const getDisplayedOnboardingForHighlight = async () => {
    const data = await IndexedDBStore.value.get<DisplayedOnboardingForHighlightType>(
      'DISPLAYED_ONBOARDING_FOR_HIGHLIGHT',
    )
    if (!data) return false
    return data.displayed
  }

  /**
   * 最後に見たハイライトの情報をレース単位で保存する
   * @param lastFetchedInfo ハイライト新着バッジ制御で利用する情報。対象レースの最後にハイライトを取得した日時が入っている。
   */
  const setLastSeenHighlightData = async (lastFetchedInfo: LastSeenHighlightDataType) => {
    const existingData = await IndexedDBStore.value.get<Array<LastSeenHighlightDataType>>(
      'LAST_SEEN_HIGHLIGHT_DATA',
    )

    /**
     * IndexedDBから取得したデータを以下の条件でフィルタ
     * 保存対象と同じmatchIdのデータがある場合は、古いデータを削除する
     * 200件以上のデータがある場合は、古いデータを削除する
     */
    const filteredExistingData = existingData
      ? orderBy(existingData, 'date', 'desc')
          .filter((v) => v.matchId !== lastFetchedInfo.matchId)
          .slice(0, 200)
      : []

    const updatedData = [...filteredExistingData, lastFetchedInfo]

    if (existingData) {
      IndexedDBStore.value.update('LAST_SEEN_HIGHLIGHT_DATA', updatedData)
    } else {
      IndexedDBStore.value.add('LAST_SEEN_HIGHLIGHT_DATA', updatedData)
    }
  }

  /**
   * 対象レースの最後に見た新着通知表示用ハイライトの情報を取得する
   */
  const getTargetRaceLastSeenHighlightData = async (
    matchId: string,
  ): Promise<LastSeenHighlightDataType | undefined> => {
    const results = await IndexedDBStore.value.get<Array<LastSeenHighlightDataType>>(
      'LAST_SEEN_HIGHLIGHT_DATA',
    )

    return results?.find((v) => v.matchId === matchId)
  }

  /**
   * 機能アップデートのお知らせを表示したかどうかを設定する
   */
  const setDisplayedNewArrivals = async (versionInfo: DisplayedNewArrivalsType) => {
    const existingData = await IndexedDBStore.value.get<DisplayedNewArrivalsType>(
      'DISPLAYED_NEW_ARRIVALS',
    )
    if (existingData) {
      IndexedDBStore.value.update('DISPLAYED_NEW_ARRIVALS', versionInfo)
    } else {
      IndexedDBStore.value.add('DISPLAYED_NEW_ARRIVALS', versionInfo)
    }
  }

  /**
   * 機能アップデートのお知らせを表示したかどうかを取得する
   */
  const getDisplayedNewArrivals = async () => {
    const data = await IndexedDBStore.value.get<DisplayedNewArrivalsType>('DISPLAYED_NEW_ARRIVALS')

    return data?.version ?? ''
  }

  /**
   * 確定すみのランキングで表示した年もしくは年月を取得する
   * - DISPLAYED_CONFIRM_MONTHLY_RANKINGを指定すれば、月間の値
   * - DISPLAYED_CONFIRM_SEASON_RANKINGを指定すれば、シーズンの値
   */
  const getDisplayedConfirmMissionRanking = async (key: DisplayedConfirmMissionRankingKeyTypes) => {
    const data = await IndexedDBStore.value.get<DisplayedConfirmMissionRanking>(key)
    if (!data) return null
    return data.displayed
  }

  /**
   * ミッション＞ランキング一覧で順位が確定し表示した年もしは年月を設定する
   * すでにkeyが存在していれば更新、存在していなければ新規追加する
   */
  const setDisplayedConfirmMissionRanking = async (
    key: DisplayedConfirmMissionRankingKeyTypes,
    value: number,
  ) => {
    const existingData = await getDisplayedConfirmMissionRanking(key)

    if (existingData) {
      IndexedDBStore.value.update(key, { displayed: value })
    } else {
      IndexedDBStore.value.add(key, { displayed: value })
    }
  }

  /**
   * 新着の特典通知を最後に表示した日時を取得する
   */
  const getNewRewardsLastDisplayedDate = async () => {
    const data = await IndexedDBStore.value.get<DisplayedDateType>(
      'NEW_REWARDS_LAST_DISPLAYED_DATE',
    )
    return data?.displayedDate ?? 0
  }

  /**
   * 新着の特典通知を最後に表示した日時を設定する
   */
  const setNewRewardsLastDisplayedDate = async (displayedDate: DisplayedDateType) => {
    const existingData = await getNewRewardsLastDisplayedDate()

    if (existingData) {
      IndexedDBStore.value.update('NEW_REWARDS_LAST_DISPLAYED_DATE', displayedDate)
    } else {
      IndexedDBStore.value.add('NEW_REWARDS_LAST_DISPLAYED_DATE', displayedDate)
    }
  }

  /**
   * ポイント有効期限のお知らせを最後に表示した日時を取得する
   */
  const getMissionPointExpirationLastDisplayedDate = async () => {
    const data = await IndexedDBStore.value.get<DisplayedDateType>(
      'MISSION_POINT_EXPIRATION_LAST_DISPLAYED_DATE',
    )
    return data?.displayedDate ?? 0
  }

  /**
   * ポイント有効期限のお知らせを最後に表示した日時を設定する
   */
  const setMissionPointExpirationLastDisplayedDate = async (displayedDate: DisplayedDateType) => {
    const existingData = await getMissionPointExpirationLastDisplayedDate()

    if (existingData) {
      IndexedDBStore.value.update('MISSION_POINT_EXPIRATION_LAST_DISPLAYED_DATE', displayedDate)
    } else {
      IndexedDBStore.value.add('MISSION_POINT_EXPIRATION_LAST_DISPLAYED_DATE', displayedDate)
    }
  }

  return {
    setDisplayedOnboardingForHighlight,
    getDisplayedOnboardingForHighlight,
    setLastSeenHighlightData,
    getTargetRaceLastSeenHighlightData,
    setDisplayedNewArrivals,
    getDisplayedNewArrivals,
    getDisplayedConfirmMissionRanking,
    setDisplayedConfirmMissionRanking,
    setNewRewardsLastDisplayedDate,
    getNewRewardsLastDisplayedDate,
    getMissionPointExpirationLastDisplayedDate,
    setMissionPointExpirationLastDisplayedDate,
  }
}
