




































































































































































import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  onUnmounted,
  PropType,
  ref,
  Ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import { now } from 'lodash'
import VueRouter from 'vue-router'
import I18n from '@/locales/I18n'
import RaceVideoPane from '@/components/RaceVideoPage/RaceVideoPane.vue'
import RaceVideoDataViewPane from '@/components/RaceVideoPage/RaceVideoDataViewPane.vue'
import RaceVideoHighlightsPane from '@/components/RaceVideoPage/RaceVideoHighlightsPane.vue'
import RaceVideoRankingPane from '@/components/RaceVideoPage/RaceVideoRankingPane.vue'
import RaceVideoRadioPane from '@/components/RaceVideoPage/RaceVideoRadioPane.vue'
import RaceVideoGpsPane from '@/components/RaceVideoPage/RaceVideoGpsPane.vue'
import DataViewSelectMenuSection from '@/components/RaceVideoPage/common/DataViewSelectMenuSection.vue'
import SubVideoPane from '@/components/pc/RaceVideoPage/SubVideoPane.vue'
import StoreUtil from '@/store/StoreUtil'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Const from '@/util/Const'
import RadioSelectSection from '@/components/RaceVideoPage/common/RadioSelectSection.vue'
import { HighlightSlideMenuType } from '@/components/RaceVideoPage/RaceVideoHighlightsPane/parts/HighlightsSlideMenuParts.vue'
import DeviceOrientation from '@/util/deviceOrientation/DeviceOrientation'
import useRadio from '@/components/RaceVideoPage/hook/useRadio'
import APIResponse from '@/util/APIResponse'
import type { ViewAngleType } from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import DeviceInfo from '@/util/DeviceInfo'
import IndexedDBAccessor from '@/store/stores/IndexedDBstore/IndexedDBAccessor'
import usePermission from '@/components/hook/usePermission'
import FlowLineToPaidPlanModalSection from '@/components/common/modal/FlowLineToPaidPlanModalSection.vue'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import useUser from '@/store/hook/useUser'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import useCoupon from '@/store/hook/useCoupon'
import useGeolocation from '@/components/hook/useGeolocation'
import useErrorHandling from '@/components/hook/useErrorHandling'
import useAvailableArea from '@/store/hook/useAvailableArea'
import useOneTimePassContract from '@/components/MypageOneTimePassConfirmPage/hook/useOneTimePassContract'
import useOneTimePassErrorHandling from '@/components/MypageOneTimePassConfirmPage/hook/useOneTimePassErrorHandling'
import useHistory from '@/store/hook/useHistory'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'

export type DataType = {
  radioVoiceEnabled: boolean
  viewMode: string
  videoFullScale: boolean
  screenOrientationType: string
  racePlayerSelectModal: boolean
  highlightFilterSelect: boolean
  raceSelectEnabled: boolean
  deviceOs: string
  orientationTimer: number | null
  animateGpsMapCar: boolean
  inputComment: string
  togglePlayMode: string
  scrollToHighlightId: string
}
/**
 * 表示モード
 */
type ViewModeType = 'telemetry' | 'single' | 'highlight' | 'ranking' | 'radio' | 'gps'

/**
 * 画面固定 orientation 指定
 */
export type LockOrientationType = 'landscape' | 'portrait'

/**
 * レース動画再生+テレメトリーページ
 */
export default defineComponent({
  name: 'RaceVideoPage',
  components: {
    RaceVideoPane,
    RaceVideoDataViewPane,
    RaceVideoHighlightsPane,
    RaceVideoRankingPane,
    RaceVideoRadioPane,
    DataViewSelectMenuSection,
    RadioSelectSection,
    RaceVideoGpsPane,
    SubVideoPane,
    FlowLineToPaidPlanModalSection,
  },
  props: {
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    championshipMasterId: {
      type: String,
      required: true,
    },
    raceId: {
      type: String,
      required: true,
    },
    highlightId: {
      type: String,
      required: false,
    },
    /**
     * 動画再生時に初期設定する再生位置の実時間。UnixTime(ミリ秒)
     */
    movieStartActualTime: {
      type: Number,
      required: false,
    },
    /**
     * 動画再生時に初期設定するドライバーのID
     */
    driverId: {
      type: String,
      required: false,
    },
    /**
     * サブ動画表示
     */
    hasSubVideo: {
      type: Boolean,
      required: false,
    },
    /**
     * 音量調整機能
     */
    hasVolumeControl: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      /**
       * 無線音声 ON/OFFフラグ
       */
      radioVoiceEnabled: false,
      /**
       * 表示モード
       * 'single' or 'telemetry' or 'highlight' or 'ranking' or 'radio' or 'gps'
       */
      viewMode: 'telemetry' as ViewModeType,
      /**
       * 画面向き
       */
      screenOrientationType: '',
      /**
       * ビデオフルスケールフラグ
       */
      videoFullScale: false,
      /**
       * 選手選択モーダル表示フラグ
       */
      racePlayerSelectModal: false,
      /**
       * ハイライトシーンフィルター選択リスト表示フラグ
       */
      highlightFilterSelect: false,
      /**
       * レース選択 開閉フラグ
       */
      raceSelectEnabled: false,
      /**
       * 仕様os
       */
      deviceOs: '',
      /**
       * ボタン操作により、縦画面表示になった時のみ、起動されるタイマー、
       */
      orientationTimer: null,
      /**
       * 車両データアニメーションフラグ
       */
      animateGpsMapCar: true,
      /**
       * ハイライトコメントを入力するために動画再生画面に遷移してきたか
       * SFgo用表示名を登録または登録キャンセルして動画再生画面に戻ってくる時に 'true' が入る
       */
      inputComment:
        typeof this.$route.query.inputComment === 'string' ? this.$route.query.inputComment : '',
      /**
       * サーキットモードトグルボタンで再生モードを切り替えたかどうか
       * サーキットモードから動画再生モードに切り替え または 動画再生モードに切り替えからサーキットモードに切り替え
       */
      togglePlayMode:
        typeof this.$route.query.togglePlayMode === 'string'
          ? this.$route.query.togglePlayMode
          : '',
      /**
       * ハイライトID
       * 遷移先の画面から戻ってきた時に対象のハイライトにスクロールさせるために利用する
       */
      scrollToHighlightId: (this.$route.query.highlightId as string) || '',
    }
  },
  setup(_) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const loginStore = StoreUtil.useStore('LoginStore')
    // レース動画再生画面のストア
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    // レース選択画面のストア
    const topPageStore = StoreUtil.useStore('TopPageStore')
    // ワンタイムパス登録画面のストア
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const {
      fetchUser,
      fetchRaceVideoPageData,
      fetchHighlightAndCommentUsers,
      fetchTargetRace,
      targetRace,
      clearFetchRaceVideoPageData,
      isFetchedUserInfo,
      setViewAngle,
      fetchRaceData,
      isLiveNotStarted,
      isLive,
      fetchChampionship,
      fetchRadioData,
      lastFetchedRadioData,
      officialMovieInfo,
      currentPlayerOnBoardMovieInfo,
      savedLastFetchedHighlightForNotifyData,
      radioDataStore,
      highlightStore,
      highlightCommentStore,
    } = raceVideoPageStore
    const { videoPlayerStatus, videoPlayer, subVideoPlayer } = toRefs(
      raceVideoPageStore.raceVideoPageState,
    )
    const { fetchDisplayedChampionshipMovieInfo, fetchRaces, mainRace, sessionsWithOutRace } =
      topPageStore
    const { fetchContractInfo, ownContractInfo, clearOneTimePassPageData } = oneTimePassPageStore

    const { saveOneTimePassUsage } = useUser()
    const { fetchTargetCoupon, targetOneTimePass, clearCoupons } = useCoupon()
    const { fetchTargetAvailableArea, getTargetAvailableArea, clearAvailableAreas } =
      useAvailableArea()
    const {
      setDisplayedOnboardingForHighlight,
      getDisplayedOnboardingForHighlight,
      setLastSeenHighlightData,
      getTargetRaceLastSeenHighlightData,
    } = IndexedDBAccessor()
    const { canShowRace, getTargetRacePermission } = usePermission()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()
    const { createOneTimePassContract } = useOneTimePassContract()
    const { createContractError } = useOneTimePassErrorHandling()
    const { saveContractInfoHistory } = useHistory()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    const isPc = DeviceInfo.isForPC()
    const isCordova = DeviceInfo.isCordova()

    /**
     * ハイライト、ハイライトコメントどちらのスライドメニューを表示するか
     */
    const highlightSlideMenu = ref<HighlightSlideMenuType | null>(null)

    const showBlockConfirmModal = ref(false)

    /** サブ動画コンポーネントを再構築中か */
    const isRebuildingSubVideoPane = ref(false)

    /**
     * ハイライト画面でモーダルが表示されているかどうか
     */
    const showHighlightsModal = ref<boolean>(false)

    /**
     * ハイライト作成機能追加のお知らを表示するかどうか
     */
    const noticeHighlightOnboardingVisible = ref<boolean>(false)

    /**
     * 有料プランへのアップグレード案内、または非公開コンテンツへのアクセスを通知するモーダル フラグ
     */
    const flowLineToPaidPlanModalEnabled = ref(false)

    /**
     * 有料プランへのアップグレードを案内するモーダルに提供するコンテンツ
     */
    const flowLineToPaidPlanModalContents = {
      title: I18n.tc('common.flowLineToPaidPlan.title'),
      message: I18n.tc('common.flowLineToPaidPlan.message'),
      submitText: I18n.tc('common.flowLineToPaidPlan.submitText'),
      link:
        I18n.locale === 'ja'
          ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA
          : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN,
    }

    /**
     * 有料会員非公開かどうか
     */
    const isPaidUserPrivate = computed(() => {
      if (!targetRace.value?.additionalData?.publishing) {
        return false
      }

      return targetRace.value?.additionalData?.publishing.paidUser === false
    })

    /**
     * ハイライト画面でモーダルを表示
     */
    const openHighlightsModal = () => {
      showHighlightsModal.value = true
    }

    /**
     * ハイライト画面でモーダルを閉じる
     */
    const closeHighlightsModal = () => {
      showHighlightsModal.value = false
    }

    const handleHighlightNoticeCloseClick = () => {
      noticeHighlightOnboardingVisible.value = false

      setDisplayedOnboardingForHighlight({
        displayed: true,
      })
    }

    /**
     * サブ動画プレーヤーが利用できる状態かを判定する。
     * すでにサブ動画プレーヤーを初期化済みの場合、再生可能状態と判定する。
     * それ以外の場合は、以下の状態にサブ動画プレーヤーを利用可能と判定する。
     * - メイン/アングルの動画情報の取得が完了している
     * - メイン動画プレーヤーが再生可能な状態となっている
     */
    const isSubVideoPlayerReady = computed(
      () =>
        !!subVideoPlayer.value ||
        (videoPlayer &&
          officialMovieInfo &&
          currentPlayerOnBoardMovieInfo &&
          (videoPlayerStatus.value?.playerStatus === 'Running' ||
            videoPlayerStatus.value?.playerStatus === 'Updating')),
    )

    /**
     * サブ動画コンポーネントを再構築する。
     */
    const reCreateElement = async () => {
      isRebuildingSubVideoPane.value = true
      /** すぐにisRebuildingSubVideoPaneをfalseにすると、切り替えたドライバーの映像情報がraceVideoPageStore内で更新されていないため、1秒待ってからfalseにする。
       * 本来であれば、Pusher通知を使って、raceVideoPageStore内の処理で切り替えたドライバーの映像情報をストアに格納次第、通知を受け取るような作りにすると確実に処理できる。
       */
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })
      isRebuildingSubVideoPane.value = false
    }

    const { livePlayerRadioEnabled } = useRadio(true)

    // リアルタイムメッセージ
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()

    /**
     * ハイライトのオンボーディングを表示するかどうかを管理しているnoticeHighlightOnboardingVisibleの初期値を設定する
     */
    const initializeNoticeHighlightOnboardingVisible = async () => {
      const displayedOnboardingForHighlight = await getDisplayedOnboardingForHighlight()
      noticeHighlightOnboardingVisible.value = !displayedOnboardingForHighlight
    }

    /**
     * 有料プランへのアップグレード案内、または非公開コンテンツへのアクセスを通知するモーダルを表示
     */
    const showFlowLineToPaidPlanModal = () => {
      if (isPaidUserPrivate.value) {
        // 有料会員非公開動画の場合「非公開コンテンツ」を表示
        Object.assign(flowLineToPaidPlanModalContents, {
          title: I18n.tc('common.PrivateContent.title'),
          message: I18n.tc('common.PrivateContent.message'),
          submitText: '',
          link: '',
        })
      }

      // 有料プランへのアップグレードを案内、または非公開コンテンツへのアクセスを通知するモーダルを出す
      flowLineToPaidPlanModalEnabled.value = true
    }

    /**
     * 有料プランへのアップグレードを案内、または非公開コンテンツへのアクセス通知するモーダル 非表示
     * モーダルを閉じた後は、前の画面に遷移する。
     */
    const hideFlowLineToPaidPlanModal = () => {
      flowLineToPaidPlanModalEnabled.value = false
      router.go(-1)
    }

    /**
     * ワンタイムパスを利用できるエリアにいるかをチェックする
     * @param areaId エリアID
     */
    const checkIsInAreaCanUseOneTimePass = async (areaId: string) => {
      if (!areaId) {
        return false
      }

      let positionResult = null
      try {
        // 現在地を取得
        positionResult = await getCurrentPosition()
        Logger.info(
          `OneTimePassPageStore#checkIsInAreaCanUseOneTimePass currentGPS: ${positionResult.coords.latitude}, ${positionResult.coords.longitude}`,
        )
      } catch (e) {
        // HACK: ESLint: 'GeolocationPositionError' is not defined.(no-undef)エラーが表示さレてしまい、回避方法が不明なため、ひとまずエラーを無効化している
        // eslint-disable-next-line no-undef
        const positionError = e as GeolocationPositionError
        Logger.info(
          `OneTimePassConfirm#handlerSubmit getCurrentPositionError: code: ${positionError.code}, message: ${positionError.message}`,
        )
        createGeolocationError(positionError)
        return false
      }

      // 対象ワンタイムパスの利用可能エリア情報を取得
      await fetchTargetAvailableArea(areaId)
      const availableArea = getTargetAvailableArea(areaId)

      try {
        // ユーザーが利用可能エリア内にいるかを判定する
        return checkIsUserInArea(positionResult, availableArea)
      } catch (e) {
        MessageDialogStore.value.open({
          title: I18n.tc('common.errors.pointInPolygonError.title'),
          message: I18n.tc('common.errors.pointInPolygonError.message'),
        })
        return false
      }
    }

    /**
     * 動画を視聴できるかチェックする
     */
    const checkCanShowRace = async () => {
      // レース視聴権限をチェックし、権限がある場合は動画を再生できる
      if (canShowRace(targetRace.value, getTargetRacePermission(targetRace.value.raceType))) {
        // 視聴権限がある場合は視聴できる
        return true
      }

      /**
       * 以下、視聴権限がない場合の処理
       */
      const effectiveCouponCode =
        UserStore.value.user.value.additionalData?.effectiveCoupon?.couponCode ?? ''
      if (!effectiveCouponCode) {
        // 利用中のワンタイムパスを持っていない場合は視聴できない
        return false
      }

      clearCoupons()
      clearAvailableAreas()
      // ユーザー情報に保持しているコードに紐づくワンタイムパス情報を取得
      await fetchTargetCoupon(effectiveCouponCode)

      const targetOneTimePassItem = targetOneTimePass(effectiveCouponCode)
      if (!targetOneTimePassItem) {
        // ユーザー情報に保持しているコードに紐づくワンタイムパス情報が取得できない場合は視聴できない
        return false
      }

      return checkIsInAreaCanUseOneTimePass(targetOneTimePassItem.availableArea?.areaId ?? '')
    }

    let fetchRaceDataIntervalId: number
    let fetchRadioDataIntervalId: number
    onBeforeMount(async () => {
      // ワンタイムパス登録ページのデータをクリアする
      clearOneTimePassPageData()

      // レース視聴権限をチェックするために対象のレース情報を取得する
      const raceResult = await fetchTargetRace(_.raceId)
      if (!raceResult.isSuccess) {
        await MessageDialogStore.value.open({
          title: I18n.t('RaceListPage.errors.fetchTopPageDataError.title') as string,
          message: I18n.t('RaceListPage.errors.fetchTopPageDataError.message') as string,
          errorApiResponse: raceResult.response,
        })
        return
      }

      // レース視聴権限をチェックし、権限がない場合はモーダルを表示する
      const canShow = await checkCanShowRace()
      if (!canShow) {
        videoPlayer.value?.pause()
        showFlowLineToPaidPlanModal()
        return
      }

      initializeNoticeHighlightOnboardingVisible()

      if (appConfigStore.currentCircuitMode.value && DeviceInfo.isCordova()) {
        // サーキットモードの場合、バックグラウンドモードを有効にする
        window.cordova.plugins.backgroundMode.enable()
      }

      if (appConfigStore.currentCircuitMode.value) {
        // サーキットモードの際は中継映像を表示する
        setViewAngle('race')
      } else if (router.currentRoute?.query?.viewAngle) {
        // ルーティング時のクエリパラメタでviewAngleが指定されている場合、それに切り替える
        setViewAngle(router.currentRoute.query.viewAngle as ViewAngleType)
      }

      // アクセストークンを定期的に更新する処理を開始する
      loginStore.startPeriodicallyRefreshAccessToken()

      raceVideoPageStore.raceVideoPageState.loginId = loginStore.loginId
      raceVideoPageStore.raceVideoPageState.organizationId = loginStore.orgId
      raceVideoPageStore.currentChampionshipMasterId.value = _.championshipMasterId
      raceVideoPageStore.currentRaceId.value = _.raceId
      if (_.driverId) {
        raceVideoPageStore.selectedPlayerId.value = _.driverId
        raceVideoPageStore.setViewAngle('player')
      }

      const lastSeenDate = (await getTargetRaceLastSeenHighlightData(_.raceId))?.date ?? 0
      const [results, publicHighlightCountResult, notPublicHighlightCountResult] =
        await Promise.all([
          fetchRaceVideoPageData(),
          highlightStore.fetchNewHighlightCount(_.raceId, true, lastSeenDate),
          highlightStore.fetchNewHighlightCount(_.raceId, false, lastSeenDate),
        ])

      const race = [...mainRace.value, ...sessionsWithOutRace.value]?.[0]
      if (
        !race ||
        race.championshipMasterId !== raceVideoPageStore.currentChampionshipMasterId.value
      ) {
        // レース一覧画面で表示しているレース情報を動画再生画面のレース一覧でも利用しているため、raceVideoPageStoreの情報が古い場合は対象動画のレース一覧を取得する
        fetchRaces(raceVideoPageStore.currentChampionshipMasterId.value ?? '')
      }

      // 取得したハイライト日時を一時退避データに保存
      savedLastFetchedHighlightForNotifyData.value = {
        matchId: _.raceId,
        date: now(),
      }

      const newHighlightCount =
        (publicHighlightCountResult.response?.data.count ?? 0) +
        (notPublicHighlightCountResult.response?.data.count ?? 0)
      if (newHighlightCount > 0) {
        // 新着通知アイコンを表示する
        raceVideoPageStore.hasNewHighlight.value = true
      }

      const failedResponse = results.find((response) => !response.isSuccess)
      if (failedResponse) {
        await MessageDialogStore.value.open({
          title: I18n.t('RaceListPage.errors.fetchTopPageDataError.title') as string,
          message: I18n.t('RaceListPage.errors.fetchTopPageDataError.message') as string,
          errorApiResponse: failedResponse.response,
        })
      }
      if (highlightCommentStore.highlightCommentUserIds.value.length > 0) {
        const fetchHighlightAndCommentUsersResult = await fetchHighlightAndCommentUsers()
        const failedResult = fetchHighlightAndCommentUsersResult.find(
          (result) => !result?.isSuccess,
        )
        if (failedResult) {
          // ハイライトにコメントしたユーザー情報の取得失敗メッセージを出す
          await MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.fetchHighlightCommentUserError.title') as string,
            message: I18n.t(
              'RaceVideoPage.errors.fetchHighlightCommentUserError.message',
            ) as string,
          })
        }
      }

      // レースに関連するリアルタイムメッセージを受信
      await initRTM()
      // 最大同時接続数制御
      subscribeRTM('login', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `RaceVideoPage#subscribeRTM: Receive manage session. event: ${JSON.stringify(data)}`,
        )
        if (data.table === 'manage_session' && data.type === 'deleted') {
          // APIをcall
          const fetchUserResult = await fetchUser(loginStore.loginId)
          // 同時ログイン上限数を超えている場合
          if (
            fetchUserResult.response?.data.error_code ===
            APIResponse.ERROR_CODE.SESSION_LIMIT_EXCEEDED
          ) {
            // NOTE: onUnmounted時にストアのデータが初期化されるが、
            // 強制ログアウト後に音声のみ再生される挙動があったため、動画再生を停止
            videoPlayer.value?.pause()
          }
        }
      })
      subscribeRTM('race', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `RaceVideoPage#subscribeRTM: Receive race event. event: ${JSON.stringify(data)}`,
        )
        if (data.table === 'radio-data') {
          if (isLive.value) {
            // 無線データの追加を受信した場合、無線データを再取得する
            // 対象のレースがライブ配信中の場合は無線データが連携されるため、無線データの追加受信がある
            await fetchRadioData()
          }
        } else {
          await fetchRaceData()
        }
      })
      subscribeRTM('operation', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `RaceVideoPage#subscribeRTM: Receive operation event. event: ${JSON.stringify(data)}`,
        )
        if (raceVideoPageStore.raceVideoPageState.championshipMasterId !== null) {
          await fetchChampionship(raceVideoPageStore.raceVideoPageState.championshipMasterId)
        }
      })
      // 対象のレースがライブ配信中の場合、無線データを定期的に取得する
      if (isLive.value) {
        fetchRadioDataIntervalId = window.setInterval(() => {
          if (lastFetchedRadioData.value && now() - lastFetchedRadioData.value >= 10000) {
            // 10秒以上無線交信データを受信していなければ、無線交信データを取得する
            fetchRadioData()
          }
        }, Const.RADIO_POLING_INTERVAL)
      }
      fetchRaceDataIntervalId = window.setInterval(() => {
        if (isLiveNotStarted.value || appConfigStore.currentCircuitMode.value) {
          // ライブ配信開始が開始していない、あるいは、サーキットモードの場合、定期的にレース情報を更新する
          // - ライブ配信開始前のレース情報取得は、対象のレースのライブ配信が開始されたことを検出するために行う。
          // - サーキットモードの場合のレース情報取得は、対象のレースのライブ配信が終了されたことを検出するために行う。
          fetchRaceData()
        }
      }, Const.RACE_DATA_POLING_INTERVAL)
    })

    /**
     * 動画を視聴している大会のメイン映像とオンボード映像（と英語の中継映像）の動画情報を取得
     */
    const fetchRelatedMovieInfo = async () => {
      const results = await fetchDisplayedChampionshipMovieInfo()

      const fetchMovieInfoFailed = results && results.flat().find((response) => !response.isSuccess)
      if (fetchMovieInfoFailed) {
        // コンテンツ情報取得に失敗した場合再生ボタンを押せなくなるため、エラーを表示する
        await MessageDialogStore.value.open({
          title: I18n.tc('RaceListPage.errors.fetchMovieInfoError.title'),
          message: I18n.tc('RaceListPage.errors.fetchMovieInfoError.message'),
          errorApiResponse: fetchMovieInfoFailed?.response,
        })
      }
    }

    onUnmounted(() => {
      // アクセストークンを定期的に更新する処理を停止する
      loginStore.stopPeriodicallyRefreshAccessToken()

      if (appConfigStore.currentCircuitMode.value && DeviceInfo.isCordova()) {
        // サーキットモードの場合、画面を離れた時にバックグラウンドモードを無効にする
        window.cordova.plugins.backgroundMode.disable()
      }

      // レースに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
      // 定期的なポーリングを停止
      if (fetchRaceDataIntervalId) {
        clearInterval(fetchRaceDataIntervalId)
      }
      if (fetchRadioDataIntervalId) {
        clearInterval(fetchRadioDataIntervalId)
      }
      // ストアのデータをクリア
      clearFetchRaceVideoPageData()
    })

    onUnmounted(() => {
      radioDataStore.clearAudioPlayer()
    })

    // サーキットモード切り替えを監視し、サーキットモードが切り替わった場合、動画再生画面を再描画する
    watch(
      () => appConfigStore.currentCircuitMode.value,
      (currentCircuitMode, previousCircuitMode) => {
        if (currentCircuitMode !== previousCircuitMode) {
          // 現在表示している動画再生画面に再度遷移することで、動画再生画面を再描画する。
          // VueRouterは、同じパスに遷移することはできないため、
          // 一度、レース選択画面に遷移して、動画再生画面に遷移する。
          const racePagePath = router.currentRoute.path
          router.replace({ name: 'TopPage' }).then(() => {
            router.replace({
              path: racePagePath,
              query: { viewAngle: 'race', togglePlayMode: 'true' },
            })
          })
        }
      },
    )

    if (isCordova) {
      // スマホアプリ起動の場合のみ実施
      // 動画プレイヤーの再生・停止状態を監視する。
      // - バックグラウンドモードを有効にすることで、アプリがバックグラウンドに移行しても動画プレイヤーを再生し続けることができる。
      watch(
        () => videoPlayerStatus.value?.playStatus,
        (newPlayStatus) => {
          if (appConfigStore.currentCircuitMode.value && newPlayStatus === 'PLAY') {
            // サーキットモード中 かつ 動画プレイヤーが再生された場合に、バックグラウンドモードを有効にする。
            window.cordova.plugins.backgroundMode.enable()
          } else if (appConfigStore.currentCircuitMode.value && newPlayStatus === 'PAUSE') {
            // サーキットモード中 かつ 動画プレイヤーが停止された場合に、バックグラウンドモードを無効にする。
            window.cordova.plugins.backgroundMode.disable()
          }
        },
      )
    }

    /**
     * ハイライト、ハイライトコメントのスライドメニューを開く
     */
    const openSlideMenu = (slideMenu: HighlightSlideMenuType) => {
      highlightSlideMenu.value = slideMenu
    }

    /**
     * ハイライト、ハイライトコメントのスライドメニューを閉じる
     */
    const closeSlideMenu = () => {
      highlightSlideMenu.value = null
    }

    /**
     * プラン登録なしのワンタイムパス利用時の処理
     * 利用するワンタイムパスをユーザー情報に保存する
     */
    const saveOneTimePassUsageOnUser = async (oneTimePassToBuUsed: CouponDocument) => {
      const loader = loading.show()

      // 利用中のワンタイムパスをユーザー情報に保存する
      const result = await saveOneTimePassUsage(
        UserStore.value.user.value,
        oneTimePassToBuUsed.couponCode ?? '',
      )

      if (!result.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageOneTimePass.inputPage.errors.useOneTimePassError.title'),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.useOneTimePassError.message',
          ),
        })
        return
      }

      // グローバルストアに保持するユーザー情報を更新
      await UserStore.value.fetchUserData(LoginStore.value.loginId)
      loader.hide()

      // 現在表示している動画再生画面に再度遷移することで、動画再生画面を再描画する。
      // VueRouterは、同じパスに遷移することはできないため、
      // 一度、レース選択画面に遷移して、動画再生画面に遷移する。
      const racePagePath = router.currentRoute.path
      router.replace({ name: 'TopPage' }).then(() => {
        router.replace({
          path: racePagePath,
        })
      })
    }

    /**
     * プラン登録ありのワンタイムパス利用時の処理
     */
    const registrationOneTimePassPlan = async () => {
      /**
       * サーキットなどの現地でのみ利用可能なワンタイムパスの場合の処理
       */
      const loader = loading.show()

      // 最新の契約情報を取得
      await fetchContractInfo()

      /** ワンタイムパスで契約情報契約プランを登録 */
      const result = await createOneTimePassContract(ownContractInfo.value.contractInfoId)

      if (!result.isSuccess) {
        loader.hide()
        await createContractError(result.response?.data)
        return
      }

      /**
       * 契約情報変更履歴を登録する
       * Pusher通知が動かないことも一応想定し、fetchUserContractInfoを実行する
       */
      await Promise.all([
        saveContractInfoHistory(
          ownContractInfo.value.contractInfoId ?? '',
          UserStore.value.user.value,
        ),
        ContractInfoStore.value.fetchUserContractInfo(),
      ])

      loader.hide()

      // 現在表示している動画再生画面に再度遷移することで、動画再生画面を再描画する。
      // VueRouterは、同じパスに遷移することはできないため、
      // 一度、レース選択画面に遷移して、動画再生画面に遷移する。
      const racePagePath = router.currentRoute.path
      router.replace({ name: 'TopPage' }).then(() => {
        router.replace({
          path: racePagePath,
        })
      })
    }

    return {
      setLastSeenHighlightData,
      showHighlightsModal,
      noticeHighlightOnboardingVisible,
      savedLastFetchedHighlightForNotifyData,
      isSubVideoPlayerReady,
      isRebuildingSubVideoPane,
      reCreateElement,
      isFetchedUserInfo,
      isFetchedRaceInfo: toRefs(raceVideoPageStore.raceVideoPageState).isFetchedRaceInfo,
      hasNewHighlight: raceVideoPageStore.hasNewHighlight,
      currentRaceId: raceVideoPageStore.currentRaceId as Ref<string | null>,
      setViewAngle,
      fetchRadioData,
      isLive,
      livePlayerRadioEnabled: livePlayerRadioEnabled as Ref<boolean>,
      radioDataStore,
      isCircuitMode: appConfigStore.currentCircuitMode,
      fetchRelatedMovieInfo,
      openHighlightsModal,
      closeHighlightsModal,
      handleHighlightNoticeCloseClick,
      highlightSlideMenu,
      openSlideMenu,
      closeSlideMenu,
      isPc,
      showBlockConfirmModal,
      flowLineToPaidPlanModalEnabled,
      flowLineToPaidPlanModalContents,
      isPaidUserPrivate,
      hideFlowLineToPaidPlanModal,
      saveOneTimePassUsageOnUser,
      registrationOneTimePassPlan,
    }
  },
  created() {
    this.radioDataStore.createAudioPlayer()

    /**
     * playTypeにhighlightが指定されている場合、選手の選択の有無に関わらずひとまずメイン動画を再生しておく
     */
    const q = this.$route.query
    if (q && q.playType === 'highlight') {
      this.setViewAngle('race')
    }
  },
  mounted() {
    const q = this.$route.query
    // オリエンテーション固定表示時指定
    if (this.lockOrientation !== undefined) {
      this.screenOrientationType = `${this.lockOrientation}-primary`
      if (!this.isPc) window.screen.orientation.lock(this.lockOrientation as LockOrientationType)

      // 初期表示モード指定
      if ((q.inputComment && q.inputComment === 'true') || this.scrollToHighlightId) {
        this.changeViewMode('highlight')
      } else {
        this.changeViewMode('telemetry')
      }

      return
    }

    this.screenOrientationType = window.screen.orientation.type
    // 仕様デバイスのOS確認
    this.checkIos()

    // iosの場合
    if (this.deviceOs === 'iphone') {
      if (this.screenOrientationType.indexOf('landscape') !== -1) {
        window.screen.orientation
          .lock('landscape')
          .catch(() =>
            Logger.debug(
              'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
            ),
          )
        this.changeViewMode('single')
      } else if (this.screenOrientationType.indexOf('portrait') !== -1) {
        window.screen.orientation
          .lock('portrait')
          .catch(() =>
            Logger.debug(
              'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
            ),
          )
      }
      // andoroid、pcの場合
    } else if (this.deviceOs === 'other') {
      if (this.screenOrientationType.indexOf('landscape') !== -1) {
        this.changeViewMode('single')
      }
    }

    window.addEventListener('orientationchange', this.changeOrientation)
    window.screen.orientation.unlock()

    if ((q.inputComment && q.inputComment === 'true') || this.scrollToHighlightId) {
      /**
       * 以下の場合に、ハイライト一覧を表示する
       * 1. ニックネーム登録（またはキャンセル）から戻ってきた
       * - NOTE: クエリーがinputCommentになっているが、ハイライト新規作成、ハイライトコメント入力時にニックネームが未登録のためマイページに遷移し、その後動画再生画面に戻ってきた時の動きを制御する処理で使っている
       * 2. 動画再生画面遷移時にハイライト一覧を表示したい画面からの遷移
       */
      this.changeViewMode('highlight')
    }
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.changeOrientation)
    if (this.orientationTimer) {
      clearInterval(this.orientationTimer)
    }
    window.screen.orientation
      .lock('portrait-primary')
      .catch(() =>
        Logger.debug(
          'Skip orientation lock due to screen.orientation.lock() is not available on this device.',
        ),
      )

    if (this.viewMode === 'highlight' && this.currentRaceId) {
      // 対象レースのハイライト一覧を最後に見た日時をIndexedDBに保存
      this.setLastSeenHighlightData({
        matchId: this.currentRaceId,
        date: now(),
      })
    }
  },
  methods: {
    /**
     * 無線音声 ON/OFFボタンが押下時処理
     * @event toggleRadioVoice
     */
    toggleRadioVoice(): void {
      if (this.raceSelectEnabled) {
        this.raceSelectEnabled = false
      }

      this.radioVoiceEnabled = !this.radioVoiceEnabled
      if (this.isLive && this.radioVoiceEnabled) {
        // 対象のレースがライブ配信中 かつ 選択中のドライバーの無線一覧を表示した場合、無線データ一覧を取得する
        this.fetchRadioData()
      }
    },
    /**
     * レース選択ボタン押下時の処理
     * @event toggleRadioVoice
     * @param state string(on / off)
     */
    async toggleRaceSelect(state?: string): Promise<void> {
      if (this.radioVoiceEnabled) {
        this.radioVoiceEnabled = false
      }

      if (state === 'on') {
        // 動画を視聴している大会のメイン映像とオンボード映像（と英語の中継映像）の動画情報を取得
        await this.fetchRelatedMovieInfo()
        this.raceSelectEnabled = true
      } else if (state === 'off') {
        this.raceSelectEnabled = false
      } else {
        if (!this.raceSelectEnabled) {
          // 動画を視聴している大会のメイン映像とオンボード映像（と英語の中継映像）の動画情報を取得
          await this.fetchRelatedMovieInfo()
        }
        this.raceSelectEnabled = !this.raceSelectEnabled
      }
    },
    /**
     * スクリーンオリエンテーション 取得
     */
    async changeOrientation() {
      this.screenOrientationType = window.screen.orientation.type
      if (this.screenOrientationType.indexOf('portrait') !== -1) {
        window.StatusBar?.show()
        this.changeViewMode(this.viewMode === 'single' ? 'telemetry' : this.viewMode)
      } else if (this.screenOrientationType.indexOf('landscape') !== -1) {
        window.StatusBar?.hide()
        this.changeViewMode('single')
        if (this.orientationTimer) {
          clearInterval(this.orientationTimer)
        }
      }
    },
    /**
     * 加速度センサーの座標を1秒ごとに検知し、縦に傾けた時、オリエンテーションロックを解除する。
     * こちらでlock('any')としているのはunlockした場合、iPhone端末だと縦に傾けた時、全画面モードになるバグが生じるため、unlockと挙動が酷似している
     * lock('any')を使用
     */
    setOrientationTimer(): void {
      this.orientationTimer = setInterval(() => {
        DeviceOrientation.getDeviceAcceleration().then((res) => {
          if (res && res.y > 8 && Math.abs(res.x) < 4 && this.orientationTimer) {
            clearInterval(this.orientationTimer)
            window.screen.orientation.lock('any')
          }
        })
      }, 1000)
    },
    /**
     * コンテンツ表示モード変更
     */
    changeViewMode(mode: string): void {
      this.viewMode = mode
      if (mode !== 'single') {
        this.videoFullScale = false
      }
      if (mode === 'highlight') {
        // 新着通知アイコンを非表示にする
        this.hasNewHighlight = false

        if (this.savedLastFetchedHighlightForNotifyData) {
          /**
           * 対象レースのハイライトを取得した日時をIndexedDBに保存
           * 動画再生画面遷移後にハイライト一覧を表示する操作をした場合、1度だけこの処理が実施される
           */
          this.setLastSeenHighlightData(this.savedLastFetchedHighlightForNotifyData)
          // 一時保存していたデータをクリア
          this.savedLastFetchedHighlightForNotifyData = null
        }
      }
    },
    /**
     * VIDEOフルスケール表示切り替え
     */
    changeFullScaleVideo(): void {
      this.videoFullScale = !this.videoFullScale

      if (this.videoFullScale) {
        this.changeViewMode('single')
      }
    },
    /**
     * 選手選択モーダル表示切り替え
     * @event showPlayerSelectModal
     */
    showPlayerSelectModal(status: boolean): void {
      this.racePlayerSelectModal = status
    },
    /**
     * ハイライトシーンフィルター表示フラグ切り替え
     * @event showHighlightFilterSelect
     */
    showHighlightFilterSelect(status: boolean): void {
      this.highlightFilterSelect = status
    },
    /**
     * OS判定
     * @event checkIos
     */
    checkIos(): void {
      if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0) {
        this.deviceOs = 'iphone'
      } else {
        this.deviceOs = 'other'
      }
    },
    /**
     * 無線 ライブ再生トグルボタン
     * @event toggleRadioLive
     */
    toggleRadioLive(): void {
      this.livePlayerRadioEnabled = !this.livePlayerRadioEnabled
    },
    /**
     * 動画シークバー touchStart時イベント
     * GPS MAP車両データのアニメーションを無効化
     */
    handleTouchStartSeekBar(): void {
      this.animateGpsMapCar = false
    },
    /**
     *
     * 動画シークバー touchEnd時イベント
     * GPS MAP車両データのアニメーションを有効化
     */
    handleTouchEndSeekBar(): void {
      this.animateGpsMapCar = true
    },
  },
})
