














import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import OneTimePassConfirmPane from '@/components/MypageOneTimePassConfirmPage/OneTimePassConfirmPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * ブラウザ版: マイページ: ワンタイムパス: 内容確認ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassConfirmPage',
  components: {
    GlobalNavigationPane,
    OneTimePassConfirmPane,
    SubHeaderSection,
  },
  methods: {
    moveToSignupComplete() {
      /**
       * 登録完了ページに遷移する
       */
      this.$router.replace({ name: 'MypageOneTimePassCompletePage' })
    },
    handlerCancel() {
      /**
       * ワンタイムパス登録画面へ遷移する
       */
      this.$router.replace({ name: 'MypageOneTimePassInputPage' })
    },
  },
})
