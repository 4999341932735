var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mypage-user-edit-pane",
      on: {
        click: function ($event) {
          _vm.openBirthdayMenuId = ""
        },
        scroll: function ($event) {
          _vm.openBirthdayMenuId = ""
        },
      },
    },
    [
      _c("user-edit-form-section", {
        ref: "userEditFormSection",
        attrs: { openBirthdayMenuId: _vm.openBirthdayMenuId },
        on: {
          "success-confirm": _vm.moveToConfirm,
          "show-cancel": _vm.showCancel,
          onClickBirthday: _vm.handleBirthdayClicked,
          onClickCloseBirthday: function ($event) {
            _vm.openBirthdayMenuId = ""
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }