var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "highlights-scene-section" },
    [
      _c("h3", { staticClass: "highlights-scene-section__title" }, [
        _c("i", { staticClass: "highlights-scene-section__title__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "19",
                height: "19",
                viewBox: "0 0 19 19",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { "clip-path": "url(#clip0_11934_284288)" } }, [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M1.5 2.5C0.947715 2.5 0.5 2.94772 0.5 3.5V15.5C0.5 16.0523 0.947716 16.5 1.5 16.5H17.5C18.0523 16.5 18.5 16.0523 18.5 15.5V3.5C18.5 2.94772 18.0523 2.5 17.5 2.5H1.5ZM2.5 4.5H4.5V6.5H2.5V4.5ZM8.5 5.5L15.5 9.5L8.5 13.5V5.5ZM4.5 12.5H2.5V14.5H4.5V12.5ZM2.5 8.5H4.5V10.5H2.5V8.5Z",
                    fill: "#00DBA6",
                  },
                }),
              ]),
              _c("defs", [
                _c("clipPath", { attrs: { id: "clip0_11934_284288" } }, [
                  _c("rect", {
                    attrs: {
                      width: "18",
                      height: "18",
                      fill: "white",
                      transform: "translate(0.5 0.5)",
                    },
                  }),
                ]),
              ]),
            ]
          ),
        ]),
        _c("span", { staticClass: "highlights-scene-section__title__text" }, [
          _vm._v(" " + _vm._s(_vm.$tc("HomePage.highlights.title")) + " "),
        ]),
      ]),
      _c(
        "carousel-list-wrapper-parts",
        {
          staticClass: "highlights-scene-section__scene-list",
          attrs: {
            carouselListType: "scene",
            enabledCarouselList: _vm.enabledCarouselList,
          },
        },
        [
          _vm._l(
            _vm.newArrivalHighlightAndCommentData,
            function (highlightOrComment) {
              return [
                highlightOrComment.highlightCard instanceof
                _vm.HighlightDocument
                  ? _c("highlights-card-parts", {
                      key:
                        "highlight-card" +
                        highlightOrComment.highlightCard.highlightId,
                      attrs: {
                        championships: _vm.championships,
                        raceList: _vm.raceList,
                        highlight: highlightOrComment.highlightCard,
                        retrieveNameUsersByUserId:
                          _vm.retrieveNameUsersByUserId,
                      },
                    })
                  : _c("scene-list-card-parts", {
                      key:
                        "highlight-comment-card" +
                        highlightOrComment.highlightCard.id,
                      staticClass: "highlights-scene-section__scene-item",
                      attrs: {
                        championships: _vm.championships,
                        raceList: _vm.raceList,
                        highlightComment: highlightOrComment.highlightCard,
                        highlightAdditional:
                          highlightOrComment.newArrivalAdditionalData,
                        highlightsById: _vm.highlightsById,
                        commentsByHighlightId: _vm.commentsByHighlightId,
                        retrieveNameUsersByUserId:
                          _vm.retrieveNameUsersByUserId,
                      },
                    }),
              ]
            }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }