















import type VueRouter from 'vue-router'
import { defineComponent, inject } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MypagePointExpirationDatePane from '@/components/MypagePointExpirationDatePage/MypagePointExpirationDatePane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'

/**
 * 戻るボタンを押した時の戻り先の型
 */
export type PointExpirationBackLinkType = 'mission' | 'mypage'

export default defineComponent({
  name: 'MypagePointExpirationDatePage',
  components: { GlobalNavigationPane, MypagePointExpirationDatePane, SubHeaderSection },
  setup() {
    const router = inject('router') as VueRouter

    const backLink: PointExpirationBackLinkType = router.currentRoute.query.backLink
      ? (router.currentRoute.query.backLink as PointExpirationBackLinkType)
      : 'mypage'

    return {
      backLink,
    }
  },
})
