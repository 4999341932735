var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-top-pane" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("LoginPage.signup"), backLink: "/login" },
      }),
      _c("div", { staticClass: "app-signup-top-pane__main" }, [
        _c("p", { staticClass: "app-signup-top-pane__title" }, [
          _vm._v(_vm._s(_vm.$tc("SignupPage.IndexPage.registerWithEmail"))),
        ]),
        _c(
          "div",
          { staticClass: "app-signup-top-pane__input" },
          [
            _c("mail-input-section", {
              attrs: { isSendingMail: false, hasMessage: false },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "app-signup-top-pane__services" }, [
          _c("p", { staticClass: "app-signup-top-pane__services__title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$tc("SignupPage.IndexPage.registerUsingService")) +
                " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "app-signup-top-pane__services__list" },
            [_c("service-button-list-parts")],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }