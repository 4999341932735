var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-pane" },
    [
      _c("login-header-section", {
        attrs: {
          loginType: _vm.loginType,
          pageTitle: _vm.$tc("LoginPage.pageTitle"),
        },
      }),
      _c("login-section", {
        attrs: {
          showLoginError: _vm.hasLoginError,
          showNetworkError: _vm.hasNetworkError,
          loginType: _vm.loginType,
        },
        on: { login: _vm.login, "input-change": _vm.removeError },
      }),
      false
        ? [
            _c("atom-input-button", {
              staticClass: "login-section__login-btn",
              attrs: {
                linkText: "google認可",
                colorType: "primary",
                isDisabled: false,
              },
              on: { push: _vm.googleAuth },
            }),
          ]
        : _vm._e(),
      _c("alert-overlay-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowAlertOverlay,
            expression: "isShowAlertOverlay",
          },
        ],
        attrs: {
          title: _vm.$tc("LoginPage.AlertOverlays.contractNotFound.title"),
          message: _vm.$tc("LoginPage.AlertOverlays.contractNotFound.message"),
          linkText: _vm.$tc(
            "LoginPage.AlertOverlays.contractNotFound.linkText"
          ),
          url: _vm.planSelectUrl,
        },
        on: { click: _vm.hideAlertOverlay, close: _vm.hideAlertOverlay },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }