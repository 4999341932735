var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mail-input-section" },
    [
      _vm.hasMessage
        ? _c("p", {
            staticClass: "mail-input-section__message",
            domProps: {
              innerHTML: _vm._s(_vm.$tc("SignupPage.IndexPage.message")),
            },
          })
        : _vm._e(),
      _c("ValidationProvider", {
        ref: "validationProvider",
        attrs: {
          slim: "",
          name: "email",
          rules: "required|email|duplicateEmail|authentication|network",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("fieldset-email-parts", {
                  attrs: {
                    showError: errors.length > 0,
                    errorMessage: errors[0],
                    required: false,
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "p",
        { staticClass: "mail-input-section__submit" },
        [
          _c("atom-input-button", {
            attrs: {
              "link-text": _vm.$tc("SignupPage.IndexPage.sendBtn"),
              isDisabled: _vm.isDisabled,
              theme: "light",
              colorType: "primary",
            },
            on: { push: _vm.onFormSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }