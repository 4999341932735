

























import { defineComponent } from '@vue/composition-api'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

export default defineComponent({
  name: 'ConfirmationSentSection',
  components: {
    ActionButtonsSection,
    AtomInputButton,
  },
  methods: {
    moveToEmailAuthentication() {
      this.$router.push({ path: '/signup/' })
    },
  },
})
