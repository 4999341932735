















































import VueRouter from 'vue-router'
import { computed, defineComponent, inject, ref, Ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import MembershipCardViewModeSection from '@/components/MypageTopPage/MembershipCardPane/MembershipCardViewModeSection.vue'
import MembershipCardEditModeSection from '@/components/MypageTopPage/MembershipCardPane/MembershipCardEditModeSection.vue'
import MembershipCardBgParts from '@/components/MypageTopPage/MembershipCardPane/parts/MembershipCardBgParts.vue'
import MembersPointSection from '@/components/MypageTopPage/MembershipCardPane/MembersPointSection.vue'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'
import { RoleForUserType } from '@/store/stores/loginStore/RoleType'
import { BadgeData } from '@/components/MypageTopPage/MembershipCardPane/parts/MemberBadgeItemParts.vue'
import Const, { MembersRankNamePointsType } from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import useUser from '@/store/hook/useUser'
import useUserIconImage from '@/components/MypageTopPage/hook/useUserIconImage'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import { UserPointWithGrantedSeasonYearType } from '@/store/hook/useUserPoint'

/**
 * ユーザーデータ型
 */
export type UserDataType = {
  name: string
  image: string
  userRole: RoleForUserType | undefined
  badgeList: Array<BadgeData>
  isFreePlan: boolean
}

/**
 * 会員ランクデータ一式 データ型
 */
export type MembersRankDataType = {
  point: number
  currentRank: MembersRankNamePointsType
  expirationPoint?: number
  expirationDate?: string
}

/**
 * マイページ: 会員証 コンテンツペイン
 */
export default defineComponent({
  name: 'MembershipCardPane',
  components: {
    MembershipCardViewModeSection,
    MembershipCardEditModeSection,
    MembershipCardBgParts,
    MembersPointSection,
  },
  props: {
    enabledListCarousel: {
      type: Boolean,
      default: false,
    },
    /**
     * 会員証を編集モードで表示するかどうか
     */
    isEditCardForHighlightComment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = inject('router') as VueRouter
    const loginStore = StoreUtil.useStore('LoginStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { isFreePlan } = contractInfoStore

    const { getBadgeDisplayYearsInfo, getCurrentRank } = useMembershipCard()
    const { uploadUserIconImage } = useUserIconImage()
    const { saveUserDisplayName } = useUser()
    const enabledEditMode = ref(props.isEditCardForHighlightComment)

    /** 会員ランク特典説明へのリンク */
    const rankBenefitsLink: Ref<string> = ref('')

    /**
     * 遷移元が動画再生画面でハイライト作成でニックネームが未登録で遷移してきたかどうか
     */
    const isTransitionCreateHighlight = !router.currentRoute.query.highlightId

    /**
     * バッジリストを取得する
     */
    const getBadgeList = () => {
      const results = []
      // sfgo-dev-supporterをロールに持つ場合、開発サポーターのバッジを付与する
      if (loginStore?.userRoles.includes('sfgo-dev-supporter')) {
        results.push({
          badgeType: 'supporter',
          label: '開発サポーター',
        })
      }
      // 登録日からの経過年数に応じたバッジ情報を取得する
      const elapsedYearResult = getBadgeDisplayYearsInfo(
        contractInfoStore?.ownOrganization.value?._createdDate,
      )
      if (elapsedYearResult) {
        results.push(elapsedYearResult)
      }
      return results
    }

    /**
     * ユーザーデータ
     */
    const userViewData = computed(() => ({
      name: mypagePageStore.user.value?.additionalData?.userDisplayName || '',
      image: CloudFrontUtil.getSignedUrl(mypagePageStore.user.value?.userPicture) || '',
      userRole: loginStore?.userRoles.find((r) =>
        Const.SFGO_ROLES.filter((v) => v !== 'sfgo-dev-supporter').includes(r),
      ),
      badgeList: getBadgeList(),
      isFreePlan,
    }))

    /**
     * ユーザーアイコン画像ファイルデータ
     */
    const userIconFileData = ref(null) as Ref<File | null>

    /**
     * ユーザープレビュー用データ
     */
    const userPreviewData = ref(cloneDeep(userViewData.value))

    /**
     * 会員ランクデータ
     */
    const membersRankData = computed<MembersRankDataType>(() => {
      // Mapの先頭のデータを取得する
      const firstEntry: [string, UserPointWithGrantedSeasonYearType] =
        mypagePageStore.userPointsWithExpirationByExpirationDate.value.entries().next().value ||
        (['', { point: 0, grantedSeasonYear: 0 }] as [string, UserPointWithGrantedSeasonYearType])
      const [expirationDate, userPointsWithExpiration] = firstEntry
      return {
        point: mypagePageStore.currentOwnedPoints.value ?? 0,
        currentRank: getCurrentRank(mypagePageStore.currentOwnedPoints.value),
        expirationDate,
        expirationPoint: userPointsWithExpiration.point,
      }
    })

    rankBenefitsLink.value = I18n.t(`MypagePage.MypageTopPage.membersRank.about`, {
      url:
        I18n.locale === 'ja'
          ? Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.JA
          : Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.EN,
    }).toString()

    return {
      enabledEditMode,
      userViewData,
      userPreviewData,
      userIconFileData,
      uploadUserIconImage,
      saveUserDisplayName,
      user: mypagePageStore.user,
      fetchUser: mypagePageStore.fetchUser,
      membersRankData,
      rankBenefitsLink,
      isTransitionCreateHighlight,
    }
  },
  methods: {
    /**
     * 動画再生画面のハイライトへ遷移する
     */
    transitionRaceVideo() {
      const q = this.$route.query
      if (q.championshipMasterId && q.raceId && q.isEditCard === 'true') {
        // 動画再生画面のハイライトから遷移してきた場合は遷移元のページへ戻る
        this.$router.push({
          path: `/race-video/${q.championshipMasterId}/${q.raceId}/?playType=highlight&inputComment=true&highlightId=${q.highlightId}&backToTop=true`,
        })
      }
    },
    /**
     * 編集モード切り替え
     */
    toggleEditMode() {
      this.transitionRaceVideo()
      this.enabledEditMode = !this.enabledEditMode

      if (!this.enabledEditMode) {
        // 編集モードを閉じる場合、編集前の状態に戻す
        this.userPreviewData = cloneDeep(this.userViewData)
        this.userIconFileData = null
      }
    },
    /**
     * ユーザー名をセットする
     * @param username
     */
    setUsername(username: string) {
      this.userPreviewData.name = username
    },
    /**
     * ユーザーのアイコン画像ファイルデータをセットする
     * @param fileData
     */
    setUserIconData(fileData: File) {
      this.userIconFileData = fileData
    },
    /**
     * 保存処理
     */
    async saveCardData() {
      const loader = this.$loading.show()

      /** 表示名を登録 */
      const userDisplayNameResult = await this.saveUserDisplayName(
        this.user,
        this.userPreviewData.name,
      )
      if (!userDisplayNameResult.isSuccess) {
        loader.hide()
        // 表示名の保存に失敗した場合、エラーメッセージを表示
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.errors.userDisplayName.title'),
          message: I18n.tc('MypagePage.errors.userDisplayName.message'),
          errorApiResponse: userDisplayNameResult.response,
        })
        return
      }

      /** ユーザーのアイコン画像アップロード */
      if (this.userIconFileData) {
        const uploadUserIconResult = await this.uploadUserIconImage(
          this.userIconFileData,
          this.user._id as string,
        )
        // アイコン画像の保存に失敗した場合、エラーメッセージを表示
        if (!uploadUserIconResult.isSuccess) {
          /**
           * 表示名の登録に成功しているため、更新後のユーザー情報を取得する
           */
          await this.fetchUser(LoginStore.value.loginId ?? '')

          loader.hide()
          await MessageDialogStore.value.open({
            title: I18n.tc('MypagePage.errors.uploadUserIcon.title'),
            message: I18n.tc('MypagePage.errors.uploadUserIcon.message'),
            errorApiResponse: uploadUserIconResult.response,
          })
          return
        }
      }

      await Promise.all([
        // マイページストア、グローバルストアに保持するユーザー情報を更新
        this.fetchUser(LoginStore.value.loginId ?? ''),
        UserStore.value.fetchUserData(LoginStore.value.loginId),
      ])

      loader.hide()
      this.enabledEditMode = false

      this.transitionRaceVideo()
    },
  },
})
