
















import { defineComponent, onBeforeMount } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import OneTimePassInputPane from '@/components/MypageOneTimePassInputPage/OneTimePassInputPane.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * マイページ: ワンタイムパス: 入力ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassInputPage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    OneTimePassInputPane,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { fetchContractPlans, clearOneTimePassPageData } = oneTimePassPageStore

    onBeforeMount(() => {
      // ワンタイムパス登録ページのデータをクリアする
      clearOneTimePassPageData()

      /** SFgo組織の契約プラン情報を取得する */
      fetchContractPlans()
    })
  },
  methods: {
    /**
     * ワンタイムパスの登録確認画面へ遷移
     */
    moveToOneTimePassConfirm() {
      this.$router.replace({ name: 'MypageOneTimePassConfirmPage' })
    },
  },
})
