










































































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import FieldsetPasswordParts from '@/components/common/form/FieldsetPasswordParts.vue'
import FieldsetNameParts from '@/components/common/form/FieldsetNameParts.vue'
import FieldsetNameRubyParts from '@/components/common/form/FieldsetNameRubyParts.vue'
import FieldsetDisplayNameParts from '@/components/common/form/FieldsetDisplayNameParts.vue'
import FieldsetBirthdayParts, {
  OpenBirthdayMenuIdType,
} from '@/components/common/form/FieldsetBirthdayParts.vue'
import FieldsetGenderParts from '@/components/common/form/FieldsetGenderParts.vue'
import FieldsetCountryParts from '@/components/common/form/FieldsetCountryParts.vue'
import FieldsetPrefecturesParts from '@/components/common/form/FieldsetPrefecturesParts.vue'
import FieldsetZipcodeParts from '@/components/common/form/FieldsetZipcodeParts.vue'
import FieldsetFavoritePlayerParts from '@/components/MypageUserInitPage/MypageUserInitPane/parts/FieldsetFavoritePlayerParts.vue'
import FieldsetFavoriteTeamParts from '@/components/MypageUserInitPage/MypageUserInitPane/parts/FieldsetFavoriteTeamParts.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import type { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 入力項目のプロパティ
 */
type InputPropertyType =
  | 'familyName'
  | 'firstName'
  | 'familyNameKana'
  | 'firstNameKana'
  | 'userDisplayName'
  | 'birthDay'
  | 'gender'
  | 'country'
  | 'prefectures'
  | 'zipcode'

/**
 * 初回ログイン 会員情報入力セクション
 * todo: スタイル調整 / コンポーネント間データ連携 / エラー表示 / emit等
 */
export default defineComponent({
  name: 'UserInitSection',
  components: {
    FieldsetEmailParts,
    FieldsetPasswordParts,
    FieldsetNameParts,
    FieldsetNameRubyParts,
    FieldsetDisplayNameParts,
    FieldsetBirthdayParts,
    FieldsetGenderParts,
    FieldsetCountryParts,
    FieldsetPrefecturesParts,
    FieldsetZipcodeParts,
    FieldsetFavoritePlayerParts,
    FieldsetFavoriteTeamParts,
    ActionButtonsSection,
    AtomInputButton,
  },
  props: {
    /**
     * 会員メールアドレス
     */
    mailAddress: {
      type: String,
      default: '',
    },
    /**
     * 会員言語
     */
    lang: {
      type: String as PropType<UserLang>,
      default: null,
    },
    /**
     * 入力対象の会員情報
     */
    userInfo: {
      type: Object as PropType<EditUserType>,
      required: true,
    },
    /**
     * お気に入り選手名
     */
    favoritePlayerName: {
      type: String,
      required: true,
    },
    /**
     * お気に入り選手画像
     */
    favoritePlayerImg: {
      type: String,
    },
    /**
     * お気に入りチーム名
     */
    favoriteTeamName: {
      type: String,
      required: true,
    },
    /**
     * お気に入りチーム画像
     */
    favoriteTeamImg: {
      type: String,
    },
    openBirthdayMenuId: {
      type: String as PropType<OpenBirthdayMenuIdType>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const loginStore = StoreUtil.useStore('LoginStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')

    /** 名前（ふりがな）の入力を行うかどうか */
    const userNameRubyEnabled = props.lang === 'ja' || !props.lang
    /** お住まいの都道府県の入力を行うかどうか */
    const showPrefecturesParts = computed(
      () => props.userInfo.country === 'JP' || !props.userInfo.country,
    )
    /** 郵便番号の入力を行うかどうか */
    const showZipcodeParts = computed(
      () => props.userInfo.country === 'JP' || !props.userInfo.country,
    )

    /** お気に入り選手、お気に入りチームを選択したかどうか */
    const isSelectedFavoriteItems = computed(
      () => !!props.favoritePlayerName && !!props.favoriteTeamName,
    )

    const handleBirthdayClicked = (menuId: string) => {
      emit('onClickBirthday', menuId)
    }

    return {
      contractInfoStore,
      userNameRubyEnabled,
      showPrefecturesParts,
      showZipcodeParts,
      isSelectedFavoriteItems,
      firstName: props.userInfo.firstName as string,
      familyName: props.userInfo.familyName as string,
      firstNameKana: props.userInfo.firstNameKana as string,
      familyNameKana: props.userInfo.familyNameKana as string,
      userDisplayName: props.userInfo.userDisplayName as string,
      birthDay: props.userInfo.birthDay,
      gender: props.userInfo.gender,
      country: props.userInfo.country || 'JP',
      prefectures: props.userInfo.prefecture,
      zipcode: props.userInfo.zipcode,
      email: props.mailAddress,
      password: loginStore.password,
      handleBirthdayClicked,
    }
  },
  methods: {
    /**
     * 確認画面へ遷移
     */
    moveToConfirm() {
      this.$router.replace({ name: 'MypageUserInitConfirmPage' })
    },
    /**
     * 入力対象の会員情報
     */
    editUserData(): EditUserType {
      return {
        firstName: this.firstName,
        familyName: this.familyName,
        firstNameKana: this.firstNameKana,
        familyNameKana: this.familyNameKana,
        userDisplayName: this.userDisplayName,
        birthDay: this.birthDay,
        gender: this.gender,
        country: this.country,
        prefecture: this.prefectures,
        zipcode: this.zipcode,
      }
    },
    /**
     * 入力対象が変更された場合
     */
    inputValue(value: string, property: InputPropertyType) {
      this[property] = value
      this.onSetEditUserInfo()
    },
    onSetEditUserInfo() {
      /**
       * 入力対象が変更されたことを通知する
       * @event onSetEditUserInfo
       */
      this.$emit('onChangeEditUserInfo', this.editUserData())
    },
  },
})
