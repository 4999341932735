import dayjs from 'dayjs'
import i18n from '@/locales/I18n'
/**
 * 日本語/英語で表示を分けるためのHook
 * ja.json、en.jsonを使わずに表示を分けたい場合は、ここに処理を記載する
 */
export default function useDisplayDependingOnLang() {
  /**
   * 表示する日付
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付（タイムスタンプ）
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'YYYY年MM月DD日'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM DD, YYYY'）
   */
  const getDisplayDate = (
    date: number | string | null | undefined,
    formatJa = 'YYYY/MM/DD',
    formatEn = 'MMM DD, YYYY',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }
    return i18n.locale === 'ja' ? dayjs(date).format(formatJa) : dayjs(date).format(formatEn)
  }

  /**
   * 表示する日付（日本時間基準で日付を表示）
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付（タイムスタンプ）
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'YYYY年MM月DD日'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM DD, YYYY'）
   */
  const getDisplayDateJa = (
    date: number | null | undefined,
    formatJa = 'YYYY/MM/DD',
    formatEn = 'MMM DD, YYYY',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }

    return i18n.locale === 'ja'
      ? dayjs(date).tz('Asia/Tokyo').format(formatJa)
      : dayjs(date).tz('Asia/Tokyo').format(formatEn)
  }

  /**
   * 表示する日付（dayjs.Dayjsの型でdateが渡ってくるケース）
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付（dayjs.Dayjs）
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'YYYY年MM月DD日'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM DD, YYYY'）
   */
  const getDisplayDateBasedDayjs = (
    date: dayjs.Dayjs,
    formatJa = 'YYYY/MM/DD',
    formatEn = 'MMM DD, YYYY',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }
    return i18n.locale === 'ja' ? date.format(formatJa) : date.format(formatEn)
  }

  /**
   * 表示する月
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付 ※請求対象年月（billingTargetYearMonth）がYYYYMM形式で返却されるため、文字列も許可している
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'MM月'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM' ←Janなど）
   */
  const getDisplayMonth = (
    date: number | string | null | undefined,
    formatJa = 'M',
    formatEn = 'MMM',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }
    return i18n.locale === 'ja' ? dayjs(date).format(formatJa) : dayjs(date).format(formatEn)
  }

  /**
   * 表示する月（日本時間基準で日付を表示）
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付 ※請求対象年月（billingTargetYearMonth）がYYYYMM形式で返却されるため、文字列も許可している
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'MM月'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM' ←Janなど）
   */
  const getDisplayMonthJa = (
    date: number | string | null | undefined,
    formatJa = 'M',
    formatEn = 'MMM',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }
    return i18n.locale === 'ja'
      ? dayjs(date).tz('Asia/Tokyo').format(formatJa)
      : dayjs(date).tz('Asia/Tokyo').format(formatEn)
  }

  /**
   * 表示する日付時間
   * 言語設定が日本語か英語かで表示する日付のフォーマットを分ける
   * @param date 日付（タイムスタンプ）
   * @param formatJa 日本語表示の場合の日付フォーマット（デフォルト 'YYYY/MM/DD HH:mm:ss'）
   * @param formatEn 英語表示の場合の日付フォーマット（デフォルト 'MMM DD, YYYY HH:mm:ss'）
   */
  const getDisplayDateTime = (
    date: number | null | undefined,
    formatJa = 'YYYY/MM/DD HH:mm:ss',
    formatEn = 'MMM DD, YYYY HH:mm:ss',
  ) => {
    if (!date) {
      // 日付が存在しない場合
      return ''
    }
    return i18n.locale === 'ja' ? dayjs(date).format(formatJa) : dayjs(date).format(formatEn)
  }

  return {
    getDisplayDate,
    getDisplayDateJa,
    getDisplayDateBasedDayjs,
    getDisplayMonth,
    getDisplayMonthJa,
    getDisplayDateTime,
  }
}
