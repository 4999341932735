import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * クレジットカード登録・更新を実施する際に使用するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3991961801/API
 */
export default class ContractInfoCreditCardDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ContractInfoCreditCardDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/contract_info'

  idAttr = 'contractInfoId'

  /**
   * 契約情報ID
   */
  contractInfoId: string | null = null

  /**
   * 支払い方法ID
   */
  paymentMethodId: string | null = null
}
