/**
 * OAuth 認可フロー処理を行うための機能を提供するユーティリティクラス。
 */
export default class OAuthHelper {
  /**
   * PKCE の code_verifier、OAuth 認可URLのstate や、OAuth 1.0a の oauth_nonce に指定するためのランダムな文字列を生成する。
   */
  static generateRandomString() {
    const array = new Uint32Array(28)
    window.crypto.getRandomValues(array)
    return Array.from(array, (dec) => `0${dec.toString(16)}`.slice(-2)).join('')
  }

  /**
   * 入力されたテキストのSHA256ハッシュ値を生成する。
   *
   * @param plain テキスト
   */
  static generateSha256Hash(plain: string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return window.crypto.subtle.digest('SHA-256', data)
  }
}
