












import { defineComponent, PropType, computed } from '@vue/composition-api'
import i18n from '@/locales/I18n'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'
import { PlayerLinksData } from '@/components/RaceListPage/RaceListPane/parts/RankingCardParts.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const playIconPath = require('@/assets/img/icon/icon_play__green.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const radioIconPath = require('@/assets/img/icon/icon_radio__green.svg')

/**
 * レース一覧画面: レース ランキング リンクコンポーネント
 */
export default defineComponent({
  name: 'RankingPlayerLinkParts',
  components: {
    AtomIconRouterButton,
  },
  props: {
    linkData: {
      type: Object as PropType<PlayerLinksData>,
      required: true,
    },
    winner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    /**
     * タイプに応じたテキスト出し分け
     * 'thisLap' | 'firstLap' | 'lastLap' | 'poleLap' | 'radio'
     * ※1位以外はテキストなし
     */
    const buttonText = computed(() =>
      props.winner ? i18n.tc(`RaceListPage.Information.play.${props.linkData.type}`) : '',
    )

    /**
     * 会員プランに応じてレース公開非公開の設定に応じたアイコンの出し分け
     */
    const buttonIcon = computed(() => {
      let rt = ''
      if (props.linkData.type === 'radio') {
        rt = radioIconPath
      } else {
        rt = playIconPath
      }
      return rt
    })
    return {
      buttonText,
      buttonIcon,
    }
  },
})
