import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 契約情報を操作するための処理を提供する。
 */
export default function useContractInfo() {
  // Collection modules
  const contractInfoCollectionModule = CollectionModule.createStore(ContractInfoDocument)

  /**
   * 契約情報を取得する。
   * @return APIレスポンス
   */
  const fetchContractInfo = () => contractInfoCollectionModule.fetch()

  /**
   * 取得した契約情報
   */
  const ownContractInfo = computed(
    () =>
      contractInfoCollectionModule.data.find((contractInfo) => contractInfo.isOwnOrgData) ||
      ({} as ContractInfoDocument),
  )

  /**
   * 契約情報の詳細を取得する
   * 請求情報取得で使用
   */
  const fetchTargetContractInfo = () => {
    const options = {
      targetId: ownContractInfo.value.contractInfoId,
    }
    return contractInfoCollectionModule.fetch(options)
  }

  // methods
  /**
   * 契約情報を更新する。
   * @return APIレスポンス
   */
  const updateContractInfo = async (requestData: ContractInfoDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${requestData.contractInfoId}`,
    }
    return contractInfoCollectionModule.save(requestData, options)
  }

  /**
   * 退会処理
   * @return APIレスポンス
   */
  const cancelContract = async (requestData: ContractInfoDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${requestData.contractInfoId}/cancel`,
    }
    return contractInfoCollectionModule.save(requestData, options)
  }

  /**
   * 無料会員/有料会員共通 契約情報登録用のパラメータ
   * @return {customerInfo: {mail: string, name: string}, currency: string}
   */
  const commonParams = () => {
    const email = LoginStore.value.loginName || ''
    return {
      currency: 'JPY',
      customerInfo: {
        mail: email,
        name: email.split('@')[0],
      },
      taxRegion: 'NONE',
    }
  }

  /**
   * 最初に登録する/契約情報用のパラメータ
   * @return ContractInfoDocument
   */
  // クレジットカードを登録した場合に、BE側でpayment.method、payment.yearlyMethodを'CREDIT_CARD'に変更するため、FEではひとまず'BANK_TRANSFER'を指定する
  const getContractInfoParams = (contractInfoData: ContractInfoDocument) =>
    new ContractInfoDocument({
      ...contractInfoData,
      ...commonParams(),
      payment: {
        method: 'BANK_TRANSFER',
        yearlyMethod: 'BANK_TRANSFER',
        paymentService: null,
      },
    })

  /**
   * 請求情報を作成または再計算する
   * @param contractInfoId 契約情報ID
   */
  const recalculateBillingInfo = async (contractInfoId: string) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${contractInfoId}/calculate/monthly`,
    }
    /**
     * 月契約の予定請求再計算APIはリクエストボディの指定がない
     * put処理を走らせるため、とりあえず適当な主キーが設定されたContractInfoDocumentを渡している
     */
    await contractInfoCollectionModule.save(
      new ContractInfoDocument({ contractInfoId: 'dummy' }),
      options,
    )
  }

  /**
   * 取得した契約情報をクリアする
   */
  const clearContractInfo = () => {
    contractInfoCollectionModule.clearData()
  }

  return {
    fetchContractInfo,
    fetchTargetContractInfo,
    ownContractInfo,
    updateContractInfo,
    cancelContract,
    getContractInfoParams,
    recalculateBillingInfo,
    clearContractInfo,
  }
}
