


























import { computed, defineComponent, PropType } from '@vue/composition-api'
import dayjs from 'dayjs'
import StoreUtil from '@/store/StoreUtil'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import useMovieContentsStatus from '@/components/hook/useMovieContentsStatus'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const playIconPath = require('@/assets/img/icon/icon_play__green.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const disabledPlayIconPath = require('@/assets/img/icon/icon_play__disabled.svg')
/* eslint-disable @typescript-eslint/no-var-requires */
const circuitPlayIconPath = require('@/assets/img/icon/icon_play__white.svg')

export default defineComponent({
  name: 'TimetableItemParts',
  components: { AtomIconRouterButton },
  props: {
    /**
     * レース情報
     */
    raceDataItem: {
      type: Object as PropType<RaceDocument>,
      required: true,
    },
    /**
     * コンテンツ動画情報
     */
    contentsInfos: {
      type: Array as PropType<Array<ContentsInfoDocument>>,
      required: true,
    },
    /**
     * アングル動画情報
     */
    angleMovieInfos: {
      type: Array as PropType<Array<AngleMovieInfoDocument>>,
      required: true,
    },
    /**
     * 予選レースに登録されているスタートイベント
     */
    qualifyingRaceStartEventList: {
      type: Array as PropType<Array<HighlightDocument>>,
    },
  },
  setup(props) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const { hasDistributedMovie } = useMovieContentsStatus()

    /**
     * ボタンを押せない状態かどうか
     */
    const isDisabled = computed(
      () =>
        !hasDistributedMovie(
          props.raceDataItem,
          props.contentsInfos,
          props.angleMovieInfos,
          props.qualifyingRaceStartEventList,
        ),
    )

    const enableCircuitMode = computed(() => {
      // ボタンが disabled だったらボタンの色を変更しない
      if (isDisabled.value) {
        return false
      }

      // サーキットモードになったら再生ボタンの色を変更する
      return appConfigStore.currentCircuitMode.value
    })

    /**
     * レースの開始時間を返す
     */
    const getStartTime = (startDate: number) => {
      if (!startDate) return ''
      return dayjs(startDate).tz('Asia/Tokyo').format('HH:mm')
    }

    return {
      enableCircuitMode,
      hasDistributedMovie,
      isDisabled,
      getStartTime,
    }
  },
  methods: {
    /**
     * 指定されたレースの動画再生画面に遷移する。
     * @param race レース
     */
    linkTo(race: RaceDocument) {
      if (race.highlightPlayInfo?.highlightId) {
        return `/race-video/${race.championshipMasterId}/${race.id}/${race.highlightPlayInfo.highlightId}/`
      }
      return `/race-video/${race.championshipMasterId}/${race.id}/`
    },
    /**
     * 動画再生画面アイコンの出し分け
     */
    setPlayIcon(): string {
      if (this.isDisabled) {
        return disabledPlayIconPath
      }
      return this.enableCircuitMode ? circuitPlayIconPath : playIconPath
    },
  },
})
