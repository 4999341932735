/**
 * 時刻を同期する機能を提供する。
 */
import LoginStore from '@/store/stores/loginStore/LoginStore'

export default class AdjustTime {
  /**
   * 端末の時間とサーバーの時間のずれを補正した現在時刻を取得する
   */
  static getAdjustedCurrentTime() {
    return new Date().getTime() + Math.round(LoginStore.value.timeDiff ?? 0)
  }
}
