<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      d="M3.25 14.25V12.75H4.75V7.5C4.75 6.4625 5.0625 5.5405 5.6875 4.734C6.3125 3.9275 7.125 3.3995 8.125 3.15V2.625C8.125 2.3125 8.2345 2.04675 8.4535 1.82775C8.6725 1.60875 8.938 1.4995 9.25 1.5C9.5625 1.5 9.82825 1.6095 10.0472 1.8285C10.2662 2.0475 10.3755 2.313 10.375 2.625V3.15C11.375 3.4 12.1875 3.92825 12.8125 4.73475C13.4375 5.54125 13.75 6.463 13.75 7.5V12.75H15.25V14.25H3.25ZM9.25 16.5C8.8375 16.5 8.48425 16.353 8.19025 16.059C7.89625 15.765 7.7495 15.412 7.75 15H10.75C10.75 15.4125 10.603 15.7658 10.309 16.0598C10.015 16.3538 9.662 16.5005 9.25 16.5ZM6.25 12.75H12.25V7.5C12.25 6.675 11.9563 5.96875 11.3687 5.38125C10.7812 4.79375 10.075 4.5 9.25 4.5C8.425 4.5 7.71875 4.79375 7.13125 5.38125C6.54375 5.96875 6.25 6.675 6.25 7.5V12.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NotificationNewsIcon',
})
</script>
