































































































import { computed, defineComponent, PropType, ref, Ref, toRefs, watch } from '@vue/composition-api'
import VideoPlayerSection from '@/components/RaceVideoPage/RaceVideoPane/VideoPlayerSection.vue'
import VideoOverlaySection from '@/components/RaceVideoPage/RaceVideoPane/VideoOverlaySection.vue'
import VideoInformationSection from '@/components/RaceVideoPage/RaceVideoPane/VideoInformationSection.vue'
import CircuitMapSection from '@/components/RaceVideoPage/RaceVideoPane/CircuitMapSection.vue'
import StoreUtil from '@/store/StoreUtil'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import { VideoPlayerError } from '@/util/videoplayer/VideoPlayerError'
import { AlertMessage } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useRanking from '@/components/RaceVideoPage/hook/useRanking'
import { LockOrientationType } from '@/views/RaceVideoPage/RaceVideoPage.vue'

export type DataType = {
  overlayVisible: boolean
  overlayTimer: number
  informationMessage: string
  mapViewEnabled: boolean
}

/**
 * レース動画再生画面 動画プレーヤーペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceVideoPane',
  components: {
    VideoPlayerSection,
    VideoOverlaySection,
    VideoInformationSection,
    CircuitMapSection,
  },
  data(): DataType {
    return {
      overlayVisible: false,
      overlayTimer: 0,
      mapViewEnabled: false,
      informationMessage:
        "I'm having trouble with the video.<br>Please wait a little bit.Please wait a little bit.<br>I'm having trouble with the video.",
    }
  },
  props: {
    /**
     * コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * 画面固定
     */
    lockOrientation: {
      type: String as PropType<LockOrientationType>,
    },
    /**
     * ビデオフルスケールフラグ
     */
    videoFullScale: {
      type: Boolean,
      default: false,
    },
    /**
     * スタートイベントのID
     * (ハイライト情報のID)
     */
    highlightId: {
      type: String,
      required: false,
    },
    /**
     * 動画再生時に初期設定する再生位置の実時間。UnixTime(ミリ秒)
     */
    movieStartActualTime: {
      type: Number,
      required: false,
    },
    /**
     * 車両データアニメーションフラグ
     */
    animateGpsMapCar: {
      type: Boolean,
      default: true,
    },
    /**
     * 音量調整機能
     */
    hasVolumeControl: {
      type: Boolean,
      default: false,
    },
    /**
     * サーキットモードトグルボタンで再生モードを切り替えたかどうか
     */
    togglePlayMode: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // ランキングデータは動画再生画面全般(RANKING/GPS/TELEMETRY)で利用するため、
    // このComponentでランキングデータのwatchを有効にする
    // それ以外の画面では、useRankingを利用する場合、watchは実施しないようにする
    useRanking(true)

    const appConfigStore = StoreUtil.useStore('AppConfigStore')

    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')
    const { getMovieInfo, isLiveNotStarted, isLive, setViewAngle, fetchRaceData } =
      raceVideoPageStore
    const { selectedHighlightId } = raceVideoPageStore.highlightStore
    const { videoPlayerStatus, videoPlayer, isFetchedRaceInfo, viewAngle, ignoreAlert } = toRefs(
      raceVideoPageStore.raceVideoPageState,
    )

    const videoPaused = computed(() => videoPlayerStatus?.value?.playStatus === 'PAUSE')
    const isVideoPlayerCanPlay = computed(
      () =>
        videoPlayerStatus.value?.playerStatus === 'Running' ||
        videoPlayerStatus.value?.playerStatus === 'Updating',
    )
    const viewContentsMode = computed(() => props.viewMode)
    const videoFullScaleStatus = computed(() => props.videoFullScale)

    const firstStartHighlightId = ref(props.highlightId)
    const autoPlayed = ref(false)

    watch(
      () => getMovieInfo.value,
      async (movieInfo, oldMovieInfo) => {
        if (ignoreAlert.value) {
          ignoreAlert.value = false
          return
        }
        Logger.debug(
          `RaceVideoPane#watch: Start check movie info process. movieInfo: ${movieInfo}, oldMovieInfo: ${oldMovieInfo}`,
        )

        // 動画のライブ状態を動画プレーヤーに反映する
        if (videoPlayer.value && movieInfo?.isLive) {
          videoPlayer.value.liveMode = movieInfo.isLiveBroadcasting()
        }

        if (movieInfo?.angleId === oldMovieInfo?.angleId) {
          Logger.debug(
            `RaceVideoPane#watch: Skip to check movie info due to same movie info changed`,
          )
          return
        }
        if (!movieInfo) {
          // 映像がない場合、最新の動画情報を取得して、現時点でも映像がないかどうかを確認する
          await fetchRaceData()
          if (getMovieInfo.value) {
            Logger.debug(
              `RaceVideoPane#watch: Movie info detected. Finish to check movie info process.`,
            )
            return
          }
          // 映像がない場合、メイン/オンボード映像の選択状態に応じて、メッセージを表示する
          if (viewAngle.value === 'player') {
            await MessageDialogStore.value.open({
              title: I18n.t('RaceVideoPage.errors.OnboardVideoNotFound.title') as string,
              message: I18n.t('RaceVideoPage.errors.OnboardVideoNotFound.message') as string,
            })
            setViewAngle('race', true)
            return
          }
          await MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.VideoNotFound.title') as string,
            message: I18n.t('RaceVideoPage.errors.VideoNotFound.message') as string,
          })
        } else if (movieInfo.isNotBroadcasting()) {
          // 未配信の場合、動画情報を再取得して、現時点でも未配信かどうかを確認する
          await fetchRaceData()
          if (!isLiveNotStarted.value) {
            return
          }
          // 映像未配信の場合、メイン/オンボード映像の選択状態に応じて、メッセージを表示する
          if (viewAngle.value === 'player') {
            await MessageDialogStore.value.open({
              title: I18n.t('RaceVideoPage.errors.OnboardVideoNotFound.title') as string,
              message: I18n.t('RaceVideoPage.errors.OnboardVideoNotFound.message') as string,
            })
            setViewAngle('race', true)
            return
          }
          await MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.VideoNotFound.title') as string,
            message: I18n.t('RaceVideoPage.errors.VideoNotFound.message') as string,
          })
        }
      },
    )
    watch(
      () => videoPlayerStatus.value?.playerStatus,
      (playerStatus) => {
        if (playerStatus === 'Not running') {
          return
        }
        if (firstStartHighlightId.value) {
          Logger.debug(`Set selectedHighlightId: ${firstStartHighlightId.value}`)
          /**
           * NOTE: 動画再生画面遷移時にオンボードハイライトを表示する場合、動画プレイヤーが生成される前にchangeScene（RaceVideoHighlightsPaneのmountedで実行）が実行されてしまうため、オンボード動画がシークしない。
           * selectedHighlightIdに一度nullをセットし、その後対象のハイライトIDをセットすることでVideoPlayerSectionのcurrentMovieInfoをwatchしている処理を動かすようにした。
           * ※currentMovieInfoのwatch処理はgetMovieInfoが変更されることによって動き、getMovieInfoはselectedHighlightIdが変更されることによって値が更新される。
           */
          selectedHighlightId.value = null

          // SFGO-218で実装した処理
          // 予選のQ1-A,Q1-B,Q2の対象位置にシークさせるためにこの処理が必要。また、画面描画時に中継映像のハイライトを再生する際もこの処理が必要
          selectedHighlightId.value = firstStartHighlightId.value
          firstStartHighlightId.value = undefined
        }
        if (!autoPlayed.value) {
          // 音声の状態をセット
          videoPlayer.value?.muted(raceVideoPageStore.raceVideoPageState.volume.muted)
          videoPlayer.value?.volume(
            Math.round(raceVideoPageStore.raceVideoPageState.volume.currentVolume * 0.01 * 100) /
              100,
          )
          // 最初に動画再生画面に遷移した時に、自動的に再生する。
          videoPlayer.value?.play()
          // canPlayはアングル切り替えでも発生するため、一度自動再生したら、自動再生はOFFにする
          autoPlayed.value = true
          // 視聴履歴取得
          raceVideoPageStore.saveHistory()
        }
      },
    )
    watch(
      () => videoPlayerStatus.value?.error,
      () => {
        if (ignoreAlert.value || appConfigStore.currentCircuitMode.value) {
          ignoreAlert.value = false
          return
        }
        const error = videoPlayerStatus.value?.error
        if (error === VideoPlayerError.NETWORK_ERROR) {
          MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.VideoReadTimeout.title') as string,
            message: I18n.t('RaceVideoPage.errors.VideoReadTimeout.message') as string,
          })
        } else if (error === VideoPlayerError.COMPATIBLE_VIDEO_NOT_FOUND) {
          MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.VideoNotFound.title') as string,
            message: I18n.t('RaceVideoPage.errors.VideoNotFound.message') as string,
          })
        } else if (error === VideoPlayerError.UNKNOWN_ERROR) {
          MessageDialogStore.value.open({
            title: I18n.t('RaceVideoPage.errors.VideoPlayError.title') as string,
            message: I18n.t('RaceVideoPage.errors.VideoPlayError.message') as string,
          })
        }
        if (videoPlayerStatus.value?.error) {
          videoPlayerStatus.value.error = undefined
        }
        setViewAngle('race', true)
      },
    )
    /**
     * 無線の再生状態を監視し、無線再生中は動画プレーヤーを消音する。
     */
    watch(
      () => raceVideoPageStore.radioDataStore.isPlayingRadio.value,
      () => {
        videoPlayer.value?.muted(raceVideoPageStore.radioDataStore.isPlayingRadio.value)
      },
    )
    return {
      videoPlayer,
      videoPlayerStatus,
      isVideoPlayerCanPlay,
      videoPaused,
      viewContentsMode,
      videoFullScaleStatus,
      isFetchedRaceInfo,
      isLiveNotStarted,
      isLive,
      currentRaceInformation: raceVideoPageStore.currentRaceInformation as Ref<AlertMessage>,
      videoSlow: raceVideoPageStore.videoSlow as Ref<boolean>,
      isCircuitMode: appConfigStore.currentCircuitMode,
    }
  },
  mounted() {
    // 動画再生画面を表示したとき、最初にオーバーレイを初期表示する
    // ※オーバーレイの機能があることをユーザーに示すため
    this.visibleOverlay()
  },
  beforeDestroy() {
    this.hideOverlay()
  },
  computed: {
    showGpsMap(): boolean {
      if (this.isFetchedRaceInfo && this.isCircuitMode) {
        return true
      }
      return this.mapViewEnabled
    },
  },
  methods: {
    /**
     * オーバーレイ表示
     */
    visibleOverlay(): void {
      this.restartOverlayTimer()
      this.overlayVisible = true
    },
    /**
     * オーバーレイ 表示タイマーリセット
     */
    restartOverlayTimer(): void {
      if (this.overlayTimer) {
        window.clearTimeout(this.overlayTimer)
      }
      this.overlayTimer = window.setTimeout(() => {
        this.overlayVisible = false
      }, 5000)
    },
    /**
     * オーバーレイ 非表示 + タイマークリア
     */
    hideOverlay(): void {
      if (this.overlayTimer) {
        window.clearTimeout(this.overlayTimer)
      }
      this.overlayVisible = false
    },
    /**
     * 動画再生・一時停止処理
     * @param mode 一時停止への変更の場合、 true が設定されて呼び出される。
     */
    async changeVideoPlayPause(mode: boolean) {
      if (mode) {
        await this.videoPlayer?.pause()
      } else {
        await this.videoPlayer?.play()
      }
      Logger.debug(
        `RaceVideoPane#changeVideoPlayPause: videoPausedStatus: ${this.videoPlayerStatus?.playStatus}, videoPaused: ${this.videoPaused}`,
      )
    },
    /**
     * 表示モード変更
     * @param mode 変更後のモード
     * @param oldMode 変更前のモード
     */
    changeViewMode(mode: string, oldMode: string): void {
      this.$emit('viewModeChange', mode, oldMode)
    },
    /**
     * VIDEOフルスケール表示切り替え
     */
    changeFullScaleVideo(): void {
      this.$emit('fullScaleVideo')
    },
    /**
     * ビデオ スロー再生 切り替え
     */
    async changeVideoSlow() {
      this.videoSlow = !this.videoSlow
      if (this.videoSlow) {
        await this.videoPlayer?.setPlaybackRate(0.5)
      } else {
        await this.videoPlayer?.setPlaybackRate(1.0)
      }
    },
    /**
     * ビデオ スロー再生 リセット
     */
    resetVideoSlow() {
      this.videoSlow = false
    },
    /**
     * LIVEボタンがクリックされた場合に呼び出される。
     */
    onLiveClicked() {
      if (this.isLive) {
        this.videoPlayer?.seekEndOfVideo()
      }
    },
    notVideoFound(): void {
      this.$emit('notVideoFound')
    },
    setOrientationTimer(): void {
      this.$emit('setOrientationTimer')
    },
    /**
     * 簡易マップ表示変更
     */
    toggleMapMode(toggle = false) {
      const appConfigStore = StoreUtil.useStore('AppConfigStore')

      if (this.isCircuitMode || toggle) {
        // サーキットモードをOFFへ
        appConfigStore.currentCircuitMode.value = false
        this.mapViewEnabled = false
      } else {
        // サーキットモードをONへ
        appConfigStore.currentCircuitMode.value = true
        this.mapViewEnabled = true
      }
    },
    /**
     * 動画シークバー touchStart時イベント通知
     */
    handleTouchStartSeekBar(): void {
      this.$emit('handleTouchStartSeekBar')
    },
    /**
     * 動画シークバー touchEnd時イベント通知
     */
    handleTouchEndSeekBar(): void {
      this.$emit('handleTouchEndSeekBar')
    },
    /**
     * 音声シークバー イベント通知
     * @param volumeLevel 音量
     */
    async handleTouchEndVolumeSeekBar(volumeLevel: number) {
      // volumeの設定値は少数のパーセントで渡す
      const volume = Math.round(volumeLevel * 0.01 * 100) / 100
      this.videoPlayer?.volume(volume)
    },
    /**
     * 音声ミュート イベント通知
     * @param isMute ミュートしているか
     */
    handleVolumeMute(isMute: boolean) {
      this.videoPlayer?.muted(isMute)
    },
  },
})
