var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "countdown-section" }, [
    _c("img", {
      staticClass: "countdown-section__logo",
      attrs: { src: _vm.logoImage, alt: "" },
    }),
    _c(
      "div",
      { staticClass: "countdown-section__item" },
      [
        _c("header", { staticClass: "countdown-section__header" }, [
          _c("span", {
            staticClass: "countdown-section__header__catch",
            domProps: { textContent: _vm._s(_vm.headerTextCatch) },
          }),
          _vm.countdownTargetData && _vm.countdownTargetData.eventName
            ? _c("span", {
                class: {
                  "countdown-section__header__title--ja": !_vm.isEnglish(
                    _vm.countdownTargetData.eventName[_vm.$i18n.locale] || ""
                  ),
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.countdownTargetData.eventName[_vm.$i18n.locale]
                  ),
                },
              })
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "countdown-section__content",
            class: { "countdown-section__content--live-mode": _vm.hasLiveRace },
          },
          [
            _vm.hasLiveRace
              ? _c(
                  "div",
                  { staticClass: "countdown-section__play-live" },
                  [
                    _c("h4", {
                      staticClass: "countdown-section__play-live__title",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$tc("HomePage.countdown.liveNow")
                        ),
                      },
                    }),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "countdown-section__play-live__link countdown-section__play-live__link--light--secondary countdown-section__play-live__link--small",
                        class: {
                          "countdown-section__play-live__link--circuit-mode":
                            _vm.isCircuitMode,
                        },
                        attrs: { to: _vm.liveRaceLink },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "18",
                              height: "18",
                              viewBox: "0 0 18 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M5 3L14.2854 8.57125C14.6091 8.76546 14.6091 9.23454 14.2854 9.42875L5 15V3Z",
                                fill: "#00DBA6",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "countdown-section__countdown" }, [
                  _c(
                    "div",
                    { staticClass: "countdown-section__countdown-days" },
                    [
                      _c("span", {
                        staticClass: "countdown-section__countdown-data",
                        domProps: { textContent: _vm._s(_vm.countdown.days) },
                      }),
                      _c("span", {
                        staticClass: "countdown-section__countdown-unit",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$tc("HomePage.countdown.days")
                          ),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "countdown-section__countdown__bottom-wrapper",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "countdown-section__countdown-hours" },
                        [
                          _c("span", {
                            staticClass: "countdown-section__countdown-data",
                            domProps: {
                              textContent: _vm._s(_vm.countdown.hours),
                            },
                          }),
                          _c("span", {
                            staticClass: "countdown-section__countdown-unit",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$tc("HomePage.countdown.hours")
                              ),
                            },
                          }),
                        ]
                      ),
                      _c("div", {
                        staticClass: "countdown-section__countdown-separator",
                      }),
                      _c(
                        "div",
                        { staticClass: "countdown-section__countdown-min" },
                        [
                          _c("span", {
                            staticClass: "countdown-section__countdown-data",
                            domProps: {
                              textContent: _vm._s(_vm.countdown.min),
                            },
                          }),
                          _c("span", {
                            staticClass: "countdown-section__countdown-unit",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$tc("HomePage.countdown.min")
                              ),
                            },
                          }),
                        ]
                      ),
                      _c("div", {
                        staticClass: "countdown-section__countdown-separator",
                      }),
                      _c(
                        "div",
                        { staticClass: "countdown-section__countdown-sec" },
                        [
                          _c("span", {
                            staticClass: "countdown-section__countdown-data",
                            domProps: {
                              textContent: _vm._s(_vm.countdown.sec),
                            },
                          }),
                          _c("span", {
                            staticClass: "countdown-section__countdown-unit",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$tc("HomePage.countdown.sec")
                              ),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
            _c(
              "figure",
              {
                staticClass: "countdown-section__icon",
                class: { "countdown-section__icon-flag": !_vm.courseSvgPath },
              },
              [_c("img", { attrs: { src: _vm.courseSvgPath, alt: "" } })]
            ),
          ]
        ),
        _vm.hasLiveRace
          ? _c("circuit-mode-section", {
              attrs: { hasDisplayedOnboarding: _vm.hasDisplayedOnboarding },
              on: {
                onCloseOnboardingScreen: function ($event) {
                  return _vm.$emit("onCloseOnboardingScreen")
                },
              },
            })
          : _vm._e(),
        _vm.showTicketButton
          ? _c(
              "div",
              { staticClass: "countdown-section__links" },
              [
                _c("ticket-link-parts", {
                  attrs: {
                    purchasedTicketStatus:
                      _vm.purchasedRelatedEventTicketStatus,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }