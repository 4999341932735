import dayjs from 'dayjs'
import { TermType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 期間を操作するための処理を取得する。
 */
export default function useMissionTerm() {
  const { getDisplayDate } = useDisplayDependingOnLang()

  // methods
  /**
   * 対象年度のミッション期間を取得する。
   */
  const getTargetYearMissionTerm = (year: number) => {
    // 選択年度のミッション期間を取得する。ミッション期間は対象年度の2/1〜翌年1/31（JST）となる。
    const startDate = dayjs.tz(`${year}-02-01`, 'Asia/Tokyo').startOf('day').valueOf()
    const endDate = dayjs
      .tz(`${year + 1}-01-31`, 'Asia/Tokyo')
      .endOf('day')
      .valueOf()

    return {
      startDate,
      endDate,
    } as TermType
  }

  /**
   * 今シーズンのミッションの年を返す
   */
  const getThisSeasonYear = () =>
    dayjs.tz(`${new Date().getFullYear()}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() >
    dayjs().valueOf()
      ? new Date().getFullYear() - 1
      : new Date().getFullYear()

  /**
   * 引数で渡された日付の対象シーズン年を返す
   */
  const getTargetSeasonYear = (date: number) => {
    const targetYear = new Date(date).getFullYear()
    return dayjs.tz(`${targetYear}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() >
      dayjs(date).valueOf()
      ? targetYear - 1
      : targetYear
  }

  /**
   * ミッション終了日を「月日」のフォーマットで返す
   */
  const getMissionEndDateMMDD = () => {
    const endDate = dayjs
      .tz(`${new Date().getFullYear()}-01-31`, 'Asia/Tokyo')
      .endOf('day')
      .local()
      .valueOf()

    return getDisplayDate(endDate, 'MM/DD', 'MMM DD')
  }

  return {
    getTargetYearMissionTerm,
    getThisSeasonYear,
    getTargetSeasonYear,
    getMissionEndDateMMDD,
  }
}
