







































import { defineComponent, ref } from '@vue/composition-api'
import MypageUserEditHeaderSection from '@/components/MypageUserEditPage/MypageUserEditPane/MypageUserEditHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageUserEditPane from '@/components/MypageUserEditPage/MypageUserEditPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import ConfirmLeavingPageModalSection from '@/components/common/modal/ConfirmLeavingPageModalSection.vue'

export type DataType = {
  confirmLeavingModalVisible: boolean
  screenTransitionTo: string
}

/**
 * マイページ: 会員情報変更ページ
 */
export default defineComponent({
  name: 'MypageUserEditPage',
  components: {
    MypageUserEditHeaderSection,
    GlobalNavigationPane,
    MypageUserEditPane,
    ActionButtonsSection,
    AtomInputButton,
    ConfirmLeavingPageModalSection,
  },
  data(): DataType {
    return {
      confirmLeavingModalVisible: false, // 画面遷移警告モーダル表示フラグ
      screenTransitionTo: '/mypage/', // 画面遷移警告モーダル 遷移先指定
    }
  },
  setup() {
    const mypageUserEditPane = ref<InstanceType<typeof MypageUserEditPane>>()

    return {
      mypageUserEditPane,
    }
  },
  methods: {
    /**
     * 変更確認通知
     */
    editUserInformation(): void {
      return this.mypageUserEditPane?.confirmUserInfo()
    },
    /**
     * キャンセル通知
     */
    editCancel(): void {
      return this.mypageUserEditPane?.editCancel()
    },
    /**
     * キャンセルモーダル表示
     * @event show-cancel
     */
    showCancel(showCancelModal: boolean) {
      if (showCancelModal) {
        this.confirmLeavingModalVisible = true
        return
      }
      this.$router.replace({ path: '/mypage/' })
    },
    /**
     * 確認画面へ遷移
     * @event success-confirm
     */
    moveToConfirm() {
      this.$router.replace({ path: '/mypage/user-confirm' })
    },
  },
})
