





























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { UserPointWithGrantedSeasonYearType } from '@/store/hook/useUserPoint'

export default defineComponent({
  name: 'MypagePointExpirationDateTableSection',
  props: {
    /**
     * 永久ポイントを除いた、ポイントと失効日の一覧
     */
    userPointsWithExpirationByExpirationDate: {
      type: Map as PropType<Map<string, UserPointWithGrantedSeasonYearType>>,
      default: () => ({}),
    },
  },
  setup(props) {
    const expirationUserPointList = computed(() =>
      props.userPointsWithExpirationByExpirationDate.entries(),
    )
    return {
      expirationUserPointList,
    }
  },
})
