


































import { defineComponent, computed } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import AtomLink from '@/components/atoms/AtomLink.vue'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'

/**
 * ミッション画面 チェックイン失敗モーダル
 */
export default defineComponent({
  name: 'CheckInFailedModalSection',
  components: {
    AtomLink,
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomInputButton,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
  },
  setup() {
    const missionPageStore = StoreUtil.useStore('MissionPageStore')
    const { currentLocationInfo } = missionPageStore

    /** 現在地のGoogle Mapのリンク */
    const currentLocationGoogleMapUrl = computed(() => {
      const googleMapUrl =
        I18n.locale === 'ja' ? Const.EXTERNAL_LINKS.GOOGLE_MP.JA : Const.EXTERNAL_LINKS.GOOGLE_MP.EN
      return `${googleMapUrl}&ll=${currentLocationInfo.value?.lat},${currentLocationInfo.value?.lng}`
    })

    const displayCurrentLocation = computed(() =>
      I18n.t('MissionPage.checkIn.errors.outOfAreaOrPeriod.currentLocation', {
        lat: currentLocationInfo.value?.lat,
        lng: currentLocationInfo.value?.lng,
      }).toString(),
    )

    return {
      currentLocationGoogleMapUrl,
      displayCurrentLocation,
    }
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
