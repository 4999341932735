




















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import OneTimePassCompletePane from '@/components/MypageOneTimePassCompletePage/OneTimePassCompletePane.vue'

/**
 * マイページ: ワンタイムパス: 登録完了ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassCompletePage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    OneTimePassCompletePane,
    ActionButtonsSection,
    AtomRouterButton,
  },
})
