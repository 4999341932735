var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { ref: "notificationPane", staticClass: "notification-pane" },
        [
          _vm.isIncludeNextRelease
            ? _c(
                "div",
                { staticClass: "notification-pane__tabs" },
                [
                  _c("notification-tabs-parts", {
                    attrs: { selectedTabId: _vm.selectedTabId },
                    on: { onClick: _vm.handleTabClicked },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedTabId === "news" &&
          _vm.notificationData &&
          _vm.notificationData.length > 0
            ? [
                _c("notification-list-section", {
                  attrs: { notificationList: _vm.notificationData },
                  on: { handlerClickLink: _vm.moveToSession },
                }),
                _c("pagination-section", {
                  attrs: {
                    currentPage: _vm.currentPage,
                    totalPages: _vm.totalPages,
                  },
                  on: { pageSelected: _vm.changePage },
                }),
              ]
            : _vm.selectedTabId === "point" &&
              _vm.pointData &&
              _vm.pointData.length > 0
            ? _c(
                "div",
                { staticClass: "notification-pane__point-list" },
                [
                  _c("notification-point-list-section", {
                    attrs: { listData: _vm.pointData },
                  }),
                  _c(
                    "div",
                    { staticClass: "notification-pane__pagination" },
                    [
                      _c("pagination-section", {
                        attrs: { currentPage: 1, totalPages: 12 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : [
                _c("div", { staticClass: "notification-pane__no-data" }, [
                  _c("h4", {
                    staticClass: "notification-pane__no-data__title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tc("NotificationPage.noData.title")
                      ),
                    },
                  }),
                  _c("p", {
                    staticClass: "notification-pane__no-data__message",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tc("NotificationPage.noData.message")
                      ),
                    },
                  }),
                ]),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }