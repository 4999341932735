var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-point-history-pane" }, [
    _c(
      "div",
      { staticClass: "mypage-point-history-pane__header" },
      [
        _c("atom-select-year", {
          attrs: { currentYear: _vm.currentYear, yearList: _vm.yearList },
          on: { change: _vm.handleYearChange },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mypage-point-history-pane__body" },
      _vm._l(_vm.pointHistoryData, function (item) {
        return _c(
          "div",
          { key: item.month, staticClass: "mypage-point-history-pane__block" },
          [
            _c("mypage-point-monthly-section", {
              attrs: { monthlyData: item },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }