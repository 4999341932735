


















import { defineComponent, Ref } from '@vue/composition-api'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import ModalCouponPlanCardParts from '@/components/common/modal/parts/ModalCouponPlanCardParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

export default defineComponent({
  name: 'OneTimePassRegisterModalSection',
  components: { AtomButton, ModalCouponPlanCardParts, ModalMessageParts, ModalTitleParts },
  setup(_, { emit }) {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { oneTimePassPlan } = oneTimePassPageStore

    /**
     * プラン登録ありのワンタイムパス登録時の処理
     */
    const handleRegistrationClicked = () => {
      emit('onClickRegistration')
    }

    return {
      oneTimePassPlan: oneTimePassPlan as Ref<ContractPlanDocument | null>,
      handleRegistrationClicked,
    }
  },
})
