var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-coupon-plan-card-parts" }, [
    _c("div", { staticClass: "modal-coupon-plan-card-parts__card" }, [
      _c("div", { staticClass: "modal-coupon-plan-card-parts__main" }, [
        _c("p", { staticClass: "modal-coupon-plan-card-parts__title" }, [
          _vm._v(
            " " + _vm._s(_vm.plan.couponData.couponName[_vm.$i18n.locale]) + " "
          ),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__subtitle" }, [
          _vm._v(
            " " + _vm._s(_vm.plan.couponData.title[_vm.$i18n.locale]) + " "
          ),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__description" }, [
          _vm._v(
            " " +
              _vm._s(_vm.plan.couponData.description[_vm.$i18n.locale]) +
              " "
          ),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__period" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.plan.couponData.couponApplicationPeriodText)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "modal-coupon-plan-card-parts__side" }, [
        _c("p", { staticClass: "modal-coupon-plan-card-parts__price" }, [
          _c("span", [_vm._v(_vm._s(_vm.priceJpy))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }