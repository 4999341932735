import dayjs from 'dayjs'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import type { ContractTermType } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import I18n from '@/locales/I18n'
import type { CouponContractPlanType } from '@/store/stores/collectionModule/documents/coupon/BaseCouponDocument'

/**
 * 契約期間取得処理のHook
 */
export default function useContractTerm() {
  const { getDisplayDate } = useDisplayDependingOnLang()

  /**
   * 契約更新予定日 or 契約更新日を取得する。
   */
  const getReferenceDate = (contractStartDate: number) => {
    // 現在の年を取得
    const currentYear = dayjs().year().toString()
    // 現在の月を取得
    const currentMonth = (dayjs().month() + 1).toString().padStart(2, '0')
    // 契約した日にち以下を取得
    const contractStartDay = dayjs(contractStartDate).format('DD HH:mm:ss').toString()

    return dayjs(`${currentYear}-${currentMonth}-${contractStartDay}`).valueOf()
  }

  /**
   * 現在の契約期間を取得する。
   * @param effectiveContractPlan 有効なプランのプランマスタ情報
   * @param effectiveOrgContract 有効なプラン情報
   * @return {contractStartDate: number, contractEndDate: number}
   */
  const getCurrentContractTerm = (
    effectiveContractPlan: ContractPlanDocument,
    effectiveOrgContract: ContractType,
  ): ContractTermType => {
    /**
     * 月払いプランの場合
     * 契約開始日、契約終了日を計算して返却する
     */
    if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
      // 現在時刻を取得
      const now = dayjs().valueOf()
      // 基準日（契約更新予定日 or 契約更新日）を取得
      const referenceDate = getReferenceDate(effectiveOrgContract?.startDate || 0)

      // 今月既に更新済み場合
      if (referenceDate < now) {
        return {
          // 開始日：契約更新日
          contractStartDate: referenceDate,
          /**
           * 終了日：契約更新日+1ヶ月-1日の23:59:59
           * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDateを優先的に表示する
           */
          contractEndDate: !effectiveOrgContract?.endDate
            ? dayjs(referenceDate).add(1, 'M').subtract(1, 'd').endOf('day').valueOf()
            : effectiveOrgContract?.endDate,
        }
      }

      // まだ更新していない場合
      return {
        // 開始日：更新予定日-1ヶ月
        contractStartDate: dayjs(referenceDate).subtract(1, 'M').valueOf(),
        /**
         * 終了日：契約更新予定日-1日の23:59:59
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDateを優先的に表示する
         */
        contractEndDate: !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).subtract(1, 'd').endOf('day').valueOf()
          : effectiveOrgContract?.endDate,
      }
    }

    /**
     * 年払いプラン、クーポンプラン、トライアルプランの場合
     * 契約情報の開始日、終了日を表示する
     */
    return {
      contractStartDate: effectiveOrgContract?.startDate,
      contractEndDate: effectiveOrgContract?.endDate,
    }
  }

  /**
   * 契約予定の契約期間を取得する。
   * @param effectiveContractPlan 有効なプランのプランマスタ情報
   * @param effectiveOrgContract 有効なプラン情報
   * @param selectedPlanGroupId 選択したプランのプラングループID
   * @return {contractStartDate: number, contractEndDate: number}
   */
  const getScheduledContractTerm = (
    effectiveContractPlan: ContractPlanDocument,
    effectiveOrgContract: ContractType,
    selectedPlanGroupId: PlanGroupType,
  ): ContractTermType => {
    let newContractStartDate = 0
    let newContractEndDate = 0
    /**
     * 月払いプラン → 新プランに更新する場合
     * 1. 年払いプランへの更新
     * 2. 無料プランへの移行
     * 契約開始日、契約終了日を計算して返却する
     */
    if (effectiveContractPlan?.planGroupId === 'monthlyPlan') {
      // 現在時刻を取得
      const now = dayjs().valueOf()
      // 基準日（契約更新予定日 or 契約更新日）を取得
      const referenceDate = getReferenceDate(effectiveOrgContract?.startDate || 0)

      // 今月既に更新済み場合
      if (referenceDate < now) {
        /**
         * 新プラン契約開始日：契約更新日+1ヶ月の00:00:00
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDate+1ミリ秒を優先的に表示する
         */
        newContractStartDate = !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).add(1, 'M').startOf('day').valueOf()
          : (effectiveOrgContract?.endDate ?? 0) + 1

        if (selectedPlanGroupId === 'annualPlan') {
          // 年払いプランの場合の終了日：新プラン契約開始日+1年-1日の23:59:59
          newContractEndDate = dayjs(newContractStartDate)
            .add(1, 'y')
            .subtract(1, 'd')
            .endOf('day')
            .valueOf()
        }
        return {
          contractStartDate: newContractStartDate,
          contractEndDate: newContractEndDate,
        }
      }

      // まだ更新していない場合
      if (selectedPlanGroupId === 'annualPlan') {
        // 年払いプランの場合の終了日：契約更新日（=新プラン契約開始日）+1年-1日の23:59:59
        newContractEndDate = dayjs(referenceDate)
          .add(1, 'y')
          .subtract(1, 'd')
          .endOf('day')
          .valueOf()
      }
      return {
        /**
         * 新プラン契約開始日：契約更新日の00:00:00
         * 解約などをして月額プランにendDateが設定されている場合は、設定されているendDate+1ミリ秒を優先的に表示する
         */
        contractStartDate: !effectiveOrgContract?.endDate
          ? dayjs(referenceDate).startOf('day').valueOf()
          : (effectiveOrgContract?.endDate ?? 0) + 1,
        contractEndDate: newContractEndDate,
      }
    }

    /**
     * 年払いプラン、クーポンプラン、トライアルプラン→ 新プランに更新する場合
     */
    // 開始日：契約終了日+1ミリ秒
    newContractStartDate = effectiveOrgContract?.endDate ? effectiveOrgContract.endDate + 1 : 0
    if (selectedPlanGroupId === 'annualPlan') {
      // 年払いプランの場合の終了日：新プラン契約開始日+1年-1日の23:59:59
      newContractEndDate = dayjs(newContractStartDate)
        .add(1, 'y')
        .subtract(1, 'd')
        .endOf('day')
        .valueOf()
    }
    if (selectedPlanGroupId === 'monthlyPlan') {
      // 月払いプランの場合の終了日：新プラン契約開始日+1ヶ月-1日の23:59:59
      newContractEndDate = dayjs(newContractStartDate)
        .add(1, 'M')
        .subtract(1, 'd')
        .endOf('day')
        .valueOf()
    }
    return {
      contractStartDate: newContractStartDate,
      contractEndDate: newContractEndDate,
    }
  }

  /**
   * クーポン適用期間を取得する
   */
  const getCouponApplicationPeriod = (plans: CouponContractPlanType[] | null | undefined) => {
    let startDate: string
    let endDate: string

    const couponPlan = plans?.find(
      (plan) =>
        plan.planGroupId === 'limitedTimePaidPlan' ||
        plan.planGroupId === 'limitedTimePaidPlanForAnnual',
    )

    /** 期間指定で作成されたクーポンの場合 */
    if (couponPlan?.planDuration) {
      // 開始日時をクーポン利用日の0時に設定する
      startDate = getDisplayDate(dayjs().startOf('day').valueOf())

      endDate = getDisplayDate(
        dayjs(startDate)
          .add(couponPlan.planDuration / 1000, 's')
          .valueOf() - 1,
        'YYYY/MM/DD',
      )

      return { startDate, endDate }
    }

    /** 日付指定で作成されたクーポンの場合 */
    // クーポンが即日適用される場合は今日の日付をセットする
    startDate = couponPlan?.startDate
      ? getDisplayDate(dayjs(couponPlan?.startDate).valueOf())
      : getDisplayDate(dayjs().valueOf())

    endDate = getDisplayDate(dayjs(couponPlan?.endDate).valueOf())

    return { startDate, endDate }
  }

  /**
   * 期間に関するテキストを取得する。以下のように出し分ける。
   * - クーポンの場合: 適用期間
   * - ワンタイムパスの場合: 無料期間
   */
  const getDurationText = (plans: CouponContractPlanType[] | null | undefined) => {
    // ワンタイムパスはクーポン利用後に無料プランに戻る
    const isOneTimePassUsed = plans?.some((plan) => plan.planGroupId === 'freePlan')

    return isOneTimePassUsed
      ? 'SignupPage.SelectPlanPage.plan.couponContractTerm'
      : 'SignupPage.SelectPlanPage.plan.freePlanTerm'
  }

  /**
   * クーポン適用期間のテキストを取得する
   */
  const getCouponApplicationPeriodText = (plans: CouponContractPlanType[] | null | undefined) => {
    const period = getCouponApplicationPeriod(plans)
    const durationText = getDurationText(plans)

    return period.endDate
      ? `${I18n.t(durationText, {
          startDate: period.startDate,
          endDate: period.endDate,
        }).toString()}`
      : ''
  }

  /**
   * クーポン適用期間のテキストを取得する
   * 開始日は今日の日付を表示
   */
  const getCouponApplicationPeriodFromTodayText = (
    plans: CouponContractPlanType[] | null | undefined,
  ) => {
    const period = getCouponApplicationPeriod(plans)
    const durationText = getDurationText(plans)

    return period.endDate
      ? `${I18n.t(durationText, {
          startDate: getDisplayDate(dayjs().valueOf()),
          endDate: period.endDate,
        }).toString()}`
      : ''
  }

  return {
    getReferenceDate,
    getCurrentContractTerm,
    getScheduledContractTerm,
    getCouponApplicationPeriod,
    getCouponApplicationPeriodText,
    getCouponApplicationPeriodFromTodayText,
  }
}
