var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "one-time-pass-register-modal-section" }, [
    _c(
      "div",
      { staticClass: "one-time-pass-register-modal-section__header" },
      [
        _c("modal-title-parts", {
          attrs: {
            title: _vm.$tc("common.flowLineToPaidPlan.discount.oneTime.title"),
          },
        }),
        _c("modal-message-parts", {
          attrs: {
            message: _vm.$tc(
              "common.flowLineToPaidPlan.discount.oneTime.message"
            ),
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "one-time-pass-register-modal-section__body" },
      [
        _c("modal-coupon-plan-card-parts", {
          attrs: { plan: _vm.oneTimePassPlan },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "one-time-pass-register-modal-section__footer" },
      [
        _c("atom-button", {
          attrs: { linkText: _vm.$tc("common.registration") },
          on: { onClick: _vm.handleRegistrationClicked },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }