






















































import { computed, defineComponent, Ref } from '@vue/composition-api'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'
import MypageFavoritePlayerLinkSection from '@/components/MypageTopPage/MypageTopPane/MypageFavoritePlayerLinkSection.vue'
import MypageFavoriteTeamLinkSection from '@/components/MypageTopPage/MypageTopPane/MypageFavoriteTeamLinkSection.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import Const from '@/util/Const'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'

/**
 * 新規会員登録 会員情報入力セクション
 * todo: スタイル調整 / コンポーネント間データ連携 / エラー表示 / emit等
 */
export default defineComponent({
  name: 'UserInitConfirmSection',
  components: {
    FieldsetConfirmParts,
    MypageFavoritePlayerLinkSection,
    MypageFavoriteTeamLinkSection,
  },
  props: {
    /**
     * 会員登録タイプ
     */
    memberType: {
      type: String,
      default: 'free',
    },
  },
  setup() {
    const loginStore = StoreUtil.useStore('LoginStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { getDisplayDate } = useDisplayDependingOnLang()

    const { user, editUserInfo } = mypagePageStore

    /** 名前（ふりがな）を表示するかどうか */
    const userNameRubyEnabled = user.value.lang === 'ja' || !user.value.lang
    /** お住まいの都道府県を表示するかどうか */
    const showPrefecturesParts = editUserInfo.value.country === 'JP' || !editUserInfo.value.country
    /** お住まいの国を表示するかどうか */
    const showZipcodeParts = editUserInfo.value.country === 'JP' || !editUserInfo.value.country

    const countryList = Const.USER_COUNTRY_LIST
    const country = computed(
      () =>
        countryList.find((item) => item.id === editUserInfo.value.country)?.name ||
        ({} as Multilingual),
    )

    const genderList = Const.USER_GENDER_LIST
    const gender = computed(
      () =>
        genderList.find((item) => item.id === editUserInfo.value.gender)?.name ||
        ({} as Multilingual),
    )

    const prefectureList = Const.USER_PREFECTURES_LIST
    const prefecture = computed(
      () =>
        prefectureList.find((item) => item.id === editUserInfo.value.prefecture)?.name ||
        ({} as Multilingual),
    )

    /**
     * 表示用の生年月日
     * @param inputtedBirthDay 入力された生年月日
     */
    const displayBirthDay = computed(() => {
      const birthDay = `${editUserInfo.value.birthDay} 00:00:00`
      const birthDayMillisec = new Date(birthDay).getTime()

      return getDisplayDate(birthDayMillisec)
    })

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }

    /**
     * チームロゴ画像のURL
     * @param team チーム情報
     */
    const teamLogoUrl = (team?: TeamDocument) => {
      if (!team) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(team.teamLogo)
    }

    /**
     * チーム表示名を取得する
     * @param target チーム
     */
    const getTeamName = (target: TeamDocument) => {
      if (target.teamShortName) {
        return target.teamShortName
      }
      return target.teamName
    }

    return {
      userNameRubyEnabled,
      showPrefecturesParts,
      showZipcodeParts,
      email: mypagePageStore.user.value.mailAddress,
      password: loginStore.password,
      editUserInfo: editUserInfo as Ref<EditUserType>,
      prefecture,
      country,
      gender,
      favoritePlayer: mypagePageStore.savingFavoritePlayer as Ref<PlayerDocument>,
      favoriteTeam: mypagePageStore.savingFavoriteTeam as Ref<TeamDocument>,
      displayBirthDay,
      playerPictureUrl,
      teamLogoUrl,
      getTeamName,
    }
  },
})
