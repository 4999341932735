var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-error-section" }, [
    _c(
      "div",
      { staticClass: "location-error-section__header" },
      [
        _c("modal-title-parts", {
          attrs: { title: _vm.$tc("common.errors.locationError.title") },
        }),
        _c(
          "div",
          { staticClass: "location-error-section__error" },
          [
            _c("modal-message-parts", {
              attrs: { message: _vm.locationErrorMessage },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "location-error-section__link" },
          [
            _c("atom-link", {
              attrs: {
                url: _vm.currentLocationGoogleMapUrl,
                linkText: _vm.$tc("common.errors.locationError.checkGoogleMap"),
                imgPath: require("@/assets/img/icon/icon_external_link_outline.svg"),
                iconPosition: "right",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "location-error-section__code" },
          [
            _c("modal-message-parts", {
              attrs: { message: _vm.$tc("common.errors.locationError.code") },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "location-error-section__current" },
          [
            _c("modal-message-parts", {
              attrs: { message: _vm.displayCurrentLocation },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "location-error-section__body" },
      [
        _vm.isShowInput
          ? _c("modal-coupon-code-input-parts", {
              attrs: {
                buttonLabel: _vm.$tc(
                  "common.flowLineToPaidPlan.discount.reUse"
                ),
                hasError: _vm.hasError,
                errorMessage: _vm.errorMessage,
              },
              on: { onCLickUse: _vm.onCLickReUse },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }