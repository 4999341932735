



































































import { defineComponent } from '@vue/composition-api'
import NavigationHomeIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationHomeIconParts.vue'
import NavigationRaceIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationRaceIconParts.vue'
import NavigationStandingsIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationStandingsIconParts.vue'
import NavigationLinkIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationLinkIconParts.vue'
import NavigationMyPageIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationMyPageIconParts.vue'
import NavigationNotificationIconSection from '@/components/common/GlobalNavigation/GlobalNavigationPane/NavigationNotificationIconSection.vue'
import NavigationMissionIconParts from '@/components/common/GlobalNavigation/GlobalNavigationPane/parts/NavigationMissionIconParts.vue'

/**
 * グローバルナビゲーション
 */
export default defineComponent({
  name: 'GlobalNavigationSection',
  components: {
    NavigationMissionIconParts,
    NavigationHomeIconParts,
    NavigationRaceIconParts,
    NavigationStandingsIconParts,
    NavigationLinkIconParts,
    NavigationMyPageIconParts,
    NavigationNotificationIconSection,
  },
  props: {
    /**
     * カレント表示指定
     */
    current: {
      type: String,
      default: '',
    },
  },
})
