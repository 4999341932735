var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-one-time-pass-input-section" },
    [
      _c("h6", { staticClass: "mypage-one-time-pass-input-section__title" }, [
        _vm._v(" " + _vm._s(_vm.$tc("formParts.label.oneTimePass")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "mypage-one-time-pass-input-section__fieldset" },
        [
          _c("atom-input-text", {
            staticClass: "mypage-one-time-pass-input-section__input",
            attrs: {
              id: "coupon",
              error: _vm.state.oneTimePassError,
              placeholder: _vm.$tc("formParts.placeholder.oneTimePass"),
            },
            model: {
              value: _vm.state.oneTimePassCode,
              callback: function ($$v) {
                _vm.$set(_vm.state, "oneTimePassCode", $$v)
              },
              expression: "state.oneTimePassCode",
            },
          }),
          _c("atom-input-button", {
            staticClass: "mypage-one-time-pass-input-section__submit",
            attrs: {
              isDisabled: false,
              "link-text": _vm.$tc("common.registrationShort"),
              colorType: "secondary",
            },
            on: { push: _vm.handlerSubmit },
          }),
        ],
        1
      ),
      _c("form-error-message-parts", {
        attrs: {
          showError: _vm.state.oneTimePassError,
          message: _vm.state.oneTimePassErrorMessage,
        },
      }),
      _vm.state.isShowLocationErrorModal
        ? _c("common-modal-section", {
            staticClass: "location-error-modal-section",
            on: { close: _vm.emitClose },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("location-error-modal-section", {
                        attrs: {
                          currentLocationInfo: _vm.currentLocationInfo,
                          storeName: _vm.oneTimePassAvailableStoreName,
                          isShowInput: false,
                        },
                        on: { onCLickReUse: _vm.handlerSubmit },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("atom-input-button", {
                        attrs: {
                          linkText: _vm.$i18n.tc("common.close"),
                          colorType: "tertiary",
                          isDisabled: false,
                        },
                        on: {
                          push: function ($event) {
                            return _vm.emitClose()
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3598600865
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }