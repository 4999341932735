var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "highlights-card-parts", attrs: { to: _vm.card.link } },
    [
      _c("div", { staticClass: "highlights-card-parts__header" }, [
        _c("p", { staticClass: "highlights-card-parts__situation" }, [
          _c("span", { staticClass: "highlights-card-parts__situation__lap" }, [
            _vm._v(" Lap"),
            _c("span", { domProps: { textContent: _vm._s(_vm.card.lap) } }),
            _vm._v("-" + _vm._s(_vm.card.sector) + " "),
          ]),
          _c(
            "span",
            { staticClass: "highlights-card-parts__situation__round" },
            [_vm._v("/ " + _vm._s(_vm.card.round))]
          ),
        ]),
        _c("p", { staticClass: "highlights-card-parts__situation__lapTime" }, [
          _vm._v(_vm._s(_vm.card.movieStartTime)),
        ]),
      ]),
      _c("figure", { staticClass: "highlights-card-parts__scene-thumbnail" }, [
        _c("img", {
          staticClass: "highlights-card-parts__scene-image",
          attrs: { src: _vm.card.thumbnailPath, alt: "" },
        }),
      ]),
      _c("div", { staticClass: "highlights-card-parts__body" }, [
        _c("figure", { staticClass: "highlights-card-parts__user-image" }, [
          _c("img", {
            attrs: { src: _vm.card.createUser.userPicture, alt: "" },
          }),
        ]),
        _c("p", {
          staticClass: "highlights-card-parts__user-name",
          domProps: {
            textContent: _vm._s(_vm.card.createUser.userDisplayName),
          },
        }),
        _c("p", {
          staticClass: "highlights-card-parts__comment",
          domProps: { innerHTML: _vm._s(_vm.card.note) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }