


































import { computed, defineComponent } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import Const from '@/util/Const'
import FieldsetConfirmParts from '@/components/common/form/FieldsetConfirmParts.vue'
import StoreUtil from '@/store/StoreUtil'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useHistory from '@/store/hook/useHistory'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'

interface selectDataNameType {
  ja: string
  en: string
}

export type DataType = {
  countryName: selectDataNameType
  prefectureName: selectDataNameType
}

/**
 * マイページ: 会員情報変更 入力セクション
 * todo: コンポーネント間データ連携 / エラー表示 / emit等
 */
export default defineComponent({
  name: 'UserConfirmFormSection',
  components: {
    FieldsetConfirmParts,
  },
  data(): DataType {
    return {
      /**
       * お住まいの国名
       */
      countryName: {} as selectDataNameType,
      /**
       * お住まいの都道府県名
       */
      prefectureName: {} as selectDataNameType,
    }
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')

    const { saveMissionHistoryForProfile, saveMissionHistory } = useHistory()

    const userInfo = mypagePageStore.user.value
    // 姓名別取得後 1つのデータに整形
    const nameFirst = userInfo.firstName
    const namefamily = userInfo.familyName
    const name = `${namefamily} ${nameFirst}`

    const rubyFirst = userInfo.firstNameKana
    const rubyfamily = userInfo.familyNameKana
    const ruby = `${rubyfamily} ${rubyFirst}`

    const userDisplayName = userInfo.userDisplayName || ''

    const birthDay = userInfo.birthDay || ''
    const countryList = Const.USER_COUNTRY_LIST
    const country = computed(
      () =>
        countryList.find((item) => item.id === userInfo.country)?.name ||
        ({} as selectDataNameType),
    )

    const genderList = Const.USER_GENDER_LIST
    const gender = computed(
      () => genderList.find((item) => item.id === userInfo.gender)?.name || ({} as Multilingual),
    )

    const prefectureList = Const.USER_PREFECTURES_LIST
    const prefecture = computed(
      () =>
        prefectureList.find((item) => item.id === userInfo.prefecture)?.name ||
        ({} as selectDataNameType),
    )
    const zipcode = userInfo.zipcode || ''
    /** 名前（ふりがな）の入力を行うかどうか */
    const userNameRubyEnabled = userInfo.lang === 'ja' || !userInfo.lang
    return {
      cloneDeep,
      userInfo,
      name,
      ruby,
      userDisplayName,
      birthDay,
      gender,
      country,
      prefecture,
      zipcode,
      ownOrganization: contractInfoStore.ownOrganization,
      fetchOrganizations: contractInfoStore.fetchOrganizations,
      saveUser: mypagePageStore.saveUser,
      updateOrganization: mypagePageStore.updateOrganization,
      userNameRubyEnabled,
      saveMissionHistoryForProfile,
      saveMissionHistory,
    }
  },
  methods: {
    async saveUserInfo() {
      /**
       * 変更処理
       */
      const loader = this.$loading.show()

      /** 組織情報更新 */
      const newOrgData = this.cloneDeep(this.ownOrganization) as OrganizationDocument
      // フォームに入力した姓名をorganizationNameにセットする
      newOrgData.organizationName = `${this.userInfo.familyName} ${this.userInfo.firstName}`
      const updateOrganizationResult = await this.updateOrganization(newOrgData)

      if (!updateOrganizationResult.isSuccess) {
        loader.hide()
        // API通信不具合
        await MessageDialogStore.value.open({
          errorApiResponse: updateOrganizationResult.response,
        })
        return
      }
      await this.fetchOrganizations()

      const apiResult = await this.saveUser()
      if (apiResult.isSuccess) {
        if (apiResult.data) {
          this.saveMissionHistoryForProfile(apiResult.data)
        }
        // グローバルストアのユーザー情報を更新
        await UserStore.value.fetchUserData(LoginStore.value.loginId)

        this.$emit('success-regist')
      } else {
        // API通信不具合
        MessageDialogStore.value.open({
          title: this.$tc('MypagePage.MypageUserConfirmPage.errors.title') as string,
          message: this.$tc('MypagePage.MypageUserConfirmPage.errors.message') as string,
          errorApiResponse: apiResult?.response,
        })
      }
      loader.hide()
    },
  },
})
