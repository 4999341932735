var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "one-time-pass-with-no-plan-complete-pane" },
    [
      _c(
        "h3",
        { staticClass: "one-time-pass-with-no-plan-complete-pane__title" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$tc(
                  "MypagePage.MypageOneTimePass.WithNoPlanCompletePage.title"
                )
              ) +
              " "
          ),
        ]
      ),
      _c("p", {
        staticClass: "one-time-pass-with-no-plan-complete-pane__message",
        domProps: {
          innerHTML: _vm._s(
            _vm.$tc(
              "MypagePage.MypageOneTimePass.WithNoPlanCompletePage.message",
              0,
              {
                area: _vm.oneTimePassAvailableStoreName,
              }
            )
          ),
        },
      }),
      _c(
        "p",
        { staticClass: "one-time-pass-with-no-plan-complete-pane__period" },
        [_vm._v(_vm._s(_vm.applicationPeriodText))]
      ),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector:
              ".one-time-pass-with-no-plan-complete-pane__message",
            visible: true,
          },
        },
        [
          _c("atom-router-button", {
            attrs: {
              to: "/",
              linkText: _vm.$tc("common.toRaceList"),
              colorType: "secondary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }