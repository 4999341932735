var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "common-race-points-card-parts" },
    [
      _c("h3", { staticClass: "common-race-points-card-parts__title" }, [
        _c("span", [_vm._v(_vm._s(_vm.cardTitle))]),
      ]),
      _vm.pointsCardData.thumbnail
        ? _c(
            "figure",
            { staticClass: "common-race-points-card-parts__thumbnail" },
            [
              _c("img", {
                attrs: { src: _vm.pointsCardData.thumbnail, alt: "" },
              }),
            ]
          )
        : _vm._e(),
      _vm.pointsCardData.catch
        ? _c("h4", {
            staticClass: "common-race-points-card-parts__catch",
            domProps: {
              textContent: _vm._s(_vm.pointsCardData.catch[_vm.$i18n.locale]),
            },
          })
        : _vm._e(),
      _vm.pointsCardData.text
        ? _c("p", {
            staticClass: "common-race-points-card-parts__text",
            domProps: {
              innerHTML: _vm._s(_vm.pointsCardData.text[_vm.$i18n.locale]),
            },
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.pointsCardData.to
        ? _c(
            "p",
            [
              _c("atom-icon-router-button", {
                staticClass: "common-race-points-card-parts__lap-link",
                attrs: {
                  to: _vm.pointsCardData.to,
                  linkText: _vm.$tc("RaceListPage.Information.play.thisLap"),
                  theme: "light",
                  colorType: "secondary",
                  size: "xxsmall",
                  imgPath: _vm.playIconPath,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }