var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-video-pane",
      class: [
        "race-video-pane--" + _vm.screenOrientationType,
        { "blank-screen": !_vm.isVideoPlayerCanPlay },
        { "view-overlay": _vm.overlayVisible },
      ],
    },
    [
      !_vm.isVideoPlayerCanPlay || _vm.lockOrientation !== undefined
        ? _c(
            "svg",
            {
              staticClass: "race-video-pane__blank_logo",
              attrs: {
                width: "110",
                height: "31",
                viewBox: "0 0 110 31",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("g", { attrs: { "clip-path": "url(#clip0_1688_22897)" } }, [
                _c("path", {
                  attrs: {
                    d: "M6.15853 0C2.79933 0 0 2.80543 0 6.17195V12.3439C0 15.7805 2.79933 18.5158 6.15853 18.5158H30.8626V24.6878H12.1071C10.2875 24.6878 8.46798 25.2489 6.99833 26.2308L0 31H30.9326C34.3618 31 37.0911 28.1946 37.0911 24.8281V18.6561C37.0911 15.2195 34.2918 12.4842 30.9326 12.4842H6.15853V6.17195H71.3829C73.2025 6.17195 75.0221 5.61086 76.4917 4.62896L83.4201 0H6.15853V0ZM43.2497 18.586V31H49.4082V18.586H61.3053C63.1249 18.586 64.9445 18.0249 66.4141 17.043L73.3425 12.414H49.4782C46.049 12.414 43.2497 15.1493 43.2497 18.586Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M93.7075 31C102.249 31 109.174 24.0604 109.174 15.5C109.174 6.93959 102.249 0 93.7075 0C85.1657 0 78.2412 6.93959 78.2412 15.5C78.2412 24.0604 85.1657 31 93.7075 31Z",
                    fill: "#00DBA6",
                  },
                }),
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M91.0482 13.1856L90.6283 12.7648C89.9285 12.0634 88.9487 11.6426 87.8989 11.6426H85.6595C84.6097 11.6426 83.7 12.4842 83.7 13.6064V15.9209C83.7 16.9729 84.5398 17.8847 85.6595 17.8847H89.5086C90.1384 17.8847 90.6983 17.6041 91.0482 17.1132V19.8485H85.6595C84.6097 19.8485 83.7 20.6901 83.7 21.8123H90.9782C91.0482 21.8123 91.1182 21.8123 91.1882 21.8123C91.3981 21.8123 91.6081 21.7421 91.748 21.672C92.4479 21.3914 92.9377 20.6901 92.9377 19.9186V11.6426C91.958 11.6426 91.1882 12.2738 91.0482 13.1856ZM90.9782 15.8507H85.5895V13.5362H90.9782V15.8507ZM102.176 11.6426H96.017C95.1772 11.6426 94.4774 12.3439 94.4774 13.1856V16.2715C94.4774 17.1132 95.1772 17.8145 96.017 17.8145H102.176C103.015 17.8145 103.715 17.1132 103.715 16.2715V13.1856C103.715 12.2738 103.015 11.6426 102.176 11.6426ZM101.826 15.8507H96.4369V13.5362H101.826V15.8507Z",
                    fill: "black",
                  },
                }),
              ]),
              _c("defs", [
                _c("clipPath", { attrs: { id: "clip0_1688_22897" } }, [
                  _c("rect", {
                    attrs: { width: "109.174", height: "31", fill: "white" },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isFetchedRaceInfo && !_vm.isLiveNotStarted
        ? _c("video-player-section", {
            staticClass: "race-video-pane__video",
            attrs: {
              viewMode: _vm.viewContentsMode,
              screenOrientationType: _vm.screenOrientationType,
              videoFullScale: _vm.videoFullScaleStatus,
              movieStartActualTime: _vm.movieStartActualTime,
              togglePlayMode: _vm.togglePlayMode,
            },
          })
        : _vm._e(),
      _vm.showGpsMap
        ? _c("circuit-map-section", {
            staticClass: "race-video-pane__circuit",
            attrs: {
              animateGpsMapCar: _vm.animateGpsMapCar,
              screenOrientationType: _vm.screenOrientationType,
            },
          })
        : _vm._e(),
      _c("div", {
        staticClass: "race-video-pane__overlay-trigger",
        on: { pointerdown: _vm.visibleOverlay },
      }),
      _vm.overlayVisible
        ? _c("video-overlay-section", {
            staticClass: "race-video-pane__overlay",
            attrs: {
              screenOrientationType: _vm.screenOrientationType,
              lockOrientation: _vm.lockOrientation,
              viewMode: _vm.viewContentsMode,
              videoPaused: _vm.videoPaused,
              videoSlow: _vm.videoSlow,
              videoFullScale: _vm.videoFullScaleStatus,
              mapViewEnabled: _vm.showGpsMap,
              hasVolumeControl: _vm.hasVolumeControl,
            },
            on: {
              videoPlayPauseChange: _vm.changeVideoPlayPause,
              videoSlowChange: _vm.changeVideoSlow,
              videoSlowReset: _vm.resetVideoSlow,
              viewModeChange: _vm.changeViewMode,
              overlayRestartTimer: _vm.restartOverlayTimer,
              overlayHide: _vm.hideOverlay,
              fullScaleVideo: _vm.changeFullScaleVideo,
              onLiveClicked: _vm.onLiveClicked,
              viewMapMode: _vm.toggleMapMode,
              handleTouchStartSeekBar: _vm.handleTouchStartSeekBar,
              handleTouchEndSeekBar: _vm.handleTouchEndSeekBar,
              setOrientationTimer: _vm.setOrientationTimer,
              handleTouchEndVolumeSeekBar: _vm.handleTouchEndVolumeSeekBar,
              handleVolumeMute: _vm.handleVolumeMute,
            },
          })
        : _vm._e(),
      _c("video-information-section", {
        staticClass: "race-video-pane__information",
        attrs: {
          informationMessage: _vm.currentRaceInformation,
          screenOrientationType: _vm.screenOrientationType,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }