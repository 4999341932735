import { computed } from '@vue/composition-api'
import _, { clone, now, orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RaceDocument, { RaceType } from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import AngleMovieChangeInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieChangeInfoDocument'
import Logger from '@/util/logger/Logger'
import { AngleMoviePlayInfoType } from '@/store/stores/collectionModule/documents/angleMovie/AngleMoviePlayInfoType'
import AngleInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleInfoDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import RaceParticipatingPlayersDocument from '@/store/stores/collectionModule/documents/race/RaceParticipatingPlayersDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import Const from '@/util/Const'

/**
 * レース情報と、レースに関連する動画情報を操作するための処理を取得する。
 */
export default function useRaces() {
  // Collection modules
  const raceCollectionModule = CollectionModule.createStore(RaceDocument)
  const contentsInfoCollectionModule = CollectionModule.createStore(ContentsInfoDocument)
  const angleMovieInfoCollectionModule = CollectionModule.createStore(AngleMovieInfoDocument)
  const angleMovieChangeInfoCollectionModule = CollectionModule.createStore(
    AngleMovieChangeInfoDocument,
  )
  const angleInfoCollectionModule = CollectionModule.createStore(AngleInfoDocument)
  const playerCollectionModule = CollectionModule.createStore(PlayerDocument)
  const participatingPlayersCollectionModule = CollectionModule.createStore(
    RaceParticipatingPlayersDocument,
  )
  const startEventCollectionModule = CollectionModule.createStore(HighlightDocument)

  // API Requests
  /**
   * 大会に関連するレースのリストを取得する
   * @return APIレスポンス
   */
  const fetchRaces = async (championshipMasterId: string) => {
    const result = await raceCollectionModule.fetch({
      query: { filter: { championshipMasterId }, sort: 'scheduleDate' },
    })
    if (!result.isSuccess) {
      Logger.info(
        `useRaces#fetchRaces: Failed to fetch race data. championshipMasterId: ${championshipMasterId}, result: ${result}`,
      )
      return [result]
    }

    // 予選情報と、その予選内のスタートイベントを取得し、疑似的にQ0,Q1-A,Q1-B,Q2のレース情報を作成する。
    const raceIds = raceCollectionModule.data
      .map((raceData) => {
        if (raceData.raceType === 'QUALIFYING') return raceData.id
        return undefined
      })
      .filter((raceId) => raceId !== undefined)
    if (raceIds.length > 1) {
      Logger.info(`useRaces#fetchRaces: Multiple qualifying detected. raceIds: ${raceIds}`)
    } else if (raceIds.length === 1) {
      const targetRaceId = raceIds[0]
      // 予選が1つのみの場合、その予選レースに登録されているスタートイベントを取得する。
      return Promise.all([
        startEventCollectionModule.fetch({
          query: {
            filter: { matchId: targetRaceId, 'playEvents.playEventId': 'playStart' },
          },
        }),
        contentsInfoCollectionModule.fetch({ targetId: targetRaceId }),
      ])
    }
    return [result]
  }

  /**
   * 対象のレースを取得する
   * @param raceId レースID
   */
  const fetchTargetRace = (raceId: string) => raceCollectionModule.fetch({ targetId: raceId })

  /**
   * 指定範囲日時に開催されるレース一覧を取得する
   * @param from
   * @param to
   */
  const fetchRacesForSpecifiedRange = (from: number, to: number) =>
    raceCollectionModule.fetch({
      query: { filter: { scheduleDate: { $gte: from, $lt: to } } },
    })

  /**
   * メイン映像とオンボード映像（と英語の中継映像）の動画情報を取得する
   * @param raceIds レースID
   */
  const fetchMovieInfo = async (raceIds: Array<string>) => {
    if (raceIds.length === 0) {
      return undefined
    }

    return Promise.all([
      contentsInfoCollectionModule.fetch({
        query: { filter: { matchId: { $in: raceIds } } },
      }),
      angleMovieInfoCollectionModule.fetch({
        query: { filter: { matchId: { $in: raceIds } } },
      }),
    ])
  }

  /**
   * レースのアングル動画情報を取得する。
   * @param raceId レースID
   * @param organizationId 組織ID
   */
  const fetchAngleMovieChangeInfo = (raceId: string, organizationId: string) =>
    angleMovieChangeInfoCollectionModule.fetch({
      url: `${
        process.env.VUE_APP_API_BASE_URL as string
      }/${organizationId}/data/record/game_match/${raceId}/angle/0`,
    })

  /**
   * レース参加選手を取得する。
   * @param championshipMasterId 大会マスタID
   * @param raceId レースID
   * @param lastModifiedDate 前回のレース参加選手情報取得日時。指定した場合、この日時以降に登録、更新されたデータのみを再取得する
   */
  const fetchParticipatingPlayers = (
    championshipMasterId: string,
    raceId: string,
    lastModifiedDate?: number,
  ) =>
    Promise.all([
      playerCollectionModule.fetch({
        query: {
          filter: {
            championshipMasterId,
            ...(lastModifiedDate
              ? { _lastModifiedDate: { $gte: lastModifiedDate - Const.HIGHLIGHT_POLING_INTERVAL } }
              : {}),
          },
          sort: 'squadNumber',
        },
        isUnionExistData: !!lastModifiedDate,
      }),
      participatingPlayersCollectionModule.fetch({
        query: {
          filter: {
            matchId: raceId,
            ...(lastModifiedDate
              ? { _lastModifiedDate: { $gte: lastModifiedDate - Const.HIGHLIGHT_POLING_INTERVAL } }
              : {}),
          },
        },
        isUnionExistData: !!lastModifiedDate,
      }),
    ])

  /**
   * 指定したIDのレース情報とコンテンツ情報、アングル情報、レース出場選手情報、ライブチャンネルなどのレース関連情報を取得する
   * @param championshipMasterId 大会ID
   * @param raceId レースID
   * @param organizationId 組織ID
   * @param lastModifiedDate 前回のレース情報取得日時。指定した場合、この日時以降に登録、更新されたデータのみを再取得する
   */
  const fetchRace = async (
    championshipMasterId: string,
    raceId: string,
    organizationId: string,
    lastModifiedDate?: number,
  ) => {
    const results = await fetchParticipatingPlayers(championshipMasterId, raceId, lastModifiedDate)
    const errorResponse = results.find((result1) => !result1.isSuccess)
    if (errorResponse) {
      return [errorResponse]
    }
    const lastModifiedDateFilter = lastModifiedDate
      ? { _lastModifiedDate: { $gte: lastModifiedDate - Const.HIGHLIGHT_POLING_INTERVAL } }
      : {}
    // アングル動画情報とアングル情報を関連づけするため、アングル情報を先に取得する
    const result = await angleInfoCollectionModule.fetch({
      query: { filter: { matchId: raceId, ...lastModifiedDateFilter } },
      isUnionExistData: !!lastModifiedDate,
    })
    if (!result.isSuccess) {
      return [result]
    }
    return Promise.all([
      raceCollectionModule.fetch({ targetId: raceId }),
      contentsInfoCollectionModule.fetch({ targetId: raceId }),
      angleMovieInfoCollectionModule.fetch({
        query: { filter: { matchId: raceId, ...lastModifiedDateFilter } },
        isUnionExistData: !!lastModifiedDate,
      }),
      fetchAngleMovieChangeInfo(raceId, organizationId),
    ])
  }

  /**
   * 指定したレースIDのレースを取得する
   * @param raceIds レースID
   */
  const fetchRacesByRaceIds = async (raceIds: string[]) =>
    raceCollectionModule.fetch({
      query: {
        filter: { matchId: { $in: raceIds } },
      },
    })

  // methods
  /**
   * 取得したレース情報とレース関連情報をクリアする
   */
  const clearRaces = () => {
    raceCollectionModule.clearData()
    contentsInfoCollectionModule.clearData()
    angleMovieInfoCollectionModule.clearData()
    angleMovieChangeInfoCollectionModule.clearData()
    angleInfoCollectionModule.clearData()
    playerCollectionModule.clearData()
    participatingPlayersCollectionModule.clearData()
    startEventCollectionModule.clearData()
  }

  // computed
  /**
   * 予選のスタートイベントを取得する。
   * ユーザゲームイベントはリアルタグ（ライブ配信中に登録されるタグ）であり、DBデータ的にはmovieStartTimeがnullとなっていて、DBデータ取得後に作成日時や撮影開始時刻からBEの処理内でmovieStartTimeを計算して返却している。
   * そのため、movieStartTimeはDB機能を利用してのsortができないので、フロント側でソートする。
   */
  const qualifyingRaceStartEventList = computed(() =>
    orderBy(startEventCollectionModule.data, 'movieStartTime'),
  )

  /**
   * 取得したレース情報一覧
   */
  const raceList = computed(() => raceCollectionModule.data)

  /**
   * 取得したフリー走行一覧
   */
  const freePracticeList = computed(() =>
    raceCollectionModule.data.filter((race) => race.raceType === RaceType.FREE_PRACTICE),
  )

  /**
   * 取得した予選レース一覧
   */
  const qualifyingRaceList = computed(() => {
    const qualifyingRace = raceCollectionModule.data.filter(
      (race) => race.raceType === RaceType.QUALIFYING,
    )
    if (qualifyingRace.length === 1 && qualifyingRace[0].id && qualifyingRace[0].qualifyingLabels) {
      const startEvents = [new HighlightDocument(), ...qualifyingRaceStartEventList.value]
      return qualifyingRace[0].qualifyingLabels
        .filter((label) => label.name)
        .map((label, index) => {
          const qualifyingRaceData = clone(qualifyingRace[0])
          qualifyingRaceData.title = label.name
          qualifyingRaceData.startDate =
            qualifyingRaceData.additionalData?.qualifyingStartDateList?.[index]
          qualifyingRaceData.highlightPlayInfo = {
            highlightId: startEvents?.[index]?.id ?? '',
            movieStartTime: startEvents?.[index]?.movieStartTime ?? 0,
          }
          qualifyingRaceData.qualifyingLabelOrder = index

          return qualifyingRaceData
        })
    }
    return qualifyingRace
  })

  /**
   * 決勝以外のセッション(フリー走行/予選)の一覧
   */
  const sessionsWithOutRace = computed(() => [
    ...freePracticeList.value,
    ...qualifyingRaceList.value,
  ])

  /**
   * 取得した決勝レース一覧
   */
  const mainRace = computed(() =>
    raceCollectionModule.data.filter((race) => race.raceType === RaceType.RACE),
  )

  /**
   * 取得したレース情報
   */
  const targetRace = computed(() => raceCollectionModule.data[0])

  /**
   * 取得したコンテンツ情報
   */
  const contentsInfo = computed(() => contentsInfoCollectionModule.data[0])

  /**
   * 取得したコンテンツ情報一覧
   */
  const contentsInfos = computed(() => contentsInfoCollectionModule.data)

  /**
   * 取得したアングル動画情報
   */
  const angleMovieInfos = computed(() => angleMovieInfoCollectionModule.data)

  /**
   * 取得したアングル情報
   */
  const angleInfos = computed(() => angleInfoCollectionModule.data)

  /**
   * 取得したアングル情報のID毎のマップ
   */
  const angleInfosById = angleInfoCollectionModule.dataMap

  /**
   * アングル毎のアングル動画の再生情報をマップオブジェクトで取得する。
   */
  const angleMoviePlayInfoByAngleId = computed(() => {
    const angleMovieChangeInfoData = angleMovieChangeInfoCollectionModule.data[0]
    Logger.debug(`useRaces#angleMoviePlayInfo: angleMovieChangeInfo: ${angleMovieChangeInfoData}`)
    const angleMoviePlayInfoMap = _.keyBy(
      angleMovieChangeInfoData?.angle,
      (angleMovieChangeInfo) => angleMovieChangeInfo.angleId,
    )
    angleMoviePlayInfoMap.main = {
      movieStartTime: 0,
    } as AngleMoviePlayInfoType

    return angleMoviePlayInfoMap
  })

  /**
   * レースに参加している選手一覧
   */
  const participatingPlayers = computed(() => {
    let participatingPlayerDataSet: Array<PlayerDocument> = []
    if (participatingPlayersCollectionModule.data[0]) {
      const participatingPlayerInfo = participatingPlayersCollectionModule.data[0].players?.filter(
        (playersData) => playersData.start.movieStartTime === 0,
      )[0]
      if (participatingPlayerInfo) {
        const homePlayerMasterIds = participatingPlayerInfo.homePlayers.playerPositions.map(
          (playerPosition) => playerPosition.playerMasterId,
        )
        const awayPlayerMasterIds = participatingPlayerInfo.awayPlayers.playerPositions.map(
          (playerPosition) => playerPosition.playerMasterId,
        )
        const playerMasterIds = [...homePlayerMasterIds, ...awayPlayerMasterIds]
        // playerMasterIdの配列をPlayerDocumentの配列に変換して返す。
        participatingPlayerDataSet = playerMasterIds
          .map((playerMasterId) => {
            const player = playerCollectionModule.dataMap.value[playerMasterId]
            if (!player) {
              Logger.info(`participatingPlayers: playerMasterId[${playerMasterId}] is not found.`)
            }
            return player
          })
          .filter((player) => player !== undefined)
      }
    }
    // 出場選手情報がない場合、その大会の登録選手を全て返す。
    if (participatingPlayerDataSet.length === 0) {
      participatingPlayerDataSet = playerCollectionModule.data
    }
    return participatingPlayerDataSet
  })

  /**
   * 大会に参加している選手一覧
   */
  const players = computed(() => playerCollectionModule.data)

  /**
   * 大会に参加している選手一覧。
   * IDをキーにして選手情報が値に設定されたマップオブジェクト
   */
  const playersById = computed(() => playerCollectionModule.dataMap.value)

  /** 試合関連情報を最後に取得した日時 */
  let lastRaceDataFetchedDate: number
  /** 試合関連情報を定期取得するためのsetIntervalのハンドルID */
  let pollingHandlerId: number

  /**
   * 試合関連情報を定期的に取得する処理を開始する。
   * @param championshipMasterId 大会ID
   * @param raceId レースID
   * @param organizationId 組織ID
   */
  const startPollingRaceData = (
    championshipMasterId: string,
    raceId: string,
    organizationId: string,
  ) => {
    pollingHandlerId = window.setInterval(() => {
      fetchRace(championshipMasterId, raceId, organizationId)
        .then(() => {
          Logger.debug(
            `useRaces#pollingRaceData: Success to fetch race data. lastRaceDataFetchedDate: ${lastRaceDataFetchedDate}`,
          )
        })
        .then((response) => {
          lastRaceDataFetchedDate = now()
          return response
        })
    }, Const.RACE_DATA_POLING_INTERVAL)
  }
  /**
   * 試合関連情報を定期的に取得する処理を停止する。
   */
  const finishPollingRaceData = () => {
    if (pollingHandlerId) {
      clearInterval(pollingHandlerId)
    }
  }

  return {
    fetchRaces,
    fetchTargetRace,
    fetchRacesForSpecifiedRange,
    fetchRace,
    fetchRacesByRaceIds,
    fetchMovieInfo,
    startPollingRaceData,
    finishPollingRaceData,
    raceList,
    freePracticeList,
    qualifyingRaceList,
    sessionsWithOutRace,
    mainRace,
    targetRace,
    contentsInfo,
    contentsInfos,
    angleInfos,
    angleInfosById,
    angleMovieInfos,
    angleMoviePlayInfoByAngleId,
    fetchParticipatingPlayers,
    players,
    playersById,
    participatingPlayers,
    qualifyingRaceStartEventList,
    clearRaces,
  }
}
