var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timetable-item" }, [
    _c("div", { staticClass: "timetable-item__main" }, [
      _vm.raceDataItem.startDate
        ? _c("time", { staticClass: "timetable-item__time" }, [
            _vm._v(" " + _vm._s(_vm.getStartTime(_vm.raceDataItem.startDate))),
            _c("span", [_vm._v("-")]),
          ])
        : _vm._e(),
      _c("p", { staticClass: "timetable-item__title" }, [
        _vm._v(_vm._s(_vm.raceDataItem.raceTitle)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "timetable-item__tool" },
      [
        _c("atom-icon-router-button", {
          staticClass: "timetable-item__btn",
          class: {
            "timetable-item__btn--disabled": _vm.isDisabled,
            "timetable-item__btn--circuit-mode": _vm.enableCircuitMode,
          },
          attrs: {
            to: !_vm.isDisabled ? _vm.linkTo(_vm.raceDataItem) : "",
            linkText: "",
            theme: "light",
            colorType: "secondary",
            size: "xsmall",
            imgPath: _vm.setPlayIcon(),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }