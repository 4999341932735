

























import { computed, defineComponent } from '@vue/composition-api'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * マイページ: 優待コード: 登録完了ペイン
 */
export default defineComponent({
  name: 'OneTimePassWithNoPlanCompletePane',
  components: { ActionButtonsSection, AtomRouterButton },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { oneTimePass, getTargetAvailableArea } = oneTimePassPageStore

    /**
     * 登録したワンタイムパスコードに紐づく店舗名またはエリア名
     */
    const oneTimePassAvailableStoreName = computed(() => {
      const area = getTargetAvailableArea(oneTimePass.value?.availableArea?.areaId ?? '')
      return area?.storeOrAreaName
    })

    /**
     * ワンタイムパス適用期間テキスト
     * 開始日は今日の日付を表示
     */
    const applicationPeriodText = computed(
      () => oneTimePass.value?.couponApplicationPeriodFromTodayText,
    )

    return {
      oneTimePassAvailableStoreName,
      applicationPeriodText,
    }
  },
})
