












import { defineComponent, PropType } from '@vue/composition-api'
import { NotificationItemType } from '@/components/NotificationPane/NotificationPane.vue'
import NotificationItemParts from '@/components/NotificationPane/NotificationPane/parts/NotificationItemParts.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * Notification リストセクション
 */
export default defineComponent({
  name: 'NotificationListSection',
  components: {
    NotificationItemParts,
  },
  props: {
    notificationList: {
      type: Array as PropType<NotificationItemType[]>,
      default: () => [],
    },
  },
  setup() {
    const isPc = DeviceInfo.isForPC()
    return {
      isPc,
    }
  },
})
