

















import { defineComponent } from '@vue/composition-api'
import OneTimePassCompletePane from '@/components/MypageOneTimePassCompletePage/OneTimePassCompletePane.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'

/**
 * ブラウザ版: マイページ: ワンタイムパス: 内容確認ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassCompletePage',
  components: {
    SubHeaderSection,
    GlobalNavigationPane,
    AtomRouterButton,
    ActionButtonsSection,
    OneTimePassCompletePane,
  },
})
