import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'
import OAuthCodeFlowBrowser, { OAuthCodeFlowType } from '@/util/oauth/OAuthCodeFlowBrowser'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'

/**
 * OAuthCodeFlowBrowserのインスタンスを管理する
 */
export default class OAuthCodeFlowManager {
  static instance: OAuthCodeFlowBrowser<OAuthCodeFlowType> | null = null

  static createOAuthCodeFlowManager<T extends OAuthCodeFlowType>(type: T) {
    this.instance = new OAuthCodeFlowBrowser(type)

    if (!DeviceInfo.isCordova()) {
      LocalStorageAccessor.setOAuthCodeFlowBrowserInfo(
        this.instance.type,
        this.instance.state,
        this.instance.codeVerifier,
      )
    }
  }

  static getOAuthCodeFlowManager() {
    if (!DeviceInfo.isCordova()) {
      const _oAuthCodeFlowManager = LocalStorageAccessor.getOAuthCodeFlowBrowserInfo()
      if (_oAuthCodeFlowManager) {
        this.instance = new OAuthCodeFlowBrowser(
          _oAuthCodeFlowManager.type,
          _oAuthCodeFlowManager.state,
          _oAuthCodeFlowManager.codeVerifier,
        )
      }
    }

    if (!this.instance) {
      Logger.error('OauthManager#getOAuthCodeFlowManager: OAuthCodeFlowManager not found.')
    }

    return this.instance
  }
}
