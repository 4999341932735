var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-one-time-pass-confirm-pane" },
    [
      _c("current-plan-card-section", {
        attrs: {
          sectionTitle: _vm.$tc("MypagePage.plan.selectPlan"),
          plan: _vm.oneTimePassPlan,
          isOneTimePassUsed: true,
          isConfirmed: true,
        },
      }),
      _c(
        "action-buttons-section",
        {
          attrs: {
            scrollTargetSelector: ".mypage-one-time-pass-confirm-pane",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.registration"),
              isDisabled: false,
            },
            on: {
              push: function ($event) {
                return _vm.handlerSubmit()
              },
            },
          }),
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("common.back"),
              isDisabled: false,
              colorType: "tertiary",
            },
            on: {
              push: function ($event) {
                return _vm.handlerCancel()
              },
            },
          }),
        ],
        1
      ),
      _vm.state.isShowLocationErrorModal
        ? _c("common-modal-section", {
            staticClass: "location-error-modal-section",
            on: { close: _vm.emitClose },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("location-error-modal-section", {
                        attrs: {
                          currentLocationInfo: _vm.currentLocationInfo,
                          storeName: _vm.oneTimePassAvailableStoreName,
                          isShowInput: false,
                        },
                        on: { onCLickReUse: _vm.handlerSubmit },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("atom-input-button", {
                        attrs: {
                          linkText: _vm.$i18n.tc("common.close"),
                          colorType: "tertiary",
                          isDisabled: false,
                        },
                        on: {
                          push: function ($event) {
                            return _vm.emitClose()
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3598600865
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }