import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import OAuth3LeggedFlowBrowser from '@/util/oauth/OAuth3LeggedFlowBrowser'
import { XAuthorizationType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import StoreUtil from '@/store/StoreUtil'

export default function useXAuthentication() {
  // SNS動画投稿機能の動作確認用の処理
  const oauthCodeFlow = new OAuth3LeggedFlowBrowser(
    'https://api.twitter.com/oauth/request_token',
    'https://api.twitter.com/oauth/authorize',
    'https://api.twitter.com/oauth/access_token',
    process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL,
    process.env.VUE_APP_X_OAUTH_API_KEY,
    process.env.VUE_APP_X_OAUTH_API_SECRET,
  )

  const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
  const { ownOrganization, updateOrganization } = contractInfoStore

  /**
   * Xの認可を開始する
   */
  const startXAuthentication = () => oauthCodeFlow.startOAuthFlow()

  /**
   * Xの認可完了後の処理、組織情報にXの認可で取得した情報を保存する
   */
  const setXAuthentication = async (oauthToken: string, oauthVerifier: string) => {
    const result = await oauthCodeFlow.getAccessToken(oauthToken, oauthVerifier)
    if (!result) return
    const xAuthorization: XAuthorizationType = {
      oauthToken: result.oauth_token,
      oauthTokenSecret: result.oauth_token_secret,
      acquisitionDate: dayjs().valueOf(),
    }
    const newOrgData = cloneDeep(ownOrganization.value)
    if (!newOrgData) {
      return
    }
    if (!newOrgData.additionalData) newOrgData.additionalData = {}
    if (!newOrgData.additionalData.postSns) {
      newOrgData.additionalData.postSns = {
        x: xAuthorization,
      }
    } else {
      newOrgData.additionalData.postSns = {
        ...newOrgData.additionalData.postSns,
        x: xAuthorization,
      }
    }
    await updateOrganization(newOrgData)
    await contractInfoStore.fetchOrganizations()
  }

  /**
   * 保持しているxのトークンが取得から50分を超えているかどうかを判定する
   */
  const availableXAuthentication = () =>
    !!ownOrganization.value?.xAuthentication &&
    dayjs(ownOrganization.value?.xAuthentication.acquisitionDate).add(50, 'm').valueOf() >
      dayjs().valueOf()

  return { startXAuthentication, setXAuthentication, availableXAuthentication }
}
