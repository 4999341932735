

















import { defineComponent, onMounted, onUnmounted, ref, Ref } from '@vue/composition-api'
import UserEditFormSection from '@/components/MypageUserEditPage/MypageUserEditPane/UserEditFormSection.vue'
import type { OpenBirthdayMenuIdType } from '@/components/common/form/FieldsetBirthdayParts.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * マイページ: 会員情報変更 コンテンツペイン
 */
export default defineComponent({
  name: 'MypageUserEditPane',
  components: {
    UserEditFormSection,
  },
  setup() {
    const userEditFormSection = ref<InstanceType<typeof UserEditFormSection>>()

    const openBirthdayMenuId: Ref<OpenBirthdayMenuIdType> = ref('')

    const wrapperElement = ref<HTMLElement | null>(null)

    const isPc = DeviceInfo.isForPC()

    const closeBirthdayOption = () => {
      openBirthdayMenuId.value = ''
    }

    const handleBirthdayClicked = (menuId: OpenBirthdayMenuIdType) => {
      openBirthdayMenuId.value = openBirthdayMenuId.value === menuId ? '' : menuId
    }

    onMounted(() => {
      wrapperElement.value = document.querySelector('.mypage-wrapper')
      if (isPc && wrapperElement.value) {
        wrapperElement.value.addEventListener('scroll', closeBirthdayOption)
        wrapperElement.value.addEventListener('click', closeBirthdayOption)
      }
    })

    onUnmounted(() => {
      if (isPc && wrapperElement.value) {
        wrapperElement.value.removeEventListener('scroll', closeBirthdayOption)
        wrapperElement.value.removeEventListener('click', closeBirthdayOption)
      }
    })

    return {
      userEditFormSection,
      handleBirthdayClicked,
      openBirthdayMenuId,
    }
  },
  methods: {
    /**
     * 会員情報の確認保存
     */
    confirmUserInfo() {
      return this.userEditFormSection?.confirmUserInfo()
    },
    /**
     * キャンセル通知
     */
    editCancel(): void {
      return this.userEditFormSection?.editCancel()
    },
    /**
     * 確認画面へ遷移
     * @event success-confirm
     */
    moveToConfirm() {
      this.$emit('success-confirm')
    },
    /**
     * キャンセルモーダル表示通知
     * @event show-cancel
     */
    showCancel(showCancelModal: boolean) {
      this.$emit('show-cancel', showCancelModal)
    },
  },
})
