








































import {
  defineComponent,
  ref,
  inject,
  onUnmounted,
  onBeforeMount,
  computed,
} from '@vue/composition-api'
import { now } from 'lodash'
import VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import NotificationListSection from '@/components/NotificationPane/NotificationPane/NotificationListSection.vue'
import PaginationSection from '@/components/common/Pagination/PaginationSection.vue'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import useRaces from '@/store/hook/useRaces'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useNotification from '@/store/hook/useNotification'
import NotificationDocument, {
  NotificationTransitionType,
  NotificationTypeUnion,
} from '@/store/stores/collectionModule/documents/notification/NotificationDocument'
import StoreUtil from '@/store/StoreUtil'
import NotificationTabsParts from '@/components/NotificationPane/NotificationPane/parts/NotificationTabsParts.vue'
import NotificationPointListSection from '@/components/NotificationPane/NotificationPane/NotificationPointListSection.vue'
/**
 * 通知: ハイライト用のデータの選手情報の型
 * TODO: デザイン実装のみなので、後にロジック実装する。不要になるかも？
 */
type playerType = {
  playerName: Multilingual
  playerPictureImagePath?: string
}

/**
 * 通知: ハイライト用のデータの型
 * TODO: デザイン実装のみなので、後にロジック実装する。不要になるかも？
 */
export type HighlightSceneType = {
  player: playerType
  raceTime: string
  thumbnailUri?: string
  comment?: string
}

/**
 * 表示用 通知一覧データの型
 */
export type NotificationItemType = {
  informationId: string
  type: NotificationTypeUnion | null
  date: number | null
  link: string
  title: Multilingual
  message: Multilingual
  note?: string
  url?: string
  extra?: HighlightSceneType
  thumbnail?: string
  mediaType?: 'image' | 'video'
  transitionType?: NotificationTransitionType
  transitionPath?: string | null
  sessionYear?: number | null
  sessionChampionshipMasterId?: string | null
}

/**
 * Notification ペイン
 */
export default defineComponent({
  name: 'NotificationPane',
  components: {
    NotificationPointListSection,
    NotificationTabsParts,
    NotificationListSection,
    PaginationSection,
  },
  setup() {
    // 1ページあたりに表示するお知らせの数
    const NOTIFICATION_COUNT_PER_PAGE = 50

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi
    // hook
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    const { fetchNotifications, notificationList, clearNotifications } = useNotification()
    const { fetchRace, clearRaces } = useRaces()

    // store
    const loginStore = StoreUtil.useStore('LoginStore')
    const notificationStore = StoreUtil.useStore('NotificationStore')

    // ローディング状態
    const isLoading = ref(true)

    // 選択されているタブのID
    const selectedTabId = ref('news')

    const pointData = [
      {
        id: '1',
        date: '2024/01/18',
        title: '「サーキットチェックイン」でXXXポイント獲得',
        expirationDate: '2025/01/31',
      },
      {
        id: '2',
        date: '2023/12/28',
        title: '「シーズンラウンドチェックイン」でXXXポイント獲得',
        expirationDate: '2025/01/31',
      },
      {
        id: '3',
        date: '2023/12/22',
        title: '「チェックインボーナス」でXXXポイント獲得',
        expirationDate: '2025/01/31',
      },
      {
        id: '4',
        date: '2023/12/15',
        title: '「LIVEのオンボード映像を視聴する」でXXXポイント獲得',
        expirationDate: '2025/01/31',
      },
      {
        id: '5',
        date: '2023/12/08',
        title: '「ハイライトのオンボード映像を視聴する」でXXXポイント獲得',
        expirationDate: '2025/01/31',
      },
    ]

    /**
     * タブが押下されたときの処理
     * @param {string} tabId
     */
    const handleTabClicked = (tabId: string) => {
      selectedTabId.value = tabId
    }

    onBeforeMount(async () => {
      const loader = loading.show()
      const fetchResult = await fetchNotifications()
      if (!fetchResult.isSuccess) {
        MessageDialogStore.value.open({
          title: I18n.tc('NotificationPage.errors.fetchNotificationPageDataError.title'),
          message: I18n.tc('NotificationPage.errors.fetchNotificationPageDataError.message'),
        })
      }

      // お知らせを取得した日時をローカルストレージに保存
      LocalStorageAccessor.lastNotificationFetchedDate = String(now())
      notificationStore.isDisplayNotificationIcon.value = false

      loader.hide()
      isLoading.value = false

      // お知らせに関連するリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `NotificationPane#subscribeRTM: Receive notification event. event: ${JSON.stringify(
            data,
          )}`,
        )
        await fetchNotifications()
        // お知らせを取得した日時をローカルストレージに保存
        LocalStorageAccessor.lastNotificationFetchedDate = String(now())
        notificationStore.isDisplayNotificationIcon.value = false
      })
    })

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
      // 取得したレース情報とレース関連情報をクリアする
      clearRaces()
      // 通知一覧をクリア
      clearNotifications()
    })

    // ページネーション: 現在のページ番号
    const currentPage = ref(1)
    // ページネーション: 総ページ数
    const totalPages = computed(() =>
      Math.ceil(notificationList.value.length / NOTIFICATION_COUNT_PER_PAGE),
    )

    /**
     * 遷移先を取得
     */
    const getTransitionPath = (item: NotificationDocument) => {
      const transitionType = item.additionalData?.transition?.type
      const transitionPath = item.additionalData?.transition?.path
      if (!transitionPath || !transitionType) return ''

      if (transitionType === 'url') return transitionPath

      if (transitionType === 'raceVideo') {
        const championshipMasterId = item.additionalData?.transition?.championshipMasterId
        if (!championshipMasterId) return ''
        return `/race-video/${championshipMasterId}/${transitionPath}`
      }

      if (transitionType === 'sfgoAppPage') {
        if (transitionPath === 'ROUNDS') {
          return '/'
        }
        if (transitionPath === 'HOME') {
          return '/home'
        }
        if (transitionPath === 'STANDINGS') {
          return '/standings'
        }
        if (transitionPath === 'MISSION') {
          return '/mission'
        }
        if (transitionPath === 'LINK') {
          return '/link'
        }
        if (transitionPath === 'MYPAGE') {
          return '/mypage'
        }
      }
      return ''
    }

    /**
     * 表示する一覧データ
     * ページネーションの変更をリアクティブに反映する
     */
    const notificationData = computed(() => {
      const startIndex = (currentPage.value - 1) * NOTIFICATION_COUNT_PER_PAGE
      const endIndex = startIndex + NOTIFICATION_COUNT_PER_PAGE
      const notifications = notificationList.value.map((item: NotificationDocument) => ({
        informationId: item.informationId ?? '',
        type: item.type,
        date: item.releaseDate,
        link: getTransitionPath(item),
        title: item.title,
        message: item.contents,
        thumbnailUri: '',
        transitionType: item.additionalData?.transition?.type,
        transitionPath: item.additionalData?.transition?.path,
        sessionYear: item.additionalData?.transition?.year ?? null,
        sessionChampionshipMasterId: item.additionalData?.transition?.championshipMasterId ?? null,
      }))
      return notifications.slice(startIndex, endIndex)
    })

    /**
     * ページ変更イベント
     * @param pageNum ページ番号
     */
    const changePage = (pageNum: number) => {
      currentPage.value = pageNum
      // 一番上までスクロールさせる
      const notificationPane: HTMLElement | null = document.querySelector('.notification-pane')
      if (notificationPane) {
        notificationPane.scrollTop = 0
      }
    }

    /**
     * 対象の画面に遷移する
     * @param notification 対象のお知らせデータ
     */
    const moveToSession = async (notification: NotificationItemType) => {
      if (notification.transitionType === 'raceVideo') {
        const championshipMasterId = notification.sessionChampionshipMasterId
        const raceId = notification.transitionPath

        if (!championshipMasterId || !raceId) return

        // 対象のセッション（レース）を取得
        const loader = loading.show()
        const fetchRaceResponses = await fetchRace(championshipMasterId, raceId, loginStore.orgId)
        loader.hide()
        const failedFetchRaceResponse = [...fetchRaceResponses].find(
          (response) => !response.isSuccess,
        )
        if (failedFetchRaceResponse) {
          MessageDialogStore.value.open({
            title: I18n.tc('NotificationPage.errors.fetchRaceDataError.title'),
            message: I18n.tc('NotificationPage.errors.fetchRaceDataError.message'),
          })
          return
        }
      }

      // 指定の画面に遷移
      router.push(notification.link)
    }

    // TODO: お知らせ画面ポイント獲得履歴の対応時に消す
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'

    return {
      isLoading,
      selectedTabId,
      notificationData,
      pointData,
      currentPage,
      totalPages,
      changePage,
      moveToSession,
      handleTabClicked,
      isIncludeNextRelease,
    }
  },
})
