var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "global-navigation",
      class: "global-navigation__" + _vm.current,
    },
    [
      _c("ul", { staticClass: "global-navigation__list" }, [
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current": _vm.current === "home",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/home" },
              },
              [
                _c("navigation-home-icon-parts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.home")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current": _vm.current === "race",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/" },
              },
              [
                _c("navigation-race-icon-parts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.race")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current":
                _vm.current === "standings",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/standings" },
              },
              [
                _c("navigation-standings-icon-parts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.standings")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current":
                _vm.current === "mission",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/mission" },
              },
              [
                _c("navigation-mission-icon-parts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.mission")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current": _vm.current === "link",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/link" },
              },
              [
                _c("navigation-link-icon-parts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.links")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current":
                _vm.current === "notification",
            },
          },
          [
            _c("navigation-notification-icon-section", {
              attrs: { hasName: true, current: _vm.current },
            }),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "global-navigation__list__item",
            class: {
              "global-navigation__list__item--current":
                _vm.current === "mypage",
            },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "global-navigation__list__item-link",
                attrs: { to: "/mypage" },
              },
              [
                _c("NavigationMyPageIconParts", {
                  staticClass: "global-navigation__list__item-icon",
                }),
                _c(
                  "span",
                  { staticClass: "global-navigation__list__item-name" },
                  [_vm._v(_vm._s(_vm.$tc("navigation.mypage")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }