import { reactive } from '@vue/composition-api'
import { uniq } from 'lodash'
import { StoreBase, ValueType } from '@/store/StoreBase'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import type { ContractType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import useOrganization from '@/store/hook/useOrganization'
import useContractPlan from '@/store/hook/useContractPlan'
import useRole from '@/store/hook/useRole'
import {
  accountPermissionsMap,
  PermissionType,
} from '@/store/stores/pageStore/common/PermissionType'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import { RoleType } from '@/store/stores/pageStore/common/RoleType'
import Const from '@/util/Const'

/**
 * 有料プラン購入時の支払い方法の型
 */
export type PurchasePaymentMethodType = 'AppStore' | 'GooglePlay' | 'CreditCard'

/**
 * 契約情報の初期状態
 */
const initialState = {
  /**
   * ログインユーザーのプランが無料プランかどうか
   */
  isFreePlan: false,
  /**
   * ログインユーザーのプランが有料プランかどうか
   */
  isPaidPlan: false,
  /**
   * ログインユーザーのプランがリードオンリー状態かどうか
   */
  isReadOnlyPlan: false,
  /**
   * ログインユーザーのプランがクーポンプランかどうか
   */
  isCouponPlan: false,
  /**
   * ログインユーザーのプランがトライアルプランかどうか
   */
  isTrialPlan: false,
  /**
   * プランを登録しているかどうか
   */
  hasNoPlan: false,
  /**
   * 有効な契約情報
   */
  effectiveOrgContract: undefined as ContractType | undefined,
  /**
   * 有効なプランのプランマスタ情報
   */
  effectiveContractPlan: undefined as ContractPlanDocument | undefined,
}

/* eslint-disable class-methods-use-this */
class ContractInfoStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    // hook
    const {
      fetchContractPlans,
      fetchTargetContractPlan,
      fetchContractPlansWithCouponPlan,
      contractPlans,
      clearContractPlans,
    } = useContractPlan()
    const {
      fetchOrganizations,
      ownOrganization,
      updateOrganization,
      clearOrganizations,
      ownOrganizationPermission,
    } = useOrganization()
    const { fetchRoles, roles, clearRoles } = useRole()

    /** ログインユーザーに適応されている契約プランを判定するためのフラグをセット
     * 無料プラン
     * 有料プラン
     * クーポンプラン
     * トライアルプラン
     */
    const setLoginUserPlan = (planGroupId: PlanGroupType) => {
      switch (planGroupId) {
        case 'freePlan':
          state.isFreePlan = true
          break
        case 'monthlyPlan':
          state.isPaidPlan = true
          break
        case 'annualPlan':
          state.isPaidPlan = true
          break
        case Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.MONTHLY:
          state.isPaidPlan = true
          break
        case Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL:
          state.isPaidPlan = true
          break
        case Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.MONTHLY:
          state.isPaidPlan = true
          break
        case Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL:
          state.isPaidPlan = true
          break
        case 'limitedTimePaidPlan':
          state.isCouponPlan = true
          break
        case 'limitedTimePaidPlanForAnnual':
          state.isCouponPlan = true
          break
        case 'trialPlan':
          state.isTrialPlan = true
          break
        case 'trialPlanForAnnual':
          state.isTrialPlan = true
          break
        case 'settlementReadonlyPlan':
          state.isReadOnlyPlan = true
          break
        default:
          break
      }
    }

    /** ユーザーに紐づく組織情報、契約情報を取得 */
    const fetchUserContractInfo = async () => {
      // stateに保持している情報をクリアする
      Object.assign(state, initialState)

      const results = []
      const fetchOrganizationsResult = await fetchOrganizations()
      if (!fetchOrganizationsResult.isSuccess) {
        return [fetchOrganizationsResult]
      }
      results.push(fetchOrganizationsResult)

      /** リードオンリープラン状態かどうかを判別する分岐処理 */
      if (ownOrganization.value?.isReadOnly) {
        state.effectiveOrgContract = ownOrganization.value?.contract?.find(
          (v) => v.planType === 'READONLY',
        )
      } else {
        /** ログインユーザーに紐づいた組織情報に存在する有効な契約情報取得
         * 有効なプランの条件 1.かつ2.
         * - 1. 終了日時にnull or 今日以降の日付がセットされている
         * - 2. 既に開始済み
         */
        state.effectiveOrgContract = ownOrganization.value?.contract?.find(
          (contract) =>
            (contract.endDate === null || contract.endDate > new Date().getTime()) &&
            contract.startDate !== null &&
            contract.startDate <= new Date().getTime(),
        )
      }

      /** クーポンプランの場合、masterContractPlanにプラン情報が入っている */
      let fetchContractPlansResult: Response<ContractPlanDocument>
      if (state.effectiveOrgContract?.masterContractPlan) {
        /**
         * クーポンプランの場合の処理
         */
        // クーポンプランの契約プランID
        const contractPlanId = state.effectiveOrgContract?.masterContractPlan.contractPlanId || ''
        // クーポンプランのプラングループID
        const couponPlanGroupId = state.effectiveOrgContract.masterContractPlan.planGroupId
        fetchContractPlansResult = await fetchContractPlansWithCouponPlan(
          contractPlanId,
          couponPlanGroupId,
        )
      } else {
        /**
         * クーポンプラン以外の場合の処理
         */
        fetchContractPlansResult = await fetchContractPlans()
      }
      if (!fetchContractPlansResult.isSuccess) {
        return [fetchContractPlansResult]
      }
      results.push(fetchContractPlansResult)

      /** 有効なプランのプランマスタ情報をセット */
      state.effectiveContractPlan = contractPlans.value.find(
        (plan) => plan.contractPlanId === state.effectiveOrgContract?.contractPlanId,
      )

      /** ログインユーザーに適応されている契約プランをセット */
      setLoginUserPlan(state.effectiveContractPlan?.planGroupId || '')

      if (!ownOrganization.value?.contract) {
        // スタッフアカウントなど契約情報がないアカウントについては有料会員扱いする。
        state.isPaidPlan = true
        // スタッフアカウントなど以外で契約情報がないにも関わらずログインできてしまうユーザー用（有料会員にアップグレードできるようにする）
        state.hasNoPlan = true
      }

      return results
    }

    /**
     * ロールマスタAPIで取得したパーミッションの内、ログインユーザーのロールと一致しているパーミッションを返す
     * @param userRoles
     */
    const getPermissionsByUserRoles = (userRoles: Array<RoleType> | undefined) =>
      uniq(
        roles.value
          .filter((role) => userRoles?.some((userRole) => userRole === role.role))
          .map((filteredRole) => filteredRole.permissions)
          .flat(),
      ) ?? []

    /**
     * 指定された権限を持っているかどうかを判定する
     * @param userRoles
     * @param permission
     */
    const hasPermission = (userRoles: Array<RoleType> | undefined, permission: PermissionType) =>
      // スタッフアカウントなど契約情報がないアカウントについては有料会員扱いする。
      ownOrganization.value?.contract
        ? [
            ...(ownOrganizationPermission.value ?? []),
            ...getPermissionsByUserRoles(userRoles),
          ]?.includes(permission)
        : [...accountPermissionsMap.staff, ...getPermissionsByUserRoles(userRoles)].includes(
            permission,
          )

    /**
     * 契約情報をクリアする
     */
    const clearUserContractInfoData = () => {
      clearOrganizations()
      clearContractPlans()
      clearRoles()
      Object.assign(state, initialState)
    }

    return {
      get isFreePlan() {
        return state.isFreePlan
      },
      get isPaidPlan() {
        return state.isPaidPlan
      },
      get isCouponPlan() {
        return state.isCouponPlan
      },
      get isTrialPlan() {
        return state.isTrialPlan
      },
      get isReadOnlyPlan() {
        return state.isReadOnlyPlan
      },
      get hasNoPlan() {
        return state.hasNoPlan
      },
      get effectiveOrgContract() {
        return state.effectiveOrgContract || null
      },
      get effectiveContractPlan() {
        return state.effectiveContractPlan || null
      },
      // 有効なplanGroupIdからどの支払い方法で有料プランになったかを判定する
      get paymentMethod(): PurchasePaymentMethodType | null {
        if (this.isFreePlan) {
          // 課金ユーザーではない場合、nullを返す
          return null
        }
        if (
          state.effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          state.effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.MONTHLY
        ) {
          return 'AppStore'
        }
        if (
          state.effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL ||
          state.effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.MONTHLY
        ) {
          return 'GooglePlay'
        }
        return 'CreditCard'
      },
      fetchUserContractInfo,
      fetchTargetContractPlan,
      fetchOrganizations,
      contractPlans, // クーポン適用中のユーザーでログインした場合はクーポンプラン情報も含まれている
      ownOrganization,
      fetchRoles,
      clearUserContractInfoData,
      hasPermission,
      updateOrganization,
    }
  }
}
const value: ValueType<ContractInfoStore> = {}

export default {
  createStore: new ContractInfoStore().createStore,
  value: value as Required<typeof value>,
}
