







import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import AppSignupInputPane from '@/components/AppSignupPage/AppSignupInputPane.vue'

export default defineComponent({
  name: 'AppSignupInputPage',
  components: { AppSignupInputPane, HeaderPane },
})
