var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-edit-form-section" },
    [
      _c("fieldset-user-edit-mail-password-parts", {
        attrs: { label: _vm.$tc("formParts.label.email") },
        on: {
          push: function ($event) {
            _vm.emailChangeModal = !_vm.emailChangeModal
          },
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("fieldset-user-edit-mail-password-parts", {
        attrs: { label: _vm.$tc("formParts.label.password"), isMask: true },
        on: {
          push: function ($event) {
            _vm.passwordChangeModal = !_vm.passwordChangeModal
          },
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("fieldset-name-parts", {
        attrs: {
          valueFamily: _vm.familyName,
          valueFirst: _vm.firstName,
          showError: _vm.showNameError,
          errorMessage: _vm.nameErrorMessage,
          required: true,
        },
        on: {
          inputfamilyName: _vm.inputfamilyName,
          inputfirstName: _vm.inputfirstName,
        },
      }),
      _vm.userNameRubyEnabled
        ? _c("fieldset-name-ruby-parts", {
            attrs: {
              valueFamily: _vm.familyNameKana,
              valueFirst: _vm.firstNameKana,
              showError: _vm.showKanaError,
              errorMessage: _vm.kanaErrorMessage,
              required: _vm.userInfo.lang === "ja",
            },
            on: {
              inputfamilyNameKana: _vm.inputfamilyNameKana,
              inputfirstNameKana: _vm.inputfirstNameKana,
            },
          })
        : _vm._e(),
      _c("fieldset-display-name-parts", {
        attrs: {
          showError: _vm.showDisplayNameError,
          errorMessage: _vm.displayNameErrorMessage,
        },
        on: { input: _vm.inputDisplayName },
        model: {
          value: _vm.userDisplayName,
          callback: function ($$v) {
            _vm.userDisplayName = $$v
          },
          expression: "userDisplayName",
        },
      }),
      _vm.isSetBirthday
        ? _c("fieldset-birthday-parts", {
            attrs: {
              openBirthdayMenuId: _vm.openBirthdayMenuId,
              showError: _vm.showBirthDayError,
              errorMessage: _vm.birthDayErrorMessage,
              required: true,
            },
            on: {
              onClickBirthday: _vm.handleBirthdayClicked,
              onClickCloseBirthday: function ($event) {
                return _vm.$emit("onClickCloseBirthday")
              },
            },
            model: {
              value: _vm.birthDay,
              callback: function ($$v) {
                _vm.birthDay = $$v
              },
              expression: "birthDay",
            },
          })
        : _vm._e(),
      _c("fieldset-gender-parts", {
        attrs: { showError: _vm.showGenderError, required: true },
        model: {
          value: _vm.gender,
          callback: function ($$v) {
            _vm.gender = $$v
          },
          expression: "gender",
        },
      }),
      _c("fieldset-country-parts", {
        attrs: { showError: _vm.showCountryError, required: true },
        on: { change: _vm.changeCountry },
        model: {
          value: _vm.country,
          callback: function ($$v) {
            _vm.country = $$v
          },
          expression: "country",
        },
      }),
      _c("fieldset-prefectures-parts", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPrefecturesParts,
            expression: "showPrefecturesParts",
          },
        ],
        attrs: { showError: _vm.showPrefectureError, required: true },
        model: {
          value: _vm.prefecture,
          callback: function ($$v) {
            _vm.prefecture = $$v
          },
          expression: "prefecture",
        },
      }),
      _c("fieldset-zipcode-parts", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showZipcodeParts,
            expression: "showZipcodeParts",
          },
        ],
        attrs: {
          showError: _vm.showZipcodeError,
          errorMessage: _vm.zipcodeErrorMessage,
        },
        model: {
          value: _vm.zipcode,
          callback: function ($$v) {
            _vm.zipcode = $$v
          },
          expression: "zipcode",
        },
      }),
      _c("email-change-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.emailChangeModal,
            expression: "emailChangeModal",
          },
        ],
        on: { close: _vm.sendAuthenticationMail, error: _vm.changeEmailError },
      }),
      _c(
        "titled-modal-section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.emailChangeCompleteModal,
              expression: "emailChangeCompleteModal",
            },
          ],
          staticClass: "user-edit-form-section__mail-change-complete-modal",
          attrs: {
            title: _vm.$tc(
              "MypagePage.MypageUserEditPage.mailSendMessageModalTitle"
            ),
          },
          on: {
            close: function ($event) {
              _vm.emailChangeCompleteModal = false
            },
          },
        },
        [
          _c("modal-message-parts", {
            staticClass:
              "user-edit-form-section__mail-change-complete-modal__message",
            attrs: {
              message: _vm.$tc(
                "MypagePage.MypageUserEditPage.mailEditModalCompleteMessage"
              ),
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "user-edit-form-section__mail-change-complete-modal__attention",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc(
                      "MypagePage.MypageUserEditPage.mailEditModalCompleteAttention"
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("message-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.emailChangeErrorModal,
            expression: "emailChangeErrorModal",
          },
        ],
        attrs: {
          message: _vm.$tc(
            "MypagePage.MypageUserEditPage.mailSendErrorModalTitle"
          ),
        },
        on: {
          close: function ($event) {
            _vm.emailChangeErrorModal = false
          },
        },
      }),
      _c("password-change-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.passwordChangeModal,
            expression: "passwordChangeModal",
          },
        ],
        on: {
          close: _vm.changePasswordComplete,
          error: _vm.changePasswordError,
        },
      }),
      _c("message-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.passwordChangeCompleteModal,
            expression: "passwordChangeCompleteModal",
          },
        ],
        attrs: {
          message: _vm.$tc(
            "MypagePage.MypageUserEditPage.PasswordChangeCompleteModalTitle"
          ),
        },
        on: {
          close: function ($event) {
            _vm.passwordChangeCompleteModal = false
          },
        },
      }),
      _c("message-modal-section", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.passwordChangeErrorModal,
            expression: "passwordChangeErrorModal",
          },
        ],
        attrs: {
          message: _vm.$tc(
            "MypagePage.MypageUserEditPage.PasswordChangeErrorModalTitle"
          ),
        },
        on: {
          close: function ($event) {
            _vm.passwordChangeErrorModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }