import Const from '@/util/Const'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { OneTimePassTypeValueType } from '@/store/stores/collectionModule/documents/coupon/CouponDocument'

/**
 * ユーザーが利用できる状態となっているクーポン情報の型
 * プラン登録なしで利用できるワンタイムパスで利用
 */
type EffectiveCouponType = {
  /**
   * ワンタイムパスの種類（位置情報を確認するタイミング）の型
   */
  type: OneTimePassTypeValueType
  /**
   * クーポンコード（ワンタイムパスコード）
   */
  couponCode: string
}

/**
 * ユーザー情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/314704252/03+API
 */
export default class UserDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/users'

  idAttr = '_id'

  /**
   * ユーザ名
   */
  username = ''

  /**
   * 表示名
   */
  displayName?: string | null = null

  /**
   * メールアドレス
   */
  mailAddress?: string | null = null

  /**
   * 選手との関連づけ
   */
  playerId?: string | null = null

  /**
   * 言語
   */
  lang?: UserLang | null = null

  /**
   * 視聴画質
   */
  viewQuality: keyof typeof Const.RESOLUTION | null = null

  /**
   * ユーザーアイコン画像
   */
  userPicture?: string | null = null

  /**
   * 会員情報の追加項目
   */
  additionalData?: {
    /**
     * プラン登録手続き中の場合に保持する会員登録タイプ
     * （memberTypeと同じ型で定義）
     */
    registrationTypeInProgress?: MemberType
    /**
     * 姓(漢字)
     */
    familyName?: string
    /**
     * 名(漢字)
     */
    firstName?: string
    /**
     * 姓(ふりがな)
     */
    familyNameKana?: string
    /**
     * 名(ふりがな)
     */
    firstNameKana?: string
    /**
     * 生年月日
     */
    birthDay?: number
    /**
     * 性別
     */
    gender?: string
    /**
     * お住まいの国
     */
    country?: string
    /**
     * お住まいの都道府県
     */
    prefecture?: string
    /**
     * 郵便番号
     */
    zipcode?: string
    /**
     * お気に入り選手
     */
    favoritePlayerId?: string
    /**
     * お気に入りチーム
     */
    favoriteTeamId?: string
    /**
     * SFgoアプリの表示名
     */
    userDisplayName?: string
    /**
     * ユーザーが利用できる状態となっているクーポン情報
     */
    effectiveCoupon?: EffectiveCouponType
  }

  /**
   * 表示名
   */
  get name() {
    return this.displayName || this.username
  }

  /**
   * ユーザーイニシャル
   */
  get initial() {
    return this.name.substring(0, 1).toUpperCase()
  }

  /**
   * 姓(漢字)
   */
  get familyName() {
    return this.additionalData?.familyName || null
  }

  /**
   * 名(漢字)
   */
  get firstName() {
    return this.additionalData?.firstName || null
  }

  /**
   * 姓(ふりがな)
   */
  get familyNameKana() {
    return this.additionalData?.familyNameKana || null
  }

  /**
   * 名(ふりがな)
   */
  get firstNameKana() {
    return this.additionalData?.firstNameKana || null
  }

  /**
   * 生年月日
   */
  get birthDay() {
    const { getDisplayDate } = useDisplayDependingOnLang()

    const birthDayNumber = this.additionalData?.birthDay ?? new Date().getTime()
    const birthDay = getDisplayDate(birthDayNumber)
    return birthDay || ''
  }

  /**
   * 性別
   */
  get gender() {
    return this.additionalData?.gender || null
  }

  /**
   * お住まいの国
   */
  get country() {
    return this.additionalData?.country || null
  }

  /**
   * お住まいの都道府県
   */
  get prefecture() {
    return this.additionalData?.prefecture || null
  }

  /**
   * 郵便番号
   */
  get zipcode() {
    return this.additionalData?.zipcode || null
  }

  /**
   * お気に入り選手ID
   */
  get favoritePlayerId() {
    return this?.additionalData?.favoritePlayerId
  }

  /**
   * お気に入りチームID
   */
  get favoriteTeamId() {
    return this?.additionalData?.favoriteTeamId
  }

  /**
   * SFgo用 Display Name
   * ハイライトにコメントした際に表示される
   */
  get userDisplayName() {
    return this?.additionalData?.userDisplayName
  }
}
