



































import { defineComponent } from '@vue/composition-api'
import NotificationNewsIcon from '@/components/NotificationPane/NotificationPane/parts/icons/NotificationNewsIcon.vue'
import NotificationPointIcon from '@/components/NotificationPane/NotificationPane/parts/icons/NotificationPointIcon.vue'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'NotificationTabsParts',
  components: { NotificationPointIcon, NotificationNewsIcon },
  props: {
    selectedTabId: {
      type: String,
      default: 'news',
    },
  },
  setup(props, { emit }) {
    const isPc = DeviceInfo.isForPC()

    /**
     * タブを押下したときの処理
     * @param {string} tabId
     */
    const handleClick = (tabId: string) => {
      emit('onClick', tabId)
    }

    return {
      isPc,
      handleClick,
    }
  },
})
