import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import ContractPlanDocument, {
  PlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useContractTerm from '@/components/hook/useContractTerm'

/**
 * 契約プランの型
 */
export type CouponContractPlanType = {
  /**
   * プラングループID
   */
  planGroupId: PlanGroupType
  /**
   * 開始日時
   */
  startDate?: number | null
  /**
   * 終了日時
   */
  endDate?: number | null
  /**
   * プラン期間
   */
  planDuration?: number | null
  /**
   * プラン情報
   */
  masterContractPlans?: Array<ContractPlanDocument>
}

/**
 * クーポン情報/ユーザー個別クーポン情報を表現する共通クラス
 */
export default class BaseCouponDocument extends DocumentWrapper {
  constructor(initProps?: Partial<BaseCouponDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * 契約プラン
   */
  plans?: Array<CouponContractPlanType> | null = null

  /**
   * クーポン適用期間テキスト
   */
  get couponApplicationPeriodText() {
    const { getCouponApplicationPeriodText } = useContractTerm()
    return getCouponApplicationPeriodText(this.plans)
  }

  /**
   * クーポン適用期間テキスト
   * 開始日は今日の日付を表示
   */
  get couponApplicationPeriodFromTodayText() {
    const { getCouponApplicationPeriodFromTodayText } = useContractTerm()
    return getCouponApplicationPeriodFromTodayText(this.plans)
  }
}
