












import dayjs from 'dayjs'
import {
  defineComponent,
  onBeforeMount,
  Ref,
  WritableComputedRef,
  ref,
  inject,
} from '@vue/composition-api'
import VueRouter from 'vue-router'
import _, { orderBy } from 'lodash'
import { PluginApi } from 'vue-loading-overlay'
import SelectYearSection from '@/components/RaceListPage/RaceListPane/SelectYearSection.vue'
import StandingsListSection from '@/components/StandingsPage/StandingsTopPane/StandingsListSection.vue'
import { StandingsPlayerDataType } from '@/components/StandingsPage/StandingsTopPane/parts/StandingsListPlayerParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useAvailableYears from '@/store/hook/useAvailableYears'

/**
 * Standings: TOP コンテンツペイン
 */
export default defineComponent({
  name: 'StandingsTopPane',
  components: {
    SelectYearSection,
    StandingsListSection,
  },
  inject: ['loading', 'router'],

  setup() {
    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi
    const yearList: Ref<Array<number>> = ref([])

    const standingsPageStore = StoreUtil.useStore('StandingsPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { selectedYear, standingsListByYear, players, teams, fetchStandingsTopPageData } =
      standingsPageStore
    const { fetchMypagePageData } = mypagePageStore

    /** 対象年度のStandingsドライバー一覧 */
    const standingsPlayerList: Ref<Array<StandingsPlayerDataType> | undefined> = ref(undefined)

    /**
     * Standings一覧に表示するデータを作成
     */
    const createStandingsData = () => {
      // 表示対象のStandingsデータ
      const targetStandingsData = standingsListByYear.value[selectedYear.value]?.standings

      return orderBy(
        targetStandingsData?.map((standing) => {
          /**
           * 同一年度内に同じcarNoの選手が複数存在する場合、StandingsデータにplayerShortNameを登録している
           * playerShortName登録されている場合、carNoとplayerShortNameで選手を特定する
           */
          const targetPlayer = players.value.find((player) =>
            standing.playerShortName
              ? player.getDisplayCarNo() === standing.carNo &&
                player.playerShortName.en === standing.playerShortName
              : player.getDisplayCarNo() === standing.carNo,
          )

          /** 所属チーム名 */
          const teamName = teams.value.find(
            (team) => team.sid === targetPlayer?.additionalData?.teamId,
          )?.teamName[I18n.locale]

          return {
            name: targetPlayer?.getPlayerName() ?? {
              ja: '',
              en: '',
            },
            teamName: teamName ?? '',
            playerPictureImagePath: CloudFrontUtil.getSignedUrl(
              targetPlayer?.playerPictureImagePath,
            ),
            rank: standing.rank ? String(standing.rank) : '---',
            rankNum: standing.rank || 999,
            carNo: standing.carNo ?? '',
            carNoNum: Number(standing.carNo) ?? 999,
            totalPts: standing.totalPts ?? 0,
            link: `/standings/details/${targetPlayer?.id}`,
          }
        }),
        ['rankNum', 'carNoNum'],
      )
    }

    onBeforeMount(async () => {
      const { getAvailableYears } = useAvailableYears()
      yearList.value = (await getAvailableYears()).filter((year) => year >= 2022)
      if (router.currentRoute.query.fromDetails === 'true') {
        // Standingsドライバー詳細画面から戻ってきた場合は、取得済みのStandingsデータを表示する
        standingsPlayerList.value = createStandingsData()
        return
      }

      selectedYear.value = _.last(yearList.value) ?? dayjs().year()
      const loader = loading.show()
      try {
        // Standings画面で必要な情報を取得する
        const results = await Promise.all([fetchStandingsTopPageData(), fetchMypagePageData()])
        loader.hide()
        const flatResults = [...results[0], results[1]]

        const failedResult = flatResults.find((result) => !result?.isSuccess)
        if (failedResult) {
          await MessageDialogStore.value.open({
            title: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.title'),
            message: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.message'),
            errorApiResponse: failedResult.response,
          })
          standingsPlayerList.value = undefined
          return
        }
      } catch (e) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.title'),
          message: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.message'),
        })
        standingsPlayerList.value = undefined
        return
      }

      standingsPlayerList.value = createStandingsData()
    })

    return {
      yearList,
      // StandingsPageStore
      selectedYear: selectedYear as WritableComputedRef<number>,
      standingsPlayerList,
      fetchStandingsTopPageData,
      standingsPageStore,
      // メソッド
      createStandingsData,
    }
  },
  methods: {
    /**
     * 年度が変更された場合に呼び出される。
     * 指定された年度のStandings情報を取得する。
     */
    async yearChange(year: number) {
      // ストアで管理している選択中の年度を変更
      this.standingsPageStore.selectedYear.value = year

      const loader = this.$loading.show()
      try {
        // 変更した年度のデータを取得
        const results = await this.fetchStandingsTopPageData()
        loader.hide()
        const failedResult = results.find((result) => !result?.isSuccess)
        if (failedResult) {
          await MessageDialogStore.value.open({
            title: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.title'),
            message: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.message'),
            errorApiResponse: failedResult.response,
          })
          this.standingsPlayerList = undefined
          return
        }
      } catch (e) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.title'),
          message: I18n.tc('StandingsPage.TopPage.errors.fetchStandingsPageDataError.message'),
        })
        this.standingsPlayerList = undefined
        return
      }

      // 変更した年度でStandings一覧表示データを作成する
      this.standingsPlayerList = this.createStandingsData()
    },
  },
})
