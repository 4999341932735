import useChampionships from '@/store/hook/useChampionships'
/**
 * SFgoアプリの年選択プルダウンの選択肢に関する処理をまとめる
 */
let availableYearsCache: number[] | null = null

export default function useAvailableYears() {
  const { fetchChampionshipsCount } = useChampionships()
  /**
   * アプリが対応している年度一覧を取得する。大会マスタに登録されているデータを参照し、以下の通り年度一覧を返す。
   * - 大会マスタに今年のデータが存在する: VUE_APP_SFGO_START_YEARから今年まで
   * - 大会マスタに今年のデータが存在しない: VUE_APP_SFGO_START_YEARから去年まで
   */
  const getAvailableYears = async () => {
    // キャッシュされている場合、再生成しない
    if (availableYearsCache) {
      return availableYearsCache
    }
    const todayYear = new Date().getFullYear()
    const championshipsCountResult = await fetchChampionshipsCount(todayYear)
    const firstYear = Number(process.env.VUE_APP_SFGO_START_YEAR as string)
    const lastYear =
      championshipsCountResult.isSuccess && championshipsCountResult.response?.data.count > 0
        ? todayYear
        : todayYear - 1
    availableYearsCache = Array.from({ length: lastYear - firstYear + 1 }, (_, i) => firstYear + i)
    return availableYearsCache
  }
  return {
    getAvailableYears,
  }
}
