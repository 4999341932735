















































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { orderBy, uniqBy, cloneDeep } from 'lodash'
import RankingCardWinnerParts from '@/components/RaceListPage/RaceListPane/parts/RankingCardWinnerParts.vue'
import RankingPlayerListItemParts from '@/components/RaceListPage/RaceListPane/parts/RankingPlayerListItemParts.vue'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'

export type PlayerLinkType = 'thisLap' | 'firstLap' | 'lastLap' | 'poleLap' | 'radio'
export type PlayerLinksData = {
  type: PlayerLinkType
  link: string
}
export type PlayerRankData = {
  number: number
  name: Multilingual
  img: string
  bestData: string
  dispRank: number | null
  bestDataNum: number
  links: PlayerLinksData[]
}

export type RankingCardDataTitle =
  | 'WINNER'
  | 'POLE POSITION'
  | 'TOP SPEED'
  | 'BEST LAP'
  | 'SECTOR 1 BEST TIME'
  | 'SECTOR 2 BEST TIME'
  | 'SECTOR 3 BEST TIME'
  | 'SECTOR 4 BEST TIME'
export type RankingCardData = {
  title: RankingCardDataTitle
  playerData: PlayerRankData[]
}

/**
 * レース一覧画面: レース ランキング カードコンポーネント
 */
export default defineComponent({
  name: 'RaceRankingCardParts',
  components: {
    RankingCardWinnerParts,
    RankingPlayerListItemParts,
  },
  props: {
    rankingCardData: {
      type: Object as PropType<RankingCardData>,
      required: true,
    },
  },
  setup: (props) => {
    const open = ref<boolean>(false)

    /** 1位表示のドライバーの人数 */
    const firstPlayerCount = ref(0)
    /** 2位表示のドライバーの人数 */
    const secondPlayerCount = ref(0)

    const toggleOpen = () => {
      open.value = !open.value
    }

    /**
     * 表示する順位を計算する
     * @param pos 順位
     */
    const calcDispRank = (pos: number) => {
      if (pos === 0) {
        // データ上1番目の順位の表示は1になる
        return 1
      }

      if (pos === 1) {
        // データ上2番目の順位の表示順位データ
        return firstPlayerCount.value + 1
      }

      // データ上3番目の順位の表示順位データ
      return firstPlayerCount.value + secondPlayerCount.value + 1
    }

    /**
     * 指定した順位のドライバーのレコードと、動画再生画面、または、無線再生のためのリンクを取得する。
     * @param pos 順位
     */
    const getPlayerDataByPosData = (pos: number) => {
      // 数値が小さい順にbestDataNumを並べる
      const ranking = orderBy(uniqBy(props.rankingCardData.playerData, 'bestDataNum')).map(
        (playerData) => playerData.bestDataNum,
      )

      const posData: Array<PlayerRankData> = []
      props.rankingCardData.playerData.forEach((player) => {
        const dispPlayerData = cloneDeep(player)

        /**
         * ドライバーのランキングデータのbestDataNumが以下のranking[順位]と一致している場合は、表示対象とする
         * 1位 ranking[0]
         * 2位 ranking[1]
         * 3位 ranking[2]
         */
        if (dispPlayerData.bestDataNum === ranking[pos]) {
          dispPlayerData.dispRank = calcDispRank(pos)
          posData.push(dispPlayerData)
        }
      })

      if (pos === 0) {
        // 1位表示のドライバーの人数
        firstPlayerCount.value = posData.length
      }

      if (pos === 1) {
        // 2位表示のドライバーの人数
        secondPlayerCount.value = posData.length
      }

      return posData
    }

    return {
      open,
      toggleOpen,
      getPlayerDataByPosData,
    }
  },
})
