













import { defineComponent, PropType } from '@vue/composition-api'
import NotificationPointItemParts, {
  PointData,
} from '@/components/NotificationPane/NotificationPane/parts/NotificationPointItemParts.vue'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'NotificationPointListSection',
  components: { NotificationPointItemParts },
  props: {
    listData: {
      type: Array as PropType<PointData[]>,
      required: true,
    },
  },
  setup() {
    const isPc = DeviceInfo.isForPC()
    return {
      isPc,
    }
  },
})
