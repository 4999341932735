import RaceDocument, { RaceType } from '@/store/stores/collectionModule/documents/race/RaceDocument'
import { PermissionType, RacePermissionType } from '@/store/stores/pageStore/common/PermissionType'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'

/**
 * ユーザー権限の情報を参照するための処理を取得する。
 */
export default function usePermission() {
  /**
   * 指定された権限を持っているかどうかを判定する。
   * @param permission 権限
   */
  const hasPermission = (permission: PermissionType) =>
    ContractInfoStore.value.hasPermission?.(LoginStore.value.userRoles, permission)

  /**
   * 動画を視聴できるかどうか
   * race.additionalData.publishingが存在する場合は、Management > レース公開範囲設定の設定に応じて視聴権限を判定する
   * @param race
   * @param permission
   */
  const canShowRace = (race: RaceDocument, permission: RacePermissionType | null) => {
    if (race.additionalData?.publishing) {
      if (ContractInfoStore.value.isFreePlan) {
        return race.additionalData.publishing.freeUser
      }
      return race.additionalData.publishing.paidUser
    }

    // レース種別が存在しないレース（permissionにnullが渡ってくるパターン）は存在しないはずだが、レース種別が存在しないレースについてはtrue（閲覧可能）を返しておく
    return permission
      ? ContractInfoStore.value.hasPermission(LoginStore.value.userRoles, permission)
      : true
  }

  /**
   * レース種別に応じてcanShowRaceに渡す権限を返す
   * @param raceType レース種別
   */
  const getTargetRacePermission = (
    raceType: keyof typeof RaceType | undefined,
  ): RacePermissionType | null => {
    switch (raceType) {
      case 'FREE_PRACTICE':
        return 'showFreeRunMovie'
      case 'QUALIFYING':
        return 'showQualifyingRaceMovie'
      case 'RACE':
        return 'showFinalRaceMovie'
      default:
        return null
    }
  }

  return {
    hasPermission,
    canShowRace,
    getTargetRacePermission,
  }
}
