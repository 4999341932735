import { computed } from '@vue/composition-api'
import _ from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import useAvailableYears from '@/store/hook/useAvailableYears'

/**
 * 選手データを操作するための処理を取得する。
 */
export default function usePlayer() {
  // Collection modules
  const playerCollectionModule = CollectionModule.createStore(PlayerDocument)

  /**
   * 選手情報一覧を取得する。
   * このメソッドは、アプリが対応している年度のうち、最新の年度の選手マスタを取得する。
   * @return 選手情報取得のレスポンス
   */
  const fetchPlayers = async () => {
    // アプリが対応している年度のうち、最新の年度の選手マスタを取得する.
    const { getAvailableYears } = useAvailableYears()
    const availableYear = _.last(await getAvailableYears())
    // 大会マスタの開始・終了日時は日本時間を基準に設定しているため、タイムゾーンは+0900にしている
    const startDate = new Date(`${availableYear}-01-01T00:00:00+0900`).getTime()
    const endDate = new Date(`${availableYear}-12-31T23:59:59+0900`).getTime()

    return playerCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        sort: 'startDate',
      },
    })
  }

  /**
   * 対象年度の選手情報一覧を取得する。
   * @return 選手情報取得のレスポンス
   */
  const fetchTargetYearPlayers = (year: number) => {
    // 対象年度の選手マスタを取得する.
    // 大会マスタの開始・終了日時は日本時間を基準に設定しているため、タイムゾーンは+0900にしている
    const startDate = new Date(`${year}-01-01T00:00:00+0900`).getTime()
    const endDate = new Date(`${year}-12-31T23:59:59+0900`).getTime()

    return playerCollectionModule.fetch({
      query: {
        filter: { startDate: { $gte: startDate }, endDate: { $lte: endDate } },
        sort: '-startDate',
      },
    })
  }

  /**
   * 取得した選手情報一覧
   */
  const players = computed(() => _.orderBy(playerCollectionModule.data, 'getDisplayCarNoNumeric'))

  /**
   * 指定されたplayerIdの選手マスタを取得する。
   *
   * @param playerId 選手ID
   */
  const findPlayerById = (playerId: string) =>
    players.value.find((player) => player.playerId === playerId)

  /**
   * 指定されたplayerIdの選手マスタ一覧を取得する。（=大会ごとに登録されている特定選手の選手情報）
   *
   * @param playerId 選手ID
   */
  const findPlayersById = (playerId: string) =>
    players.value.filter((player) => player.playerId === playerId)

  // methods
  /**
   * 取得した選手情報をクリアする
   */
  const clearPlayers = () => {
    playerCollectionModule.clearData()
  }

  return {
    fetchPlayers,
    fetchTargetYearPlayers,
    players,
    findPlayerById,
    findPlayersById,
    clearPlayers,
  }
}
