<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 9C2.75 12.866 5.88401 16 9.75 16C13.616 16 16.75 12.866 16.75 9C16.75 5.13401 13.616 2 9.75 2C5.88401 2 2.75 5.13401 2.75 9ZM13.6067 8.1955C13.6067 6.8185 12.5267 6.0625 10.9067 6.0625H6.5957V12.3625H8.0357V10.3285H10.9067C12.5267 10.3285 13.6067 9.5905 13.6067 8.1955ZM12.1397 8.2045C12.1397 8.9245 11.5997 9.1405 10.7897 9.1405H8.0357V7.2685H10.7897C11.5997 7.2685 12.1397 7.4845 12.1397 8.2045Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NotificationPointIcon',
})
</script>
