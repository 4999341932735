




































import { defineComponent } from '@vue/composition-api'
import MypagePointExpirationDateTableSection from '@/components/MypagePointExpirationDatePage/MypagePointExpirationDatePane/MypagePointExpirationDateTableSection.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import StoreUtil from '@/store/StoreUtil'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MypagePointExpirationDatePane',
  components: {
    AtomRouterButton,
    AtomIconLinkButton,
    MypagePointExpirationDateTableSection,
  },
  setup() {
    // TODO: ポイント獲得履歴表示対応時に消す
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'

    const mypagePointExpirationDatePageStore = StoreUtil.useStore('MypagePageStore')
    const { userPointsWithExpirationByExpirationDate } = mypagePointExpirationDatePageStore

    const pointDescriptionPageUrl =
      I18n.locale === 'ja'
        ? Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.JA
        : Const.EXTERNAL_LINKS.ABOUT_MEMBER_RANK.EN

    return {
      isIncludeNextRelease,
      pointDescriptionPageUrl,
      userPointsWithExpirationByExpirationDate,
    }
  },
})
