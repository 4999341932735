var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-point-item-parts" }, [
    _c("div", { staticClass: "notification-point-item-parts__header" }, [
      _c(
        "div",
        { staticClass: "notification-point-item-parts__icon" },
        [_c("notification-point-icon")],
        1
      ),
      _c("p", { staticClass: "notification-point-item-parts__date" }, [
        _vm._v(_vm._s(_vm.itemData.date)),
      ]),
    ]),
    _c("div", { staticClass: "notification-point-item-parts__body" }, [
      _c("p", { staticClass: "notification-point-item-parts__title" }, [
        _vm._v(_vm._s(_vm.itemData.title)),
      ]),
    ]),
    _c("div", { staticClass: "notification-point-item-parts__footer" }, [
      _c(
        "p",
        { staticClass: "notification-point-item-parts__expiration-date" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$tc("NotificationPage.expirationDate")) +
              " " +
              _vm._s(_vm.itemData.expirationDate) +
              " "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }