var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-point-expiration-date-table-section" },
    [
      _c(
        "table",
        { staticClass: "mypage-point-expiration-date-table-section__table" },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$tc("MypagePage.MypagePointExpirationDatePage.point")
                  )
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$tc(
                      "MypagePage.MypagePointExpirationDatePage.expirationDate"
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.expirationUserPointList, function (ref) {
              var expirationDate = ref[0]
              var userPointWithGrantedSeasonYear = ref[1]
              return _c("tr", { key: expirationDate }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "mypage-point-expiration-date-table-section__point",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-point-expiration-date-table-section__point-data",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            userPointWithGrantedSeasonYear.point.toLocaleString()
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-point-expiration-date-table-section__point-unit",
                      },
                      [_vm._v("P")]
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass:
                      "mypage-point-expiration-date-table-section__date",
                  },
                  [_vm._v(" " + _vm._s(expirationDate) + " ")]
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }