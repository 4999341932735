var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notification-point-list-section",
      class: { "notification-point-list-section--is-pc": _vm.isPc },
    },
    [
      _c(
        "ul",
        { staticClass: "notification-point-list-section__list" },
        _vm._l(_vm.listData, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              staticClass: "notification-point-list-section__item",
            },
            [
              _c("notification-point-item-parts", {
                attrs: { itemData: item },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }