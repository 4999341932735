import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import { ValidationResultType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * ワンタイムパスのバリデーションに関するhook
 */
export default function useOneTimePassValidation() {
  const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')

  /**
   * ワンタイムパスチェック関数
   * @param inputtedValue 入力されたワンタイムパスコードの値
   * @param oneTimePass ワンタイムパス
   */
  const validateOneTimePass = (
    inputtedValue: string,
    oneTimePass: CouponDocument | undefined,
  ): ValidationResultType => {
    const contractInfoPlans = oneTimePassPageStore.contractInfoPlans.value

    // 入力値がブランクの場合、エラーを返す
    if (inputtedValue === '') {
      return {
        isError: true,
        errorMessage: I18n.tc('formParts.errorMessage.oneTimePassBlank'),
      }
    }

    // ワンタイムパスが見つからなかった場合、エラーを返す
    if (!oneTimePass) {
      return {
        isError: true,
        errorMessage: I18n.tc('formParts.errorMessage.oneTimePassError'),
      }
    }
    // すでに使用済みのワンタイムパスの場合、エラーを返す
    if (oneTimePass && contractInfoPlans.find((v) => v.couponId === oneTimePass?.couponId)) {
      return {
        isError: true,
        errorMessage: I18n.tc('formParts.errorMessage.oneTimePassAlreadyUsed'),
      }
    }

    return {
      isError: false,
      errorMessage: '',
    }
  }

  return {
    validateOneTimePass,
  }
}
