var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "race-link-list-section" },
    [
      _vm._l(_vm.sessionsWithOutRace, function (session, i) {
        return _c(
          "li",
          {
            key: i,
            staticClass: "race-link-list-section__item",
            on: { click: _vm.toggleRaceSelect },
          },
          [
            _c(
              "button",
              {
                staticClass: "race-link-list-section__link",
                class: {
                  "race-link-list-section__link--current":
                    session.sectionId === _vm.currentSectionId,
                  "race-link-list-section__link--disabled":
                    !_vm.hasDistributedMovie(
                      session,
                      _vm.contentsInfos,
                      _vm.angleMovieInfos,
                      _vm.qualifyingRaceStartEventList
                    ),
                },
                attrs: {
                  disabled: !_vm.hasDistributedMovie(
                    session,
                    _vm.contentsInfos,
                    _vm.angleMovieInfos,
                    _vm.qualifyingRaceStartEventList
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.linkTo(session)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(session.raceTitle))]),
                !_vm.hasDistributedMovie(
                  session,
                  _vm.contentsInfos,
                  _vm.angleMovieInfos,
                  _vm.qualifyingRaceStartEventList
                )
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/icon_chevron_right_18__gray50.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/icon/icon_chevron_right_18__white.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
          ]
        )
      }),
      _vm.finalRaces.length > 0
        ? _c(
            "li",
            {
              staticClass: "race-link-list-section__item",
              on: { click: _vm.toggleRaceSelect },
            },
            [
              _vm._l(_vm.finalRaces, function (finalRace) {
                return [
                  _c(
                    "button",
                    {
                      key: finalRace.id,
                      staticClass: "race-link-list-section__link",
                      class: {
                        "race-link-list-section__link--current":
                          finalRace.sectionId === _vm.currentSectionId,
                        "race-link-list-section__link--disabled":
                          !_vm.hasDistributedMovie(
                            finalRace,
                            _vm.contentsInfos,
                            _vm.angleMovieInfos,
                            _vm.qualifyingRaceStartEventList
                          ),
                      },
                      attrs: {
                        disabled: !_vm.hasDistributedMovie(
                          finalRace,
                          _vm.contentsInfos,
                          _vm.angleMovieInfos,
                          _vm.qualifyingRaceStartEventList
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.linkTo(finalRace)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(finalRace.raceTitle))]),
                      !_vm.hasDistributedMovie(
                        finalRace,
                        _vm.contentsInfos,
                        _vm.angleMovieInfos,
                        _vm.qualifyingRaceStartEventList
                      )
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon/icon_chevron_right_18__gray50.svg"),
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon/icon_chevron_right_18__white.svg"),
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }