






























































import { defineComponent, Ref } from '@vue/composition-api'
import CarouselListWrapperParts from '@/components/HomePage/HomePane/parts/CarouselListWrapperParts.vue'
import SceneListCardParts from '@/components/HomePage/HomePane/parts/SceneListCardParts.vue'
import HighlightsCardParts from '@/components/HomePage/HomePane/parts/HighlightsCardParts.vue'
import StoreUtil from '@/store/StoreUtil'
import type { NewArrivalHighlightDataType } from '@/store/stores/pageStore/HomePage/HomePageStore'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import type { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import HighlightCommentDocument from '@/store/stores/collectionModule/documents/highlight/HighlightCommentDocument'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'

/**
 * HOME SFgo’s HIGHLIGHTS セクション
 */
export default defineComponent({
  name: 'HighlightsSceneSection',
  computed: {
    HighlightDocument() {
      return HighlightDocument
    },
  },
  components: {
    HighlightsCardParts,
    CarouselListWrapperParts,
    SceneListCardParts,
  },
  props: {
    enabledCarouselList: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const homePageStore = StoreUtil.useStore('HomePageStore')
    const {
      newArrivalHighlightAndCommentData,
      championships,
      raceList,
      retrieveNameUsersByUserId,
      highlightsById,
      commentsByHighlightId,
    } = homePageStore

    return {
      championships: championships as Ref<Array<ChampionshipDocument>>,
      raceList: raceList as Ref<Array<RaceDocument>>,
      newArrivalHighlightAndCommentData: newArrivalHighlightAndCommentData as Ref<
        Array<NewArrivalHighlightDataType>
      >,
      retrieveNameUsersByUserId: retrieveNameUsersByUserId as Ref<
        Record<string, UserRetrieveNameResponseType>
      >,
      highlightsById: highlightsById as Ref<Record<string, HighlightDocument>>,
      commentsByHighlightId: commentsByHighlightId as Ref<
        Record<string, Array<HighlightCommentDocument>>
      >,
    }
  },
})
