

































import { computed, defineComponent, inject, onMounted, PropType, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import VueRouter from 'vue-router'
import { LoaderComponent } from 'vue-loading-overlay'
import useLogin from '@/components/hook/useLogin'
import LoginHeaderSection from '@/components/LoginPage/LoginHeaderSection.vue'
import LoginSection from '@/components/LoginPage/LoginPane/LoginSection.vue'
import AlertOverlaySection from '@/components/common/overlay/AlertOverlaySection.vue'
import LocalCache from '@/util/localcache/LocalCache'
import NativeVideoPlayer from '@/util/videoplayer/NativeVideoPlayer'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import Logger from '@/util/logger/Logger'
import MypagePageStore from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import NotificationStore from '@/store/stores/pageStore/common/NotificationStore'
import DeviceInfo from '@/util/DeviceInfo'
import useContract from '@/components/hook/useContract'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import usePushNotification from '@/store/hook/notification/usePushNotification'
import OAuthCodeFlowManager from '@/util/oauth/OAuthCodeFlowManager'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'

export type DataType = {
  showLoading: boolean
  hasLoginError: boolean
  hasNetworkError: boolean
}

/**
 * ログインタイプ指定
 */
export type LoginType =
  | 'login'
  | 'notification'
  | 'paid-membership'
  | 'paid-plan-change'
  | 'payment'
  | 'cancel-paid-plan'
  | 'cancel-account'
  | 'mypage'
  | 'mypage-contract'
  | 'mypage-contract-log'
  | 'plan-select'

/**
 * ログインペインのコンポーネントです。
 */
export default defineComponent({
  name: 'LoginPane',
  components: {
    AtomInputButton,
    LoginHeaderSection,
    LoginSection,
    AlertOverlaySection,
  },
  inject: ['router'],
  data(): DataType {
    return {
      /**
       * ローディングアイコンを表示するかどうか
       */
      showLoading: false,
      /**
       * ログインエラーがあるかどうか
       */
      hasLoginError: false,
      /**
       * ログイン時にネットワークエラーが発生したどうか
       */
      hasNetworkError: false,
    }
  },
  props: {
    /**
     * ログインタイプ指定
     */
    loginType: {
      type: String as PropType<LoginType>,
      default: 'login',
    },
  },
  setup() {
    const router = inject('router') as VueRouter

    const { login: _login, addLoginSuccessListener } = useLogin()
    const { createContract } = useContract()
    const { initPushNotification } = usePushNotification()

    addLoginSuccessListener(async () => {
      // ログイン成功後、Push通知処理を初期化し、Push通知を受け取れるようにする
      await initPushNotification(router)
    })

    /**
     * フォアグランドに戻ってきた時の処理
     * iOSの場合、バックグラウンド状態でPusher通知が動かないことがあるため、フォアグランドに戻ってきた時に処理を行う
     */
    const returningToForegroundEvent = () => {
      if (!LoginStore.value.isLogin) {
        return
      }
      NotificationStore.value.fetchNotificationData()
      Logger.debug('LoginPane#returningToForegroundEvent: execute.')
    }

    onMounted(async () => {
      /**
       * アプリがフォアグラウンドに戻った時/スリープ解除した時のイベントリスナー
       * ログイン画面で一度イベントリスナーを解除した後、登録する
       */
      document.removeEventListener('resume', returningToForegroundEvent)
      document.addEventListener('resume', returningToForegroundEvent)

      // TODO：調査のためここにGoogleのトークン取得とアカウント情報取得処理を記載した。新しいユーザー登録画面ができたらそちらに移動する。v3.5.1をリリースするのでコメントアウトしている。
      // Logger.debug(`LoginPane#onMounted: query: ${JSON.stringify(router.currentRoute.query)}`)
      //
      // const _state = router.currentRoute.query.state
      // const _code = router.currentRoute.query.code
      //
      // if (_state && _code) {
      //   // OAuth 2.0の認可後に、リダイレクトされた際の処理
      //   Logger.debug(`LoginPane#onMounted: oAuth2.0 state: ${_state} code: ${_code}`)
      //
      //   const oauthManager = OAuthCodeFlowManager.getOAuthCodeFlowManager()
      //
      //   if (!oauthManager) {
      //     Logger.error('LoginPane#onMounted: OAuthCodeFlowManager not found.')
      //     return
      //   }
      //
      //   if (oauthManager.state !== _state) {
      //     Logger.error('LoginPane#onMounted: state different value.')
      //     return
      //   }
      //
      //   const token = await oauthManager.requestToken(_code as string)
      //
      //   const hTTPClient = new HTTPClient()
      //
      //   const response = await hTTPClient.sendRequest(
      //     'https://www.googleapis.com/oauth2/v3/userinfo',
      //     'get',
      //     {
      //       Authorization: `Bearer ${token?.access_token}`,
      //       Accept: 'application/json',
      //     },
      //   )
      //
      //   console.log(response)
      // }
    })

    /** オーバーレイを表示するかどうか */
    const isShowAlertOverlay = ref(false)

    /** ログイン後、プラン選択画面に遷移するURL */
    const planSelectUrl = computed(
      () => `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/plan-select`,
    )

    /**
     * オーバーレイを閉じる
     * プランなし状態の場合はログインできないようにしたいため、ブラウザ版プラン登録ページに遷移した場合もログアウトさせる
     */
    const hideAlertOverlay = async () => {
      isShowAlertOverlay.value = false

      /**
       * オーバーレイを閉じた場合はログアウト状態にする
       * すぐにログアウトすると別タブでプラン登録画面が開ないことがあるので、1秒待ってからログアウトさせる
       */
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })
      await router.replace({ path: '/logout' })
    }

    return {
      _login,
      createContract,
      isShowAlertOverlay,
      planSelectUrl,
      hideAlertOverlay,
    }
  },
  methods: {
    /**
     * ログインする。
     * @param loginName ログインID
     * @param password パスワード
     */
    async login({ loginName, password }: { loginName: string; password: string }) {
      let loader = this.$loading.show()
      try {
        this.hasLoginError = false
        this.hasNetworkError = false
        const result = await this._login(loginName, password)

        if (result?.apiResponse?.isNetworkError) {
          this.hasNetworkError = true
        } else if (result?.isSuccess) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [contractInfoResults, _billingInfoResult, _roleResult] = await Promise.all([
            ContractInfoStore.value.fetchUserContractInfo(),
            UserStore.value.fetchFixedBillingInfo(),
            ContractInfoStore.value.fetchRoles(),
          ])

          const failedResult = contractInfoResults.find(
            (contractInfoResult) => !contractInfoResult?.isSuccess,
          )
          if (failedResult) {
            loader.hide()
            // ユーザーに紐づくプラン情報を取得できなかった場合は、エラーコードに応じたエラーメッセージを表示する
            await MessageDialogStore.value.open?.({
              errorApiResponse: failedResult.response,
              isVisibleCancelButton: false,
            })
            await this.$router.replace({ path: '/logout' })
            return
          }

          if (!LoginStore.value.isTimeDiffWithinRange) {
            // DynamoDBのリクエストに失敗するほどの時刻のずれがある場合はエラーメッセージを表示する
            loader.hide()
            await MessageDialogStore.value.open({
              title: this.$tc('LoginPage.errors.timeDiffError.title'),
              message: this.$tc('LoginPage.errors.timeDiffError.message'),
            })
            loader = this.$loading.show()
          }

          LocalCache.init()
          NativeVideoPlayer.changeDeepestLayerStyle()
          await MypagePageStore.value.fetchMypagePageData(LoginStore.value.loginId)
          // グローバルストアにユーザー情報を保持するため、ユーザー情報をfetch
          await UserStore.value.fetchUserData(LoginStore.value.loginId)

          /** プランを登録していないユーザーの制御 */
          await this.controlUserNotRegisteredPlan(loader)

          // グローバルストアに新着のお知らせ情報を保持するため、お知らせ情報をfetch
          if (!LocalStorageAccessor.lastNotificationFetchedDate) {
            // ローカルストレージ > lastNotificationFetchedDateが存在しない場合、ログイン日時をセットする
            LocalStorageAccessor.lastNotificationFetchedDate = String(dayjs().valueOf())
          }
          NotificationStore.value.fetchNotificationData()

          if (this.isShowAlertOverlay) {
            // オーバーレイが表示されている場合は後続処理を行わない
            return
          }

          if (ContractInfoStore.value.isReadOnlyPlan && this.loginType !== 'payment') {
            /**
             * リードオンリープラン状態であれば強制的にマイページに遷移させる
             * ただし、クレジットカード情報変更画面への遷移は許可する
             */
            this.moveToMypageTop()
            return
          }

          if (
            UserStore.value.ownBillingInfo.value?.isAccountPending &&
            this.loginType !== 'payment'
          ) {
            /**
             * 決済未完了状態であれば強制的にマイページに遷移させる
             * ただし、クレジットカード情報変更画面への遷移は許可する
             */
            this.moveToMypageTop()
            return
          }

          /**
           * ログインタイプ毎に振り分け仮処理
           */
          if (this.loginType === 'login' || this.loginType === 'notification') {
            // スマホブラウザの場合、マイページに遷移
            if (
              (DeviceInfo.isMobile() && !DeviceInfo.isCordova() && DeviceInfo.isAndroidBrowser()) ||
              (DeviceInfo.isMobile() && !DeviceInfo.isCordova() && DeviceInfo.isiOSBrowser())
            ) {
              this.moveToMypageTop()
              return
            }
            // お知らせのPush通知から遷移した場合、お知らせ画面に遷移
            if (this.loginType === 'notification') {
              this.$router.replace({ path: '/notification' })
              return
            }
            // トップページに遷移
            this.moveToTop()
          } else if (this.loginType === 'payment') {
            this.$router.replace({ path: '/payment/edit' })
          } else if (this.loginType === 'paid-membership') {
            this.$router.replace({ path: '/paid-membership/edit' })
          } else if (this.loginType === 'paid-plan-change') {
            this.$router.replace({ path: '/paid-plan-change/edit' })
          } else if (this.loginType === 'cancel-paid-plan') {
            this.$router.replace({ path: '/cancel-paid-plan/edit' })
          } else if (this.loginType === 'cancel-account') {
            this.$router.replace({ path: '/cancel/confirm' })
          } else if (this.loginType === 'mypage') {
            this.$router.replace({ path: '/mypage' })
          } else if (this.loginType === 'mypage-contract') {
            this.$router.replace({ path: '/mypage/contract' })
          } else if (this.loginType === 'mypage-contract-log') {
            this.$router.replace({ path: '/mypage/contract-log' })
          }

          if (UserStore.value.isInitLogin()) {
            // 初回ログインの場合は、会員情報入力ページに遷移
            this.moveToMypageUserInit()
            return
          }
        } else {
          this.hasLoginError = true
        }
      } catch (e) {
        MessageDialogStore.value.open?.({
          title: this.$tc('apiNotification.dialog.500.title') as string,
          message: this.$tc('apiNotification.dialog.500.message') as string,
          isVisibleCancelButton: false,
        })
      } finally {
        loader.hide()
      }
    },

    /**
     * googleの認可を実施する
     */
    async googleAuth() {
      OAuthCodeFlowManager.createOAuthCodeFlowManager('google')
      const oAuthCodeFlowManager = OAuthCodeFlowManager.getOAuthCodeFlowManager()
      oAuthCodeFlowManager?.startOauthCodeFlow(['profile', 'email'])
    },
    /**
     * プランを登録していないユーザーの制御
     * 2023/4/20 00:00:00（JST）以降に会員登録をしたユーザーで有効なプランが存在しない場合、無料プランを付与する処理を行う
     */
    async controlUserNotRegisteredPlan(loader: LoaderComponent) {
      // registrationTypeInProgress = memberTypeとなる
      const registrationTypeInProgress =
        MypagePageStore.value.user.value.additionalData?.registrationTypeInProgress || ''

      const isCreatedAfter20230420 =
        ContractInfoStore.value.ownOrganization.value?._createdDate &&
        ContractInfoStore.value.ownOrganization.value._createdDate >= 1681916400000

      if (isCreatedAfter20230420 && !ContractInfoStore.value.effectiveOrgContract) {
        // 2023/4/20 00:00:00（JST）以降に会員登録をしたユーザーで有効なプランが存在しない場合、無料プランを付与する
        const createContractResult = await this.createContract('freePlan')

        if (!createContractResult.isSuccess) {
          loader.hide()
          await MessageDialogStore.value.open({
            // 契約情報契約プランを登録できなかった場合は、ユーザーに対して「プラン取得エラー」という表示をする
            title: this.$tc('LoginPage.errors.createContractError.title'),
            message: this.$tc('LoginPage.errors.createContractError.message'),
          })

          // プランの作成に失敗した場合はログアウトする
          await this.$router.replace({ path: '/logout' })
          return
        }

        loader.hide()
        await MessageDialogStore.value.open({
          // プランが存在しなかった場合、無料プランを登録したことをユーザーに伝え、最新の契約情報を取得するために一旦ログアウトする
          title: this.$tc('LoginPage.registerFreePlan.title'),
          message: this.$tc('LoginPage.registerFreePlan.message'),
        })
        await this.$router.replace({ path: '/logout' })
        return
      }

      if (ContractInfoStore.value.effectiveOrgContract && registrationTypeInProgress) {
        /** プランが存在しているにも関わらずregistrationTypeInProgressが残っている場合は、登録手続き完了状態にする */
        await MypagePageStore.value.saveRegistrationTypeInProgress(
          registrationTypeInProgress,
          'end',
        )
      }
    },
    /**
     * TOPページに遷移する
     */
    moveToTop(): void {
      Logger.debug(`LoginPage: this.$route.query.redirect: ${this.$route.query?.redirect}`)
      if (this.$route.query?.redirect && typeof this.$route.query.redirect === 'string') {
        const redirectURL = decodeURIComponent(this.$route.query.redirect)
        if (redirectURL) {
          this.$router.replace({ path: redirectURL })
          Logger.debug(`LoginPage: Go redirectURL page: ${redirectURL}`)
          return
        }
      }
      Logger.debug('LoginPage: Go TopPage')
      this.$router.replace({ name: 'HomePage' })
    },
    /**
     * 会員情報入力ページに遷移する
     */
    moveToMypageUserInit(): void {
      Logger.debug('LoginPage: Go MypageUserInitPage')
      this.$router.replace({ name: 'MypageUserInitPage' })
    },
    /**
     * マイページトップに遷移する
     */
    moveToMypageTop(): void {
      Logger.debug('LoginPage: Go MypageTopPage')
      this.$router.replace({ name: 'MypageTopPage' })
    },
    /**
     * エラー表示を消す
     */
    removeError() {
      this.hasLoginError = false
    },
  },
})
