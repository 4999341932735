







import { defineComponent } from '@vue/composition-api'
import AppConfirmationSentPane from '@/components/AppSignupPage/AppConfirmationSentPane.vue'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'

export default defineComponent({
  name: 'AppConfirmationSentPage',
  components: { HeaderPane, AppConfirmationSentPane },
})
