import BaseCouponDocument from '@/store/stores/collectionModule/documents/coupon/BaseCouponDocument'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { PlanAfterCouponEndsType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'

/**
 * ワンタイムパスの種類（位置情報を確認するタイミング）の型
 */
export type OneTimePassTypeValueType = 'purchasingPlan' | 'watchingVideo'

/**
 * アフィリエイトクーポンの対象の型
 */
export type AffiliateCouponTargetType = 'team' | 'user'
/**
 * クーポン情報を表現するクラス
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3367075853/API?NO_SSR=1
 */
export default class CouponDocument extends BaseCouponDocument {
  constructor(initProps?: Partial<CouponDocument>) {
    super(initProps as Partial<BaseCouponDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/coupon'

  idAttr = 'couponId'

  /**
   *
   */
  couponId: string | null = null

  /**
   * クーポンコード
   */
  couponCode: string | null = null

  /**
   * クーポン名
   */
  couponName: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 説明
   */
  description?: Multilingual = {
    ja: '',
    en: '',
  }

  /**
   * 有効開始日時
   */
  effectiveStartDate: number | null = null

  /**
   * 有効終了日時
   */
  effectiveEndDate?: number | null = null

  /**
   * 利用可能回数
   */
  numberOfAvailableUses?: number | null = null

  /**
   * ベースとなるクーポンID
   */
  baseCouponId?: string | null = null

  /**
   * クーポン期限切れ3日前に通知するかどうか
   */
  needToNotify: boolean | null = null

  /**
   * クーポン配布ユーザーID
   */
  distributorUserId?: string | null = null

  /**
   * クーポン情報の追加項目
   */
  additionalData?: {
    /**
     * 見出し
     */
    title: Multilingual
    /**
     * クーポン終了後のプラン
     */
    couponPlanType: PlanAfterCouponEndsType
    /**
     * クーポンを利用できる場所
     */
    availableArea?: {
      /**
       * クーポンを利用できる場所
       */
      areaId: string | null
      /**
       * 位置情報を確認するタイミング
       */
      checkLocationTiming?: OneTimePassTypeValueType | null
    } | null
    /**
     * クーポン期限切れ3日前に通知するかどうか
     */
    needToNotify?: boolean | null
    /**
     * アフィリエイトクーポンかどうか
     */
    affiliateCoupon?: boolean | null
    /**
     * アフィリエイトクーポンの対象
     */
    affiliateCouponTarget?: AffiliateCouponTargetType | null
  }

  /**
   * クーポンの適用期間が日数指定かどうか
   */
  get isSettingNumberOfDays() {
    const couponPlan = this.plans?.find(
      (plan) =>
        plan.planGroupId === 'limitedTimePaidPlan' ||
        plan.planGroupId === 'limitedTimePaidPlanForAnnual',
    )
    return !!couponPlan?.planDuration
  }

  /**
   * クーポン期限切れ3日前に通知するかどうか
   */
  get notifyBeforeExpires() {
    if (this.baseCouponId) {
      /**
       * ベースクーポンの情報から生成される一般ユーザー向け紹介コードは、ベースクーポンの内容を引き継ぐがneedToNotifyの値は引き継がれない。
       * そのため、additionalData.needToNotifyを使ってメール通知するクーポンかどうかを判定する。
       */
      return this.additionalData?.needToNotify
    }
    return this.needToNotify
  }

  /**
   * 利用できる場所
   */
  get availableArea() {
    return this.additionalData?.availableArea ?? null
  }

  /**
   * 紹介コードかどうか
   */
  get isReferralCode() {
    return (
      (this.additionalData?.affiliateCoupon &&
        this.additionalData?.affiliateCouponTarget === 'user') ??
      false
    )
  }
}
