import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoCreditCardDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoCreditCardDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * 契約情報にクレジットカード情報を登録・更新するための処理を提供する。
 */
export default function useContractInfoCreditCard() {
  // Collection modules
  const contractInfoCreditCardCollectionModule = CollectionModule.createStore(
    ContractInfoCreditCardDocument,
  )

  /**
   * クレジットカード情報を登録・更新する。
   * この処理を行うことで、契約情報にクレジットカード支払いの情報が反映される。
   *  - 例: payment.methodがCREDIT_CARDに変わる など
   */
  const updateCreditCard = async (requestData: ContractInfoCreditCardDocument) => {
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/manage/contract_info/${requestData.id}/credit_card`,
    }
    return contractInfoCreditCardCollectionModule.save(requestData, options)
  }

  return {
    updateCreditCard,
  }
}
