var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-confirmation-sent-page" },
    [
      _c("header-pane", {
        attrs: { pageTitle: "SIGN UP", colorType: "light" },
      }),
      _c("app-confirmation-sent-pane"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }