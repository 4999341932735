import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import {
  Multilingual,
  MissionOperationType,
} from '@/store/stores/collectionModule/documents/GeneralTypes'
import I18n from '@/locales/I18n'

type CoordinateType = {
  /**
   * 緯度情報
   */
  lat: number
  /**
   * 経度情報
   */
  lng: number
}

/**
 * 矩形外エリアを対象とする場合に利用する緯度経度情報の型
 */
type OuterCoordinatesType = {
  coordinates: Array<CoordinateType>
}

type MissionType = {
  /**
   * ミッション達成にする対象の操作
   */
  operation?: MissionOperationType
  /**
   * イベントチェックイン（サーキット周辺チェックイン）かどうか
   */
  around?: boolean
}

/**
 * 利用できる場所情報を表現するクラス。（現状はクーポンを利用できる場所情報を扱っている。）
 * スタジアムIDマスタにデータを格納している。
 */
export default class AvailableAreaDocument extends DocumentWrapper {
  constructor(initProps?: Partial<AvailableAreaDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/master/stadium_id'

  idAttr = 'stadiumId'

  /**
   * 利用可能場所ID
   */
  stadiumId: string | null = null

  /**
   * 利用可能場所名
   */
  stadiumName: string | null = null

  /**
   * 追加情報
   */
  additionalData?: {
    /**
     * ミッションに関する情報
     */
    mission?: MissionType
    /**
     * GoogleマップのURL
     */
    googleMapUrl?: string
    /**
     * 地域
     */
    area?: Multilingual
    /**
     * エリア名
     */
    areaName?: Multilingual
    /**
     *グループ名
     */
    groupName?: Multilingual
    /**
     *店舗名
     */
    storeName?: Multilingual
    /**
     * 住所
     */
    address?: Multilingual
    /**
     * 大会名
     */
    championshipName?: Multilingual
    /**
     * 緯度経度情報
     */
    coordinates?: Array<CoordinateType>
    /**
     * 緯度経度情報（矩形外エリアを対象とする場合に使用）
     */
    outerCoordinates?: Array<OuterCoordinatesType>
  }

  /**
   * ミッションに関する情報
   */
  get mission() {
    return this.additionalData?.mission || null
  }

  /**
   * 地域
   */
  get area() {
    return this.additionalData?.area || null
  }

  /**
   * グループ名
   */
  get groupName() {
    return this.additionalData?.groupName || null
  }

  /**
   * 店舗名
   */
  get storeName() {
    return this.additionalData?.storeName || null
  }

  /**
   * エリア名
   */
  get areaName() {
    return this.additionalData?.areaName || null
  }

  /**
   * 住所
   */
  get address() {
    return this.additionalData?.address || null
  }

  /**
   * 大会名
   */
  get championshipName() {
    return this.additionalData?.championshipName || null
  }

  /**
   * GoogleマップのURL
   */
  get googleMapUrl() {
    return this.additionalData?.googleMapUrl || ''
  }

  /**
   * ディーラー店舗名またはエリア名
   * ディーラー店舗名（グループ名 + 店舗名）は日本語のみ対応
   */
  get storeOrAreaName() {
    return this.groupName?.ja && this.storeName?.ja
      ? `${this.groupName?.ja} ${this.storeName?.ja}`
      : this.areaName?.[I18n.locale]
  }
}
