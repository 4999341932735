import Vue from 'vue'
import VueRouter, { Location, RouteConfig } from 'vue-router'
import LoginPage from '@/views/LoginPage.vue'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import MainPage from '@/views/MainPage/MainPage.vue'
import HomePage from '@/views/HomePage/HomePage.vue'
import HomeSnsPlayerSelectPage from '@/views/HomePage/HomeSnsPlayerSelectPage.vue'
import HomeSnsTeamSelectPage from '@/views/HomePage/HomeSnsTeamSelectPage.vue'
import RaceListPage from '@/views/RaceListPage/RaceListPage.vue'
import RaceVideoPage from '@/views/RaceVideoPage/RaceVideoPage.vue'
import LinkPage from '@/views/LinkPage/LinkPage.vue'
import AwardPage from '@/views/LinkPage/AwardPage.vue'
import MyPageWrapper from '@/views/MypagePage/MyPageWrapper.vue'
import MypageTopPage from '@/views/MypagePage/MypageTopPage.vue'
import MypageTicketPageWrapper from '@/views/MypagePage/MyPageTicketPage/MypageTicketPageWrapper.vue'
import MypageTicketListPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketListPage.vue'
import MypageTicketTodayPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketTodayPage.vue'
import MypageTicketPastPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketPastPage.vue'
import MypageUserEditPage from '@/views/MypagePage/MypageUserEditPage.vue'
import MypageUserConfirmPage from '@/views/MypagePage/MypageUserConfirmPage.vue'
import MypageUserCompletePage from '@/views/MypagePage/MypageUserCompletePage.vue'
import MypageFavoriteTeamSelectPage from '@/views/MypagePage/MypageFavoriteTeamSelectPage.vue'
import MypageFavoritePlayerSelectPage from '@/views/MypagePage/MypageFavoritePlayerSelectPage.vue'
import MypageLanguageSettingPage from '@/views/MypagePage/MypageLanguageSettingPage.vue'
import MypageEcSiteConnectPage from '@/views/MypagePage/MypageEcSiteConnectPage.vue'
import MypageDetailsSettingPage from '@/views/MypagePage/MypageDetailsSettingPage.vue'
import MypageAboutUsingPage from '@/views/MypagePage/MypageAboutUsingPage.vue'
import MypageContactUsPage from '@/views/MypagePage/MypageContactUsPage.vue'
import MypageUserInitPage from '@/views/MypagePage/MypageUserInitPage.vue'
import MypageFavoritePlayerInitSelectPage from '@/views/MypagePage/MypageFavoritePlayerInitSelectPage.vue'
import MypageFavoriteTeamInitSelectPage from '@/views/MypagePage/MypageFavoriteTeamInitSelectPage.vue'
import MypageUserInitConfirmPage from '@/views/MypagePage/MypageUserInitConfirmPage.vue'
import MypageOneTimePassInputPage from '@/views/MypagePage/MypageOneTimePassInputPage.vue'
import MypageOneTimePassConfirmPage from '@/views/MypagePage/MypageOneTimePassConfirmPage.vue'
import MypageOneTimePassCompletePage from '@/views/MypagePage/MypageOneTimePassCompletePage.vue'
import MypageOneTimePassWithNoPlanCompletePage from '@/views/MypagePage/MypageOneTimePassWithNoPlanCompletePage.vue'
import MypageReferralCodePage from '@/views/MypagePage/MypageReferralCodePage.vue'
import MypageSwitchToPaidPlanPage from '@/views/MypagePage/MypageSwitchToPaidPlanPage.vue'
import MypagePaidPlanChangeEditPage from '@/views/MypagePage/MypagePaidPlanChangeEditPage.vue'
import MypagePaymentEditPage from '@/views/MypagePage/MypagePaymentEditPage.vue'
import MypageCancelPaidPlanEditPage from '@/views/MypagePage/MypageCancelPaidPlanEditPage.vue'
import StandingsPageWrapper from '@/views/StandingsPage/StandingsPageWrapper.vue'
import StandingsTopPage from '@/views/StandingsPage/StandingsTopPage.vue'
import StandingsDetailsPage from '@/views/StandingsPage/StandingsDetailsPage.vue'
import NotificationPage from '@/views/NotificationPage/NotificationPage.vue'
import MissionTopPage from '@/views/MissionPage/MissionTopPage.vue'
import MissionPage from '@/views/MissionPage/MissionPage.vue'
import MissionRankingPage from '@/views/MissionPage/MissionRankingPage.vue'
import MotorsportCalendarPage from '@/views/LinkPage/MotorsportCalendarPage.vue'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import StoreUtil from '@/store/StoreUtil'
import MypageContractPage from '@/views/MypagePage/MypageContractPage.vue'
import MypageContractLogPage from '@/views/MypagePage/MypageContractLogPage.vue'
import RewardPage from '@/views/RewardPage/RewardPage.vue'
import MypageBlockedUserListPage from '@/views/MypagePage/MypageBlockedUserListPage.vue'
import MypagePointExpirationDatePage from '@/views/MypagePage/MypagePointExpirationDatePage.vue'
import MypagePointHistoryPage from '@/views/MypagePage/MypagePointHistoryPage.vue'
import AppSignupTopPage from '@/views/AppSignupPage/AppSignupTopPage.vue'
import AppConfirmationSentPage from '@/views/AppSignupPage/AppConfirmationSentPage.vue'
import AppSignupInputPage from '@/views/AppSignupPage/AppSignupInputPage.vue'

Vue.use(VueRouter)

export const mobileRoutes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    children: [
      {
        path: '/login/:loginType',
        name: 'EditLoginPage',
        component: LoginPage,
        /**
         * 【対象のloginType】
         * notification(お知らせ)
         */
        beforeEnter: (to, from, next) => {
          const targetLoginTypes = ['notification']
          if (!targetLoginTypes.find((v) => v === to.params.loginType)) {
            // 対象のloginType以外の場合、ログイン画面に返す
            const route = { path: '/login' } as Location
            next(route)
            return
          }
          next()
        },
      },
    ],
  },
  {
    path: '/logout',
    beforeEnter: () => {
      // ログアウトする
      LocalStorageAccessor.transitionFromLogout = true
      // Storeの状態をクリアするためログアウト時にブラウザを強制リロードする
      window.location.reload()
    },
  },
  {
    path: '/signup/confirmation-sent',
    name: 'AppConfirmationSentPage',
    component: AppConfirmationSentPage,
  },
  {
    path: '/signup/input',
    name: 'AppSignupInputPage',
    component: AppSignupInputPage,
  },
  {
    path: '/signup',
    name: 'AppSignupTopPage',
    component: AppSignupTopPage,
  },
  {
    path: '/',
    component: MainPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
      },
      {
        path: '/home/player-select',
        name: 'HomeSnsPlayerSelectPage',
        component: HomeSnsPlayerSelectPage,
      },
      {
        path: '/home/team-select',
        name: 'HomeSnsTeamSelectPage',
        component: HomeSnsTeamSelectPage,
      },
      {
        path: '/',
        name: 'TopPage',
        component: RaceListPage,
        props: (route) => ({
          isDataFetched: route.params.isDataFetched === 'true',
        }),
      },
      {
        path: '/race-video/:championshipMasterId/:raceId/:highlightId?',
        name: 'RaceVideoPage',
        component: RaceVideoPage,
        props: (route) => ({
          // 大会ID
          championshipMasterId: route.params.championshipMasterId,
          // レースID
          raceId: route.params.raceId,
          // 最初に再生するハイライト情報(オプション)
          highlightId: route.params.highlightId,
          isDataFetched: route.params.isDataFetched === 'true',
        }),
      },
      {
        path: '/race-video/:championshipMasterId/:raceId/:movieStartActualTime/:driverId',
        name: 'RaceVideoPage',
        component: RaceVideoPage,
        props: (route) => ({
          // 大会ID
          championshipMasterId: route.params.championshipMasterId,
          // レースID
          raceId: route.params.raceId,
          // 最初に再生する動画情報の実時刻
          movieStartActualTime: Number(route.params.movieStartActualTime),
          driverId: route.params.driverId,
        }),
      },
      {
        path: '/link',
        name: 'LinkPage',
        component: LinkPage,
      },
      {
        path: '/notification',
        name: 'NotificationPage',
        component: NotificationPage,
      },
    ],
  },
  {
    path: '/link/award',
    name: 'AwardPage',
    component: AwardPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/link/calendar',
    name: 'MotorsportCalendar',
    component: MotorsportCalendarPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mission',
    name: 'Mission',
    component: MissionTopPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'MissionPage',
        component: MissionPage,
      },
      {
        path: '/mission/ranking',
        name: 'MissionRankingPage',
        component: MissionRankingPage,
      },
    ],
  },
  {
    path: '/standings',
    meta: {
      requiresAuth: true,
    },
    component: StandingsPageWrapper,
    children: [
      {
        path: '',
        name: 'StandingsTopPage',
        component: StandingsTopPage,
      },
      {
        path: '/standings/details/:playerMasterId',
        name: 'StandingsDetailsPage',
        component: StandingsDetailsPage,
        props: (route) => ({
          playerMasterId: route.params.playerMasterId,
        }),
      },
    ],
  },
  {
    path: '/mypage',
    component: MyPageWrapper,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'MypageTopPage',
        component: MypageTopPage,
      },
      {
        path: '',
        component: MypageTicketPageWrapper,
        children: [
          {
            path: '/mypage/ticket-list',
            name: 'MypageTicketListPage',
            component: MypageTicketListPage,
          },
          {
            path: '/mypage/ticket-today',
            name: 'MypageTicketTodayPage',
            component: MypageTicketTodayPage,
          },
          {
            path: '/mypage/ticket-past',
            name: 'MypageTicketPastPage',
            component: MypageTicketPastPage,
          },
        ],
      },
      {
        path: '/mypage/point-history',
        name: 'MypagePointHistoryPage',
        component: MypagePointHistoryPage,
      },
      {
        path: '/mypage/point-expiration-date',
        name: 'MypagePointExpirationDatePage',
        component: MypagePointExpirationDatePage,
      },
      {
        path: '/mypage/user-edit',
        name: 'MypageUserEditPage',
        component: MypageUserEditPage,
      },
      {
        path: '/mypage/user-confirm',
        name: 'MypageUserConfirmPage',
        component: MypageUserConfirmPage,
      },
      {
        path: '/mypage/user-complete',
        name: 'MypageUserCompletePage',
        component: MypageUserCompletePage,
      },
      {
        path: '/mypage/favorite-team',
        name: 'MypageFavoriteTeamSelectPage',
        component: MypageFavoriteTeamSelectPage,
      },
      {
        path: '/mypage/favorite-player',
        name: 'MypageFavoritePlayerSelectPage',
        component: MypageFavoritePlayerSelectPage,
      },
      {
        path: '/mypage/language',
        name: 'MypageLanguageSettingPage',
        component: MypageLanguageSettingPage,
      },
      {
        path: '/mypage/ec-site-connect',
        name: 'MypageEcSiteConnect',
        component: MypageEcSiteConnectPage,
      },
      {
        path: '/mypage/details-setting',
        name: 'MypageDetailsSettingPage',
        component: MypageDetailsSettingPage,
      },
      {
        path: '/mypage/blocked-user-list',
        name: 'MypageBlockedUserListPage',
        component: MypageBlockedUserListPage,
      },
      {
        path: '/mypage/about-using',
        name: 'MypageAboutUsingPage',
        component: MypageAboutUsingPage,
      },
      {
        path: '/mypage/contact-us',
        name: 'MypageContactUsPage',
        component: MypageContactUsPage,
      },
      {
        path: '/mypage/one-time-pass-input',
        name: 'MypageOneTimePassInputPage',
        component: MypageOneTimePassInputPage,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isPaidPlan, isCouponPlan, isTrialPlan } = contractInfoStore
          if (isPaidPlan || isCouponPlan || isTrialPlan) {
            // 有料会員（クーポン適用中の有料会員も含む）の場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          // ワンタイムパスの登録ページに遷移する
          next()
        },
      },
      {
        path: '/mypage/one-time-pass-confirm',
        name: 'MypageOneTimePassConfirmPage',
        component: MypageOneTimePassConfirmPage,
      },
      {
        path: '/mypage/one-time-pass-complete',
        name: 'MypageOneTimePassCompletePage',
        component: MypageOneTimePassCompletePage,
      },
      {
        path: '/mypage/one-time-pass-with-no-plan-complete',
        name: 'MypageOneTimePassWithNoPlanCompletePage',
        component: MypageOneTimePassWithNoPlanCompletePage,
      },
      {
        path: '/mypage/referral-code',
        name: 'MypageReferralCodePage',
        component: MypageReferralCodePage,
      },
      {
        path: '/mypage/contract',
        name: 'MypageContractPage',
        component: MypageContractPage,
      },
      {
        path: '/mypage/contract-log',
        name: 'MypageContractLogPage',
        component: MypageContractLogPage,
      },
      {
        path: '/mypage/switch-to-paid-plan/',
        name: 'MypageSwitchToPaidPlanPage',
        component: MypageSwitchToPaidPlanPage,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isPaidPlan, isCouponPlan, isTrialPlan, hasNoPlan } = contractInfoStore
          if (!hasNoPlan && (isPaidPlan || isCouponPlan || isTrialPlan)) {
            // プランがあり かつ 有料会員（クーポン適用中の有料会員も含む）の場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          // 有料会員変更ページに遷移する
          next()
        },
      },
      {
        path: '/mypage/paid-plan-change/edit-guide',
        name: 'MypagePaidPlanChangeEditPage',
        component: MypagePaidPlanChangeEditPage,
      },
      {
        path: '/mypage/payment/edit-guide',
        name: 'MypagePaymentEditPage',
        component: MypagePaymentEditPage,
      },
      {
        path: '/mypage/cancel-paid-plan/edit-guide',
        name: 'MypageCancelPaidPlanEditPage',
        component: MypageCancelPaidPlanEditPage,
      },
    ],
  },
  {
    path: '/mypage',
    component: MyPageWrapper,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/mypage/init',
        name: 'MypageUserInitPage',
        component: MypageUserInitPage,
      },
      {
        path: '/mypage/init/favorite-player',
        name: 'MypageFavoritePlayerInitSelectPage',
        component: MypageFavoritePlayerInitSelectPage,
      },
      {
        path: '/mypage/init/favorite-team',
        name: 'MypageFavoriteTeamInitSelectPage',
        component: MypageFavoriteTeamInitSelectPage,
      },
      {
        path: '/mypage/init-confirm',
        name: 'MypageUserInitConfirmPage',
        component: MypageUserInitConfirmPage,
      },
    ],
  },
  {
    path: '/reward',
    name: 'RewardPage',
    component: RewardPage,
    meta: {
      requiresAuth: true,
    },
  },
]

/**
 * 指定されたルーティングで新規にルーターを作成する。
 *
 * @param routes ルーティング設定
 */
export const makeRouter = (routes: RouteConfig[]): VueRouter => {
  const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
  })

  router.beforeEach((to, from, next) => {
    if (to.meta?.requiresAuth || to.matched.some((page) => page.meta.requiresAuth)) {
      // requiresAuthがtrueの場合でログイン情報がStoreに存在しない場合はログインページにリダイレクトする
      // ※MOCK_MODEの場合はリダイレクトしない
      if (!LoginStore.value.isLogin) {
        const route = { path: '/login' } as Location
        next(route)
        return
      }

      const readonlyAccessiblePathList = ['/payment/edit', '/payment/confirm', '/payment/complete']
      /** 以下の条件全てを満たす場合、マイページに遷移させる
       * 1. ログイン画面以外からの遷移（LoginPaneにログイン後のリダイレクト先の記述がある影響でリダイレクトループが発生するため、ログイン画面からの遷移は除外）
       * 2. リードオンリープラン状態
       */
      if (!from.path.startsWith('/login') && ContractInfoStore.value.isReadOnlyPlan) {
        if (!readonlyAccessiblePathList.some((path) => path === to.path)) {
          const route = { path: '/mypage' } as Location
          next(route)
          return
        }
      }

      const initPathList = [
        '/mypage/init',
        '/mypage/init/favorite-player',
        '/mypage/init/favorite-team',
        '/mypage/init-confirm',
      ]
      /** 以下の条件全てを満たす場合、初回会員情報入力ページに遷移させる
       * 1. ログイン画面以外からの遷移（LoginPaneにログイン後のリダイレクト先の記述がある影響でリダイレクトループが発生するため、ログイン画面からの遷移は除外）
       * 2. ログイン済み
       * 3. 初回ログイン
       * 4. 初回ログイン時にアクセスできるページ（initPathList、readonlyAccessiblePathList）への遷移以外
       */
      if (
        !from.path.startsWith('/login') &&
        LoginStore.value.isLogin &&
        UserStore.value.isInitLogin()
      ) {
        if (
          !initPathList.some((path) => path === to.path) &&
          !readonlyAccessiblePathList.some((path) => path === to.path)
        ) {
          const route = { path: '/mypage/init' } as Location
          next(route)
          return
        }
      }
    }
    next()
  })
  return router
}

const router = makeRouter(mobileRoutes)
export default router
