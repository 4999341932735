import { computed, reactive, ref, Ref, watch } from '@vue/composition-api'
import { orderBy } from 'lodash'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useChampionships from '@/store/hook/useChampionships'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import useRaces from '@/store/hook/useRaces'
import useHighlightData from '@/store/hook/useHighlightData'
import useVideoPlatformContents from '@/store/hook/useVideoPlatformContents'
import useRaceRank from '@/components/RaceListPage/hook/useRankData'
import useRacePointVideo from '@/components/RaceListPage/hook/useRacePointVideo'
import useRadioData from '@/store/hook/useRadioData'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import VideoPlatformContentsDocument from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import useAddInfoToRadioData from '@/components/hook/useAddInfoToRadioData'
import useAvailableYears from '@/store/hook/useAvailableYears'

/**
 * Race Point Radio表示用データの型
 */
export type RacePointRadioDataType = {
  catch: Multilingual
  text: Multilingual
  to: string
  raceRadioData: RadioDataDocument | null
  videoId: string
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのレース選択画面のStore
 */
class TopPageStore implements StoreBase {
  createStore() {
    const topPageState = reactive({
      /**
       * レース選択画面ページのデータを表示する年度
       */
      raceYear: 0,
      /**
       * レース選択画面ページで表示する年度一覧
       */
      yearList: [] as Array<number>,
      /**
       * 選択中のレース大会のId
       */
      currentChampionshipId: LocalStorageAccessor.currentChampionshipId,
      /**
       * 全てのセッションを表示するかどうかのフラグ、trueなら表示
       */
      showTimetable: false,
    })

    // hook
    const { fetchChampionships, championships, targetChampionship, clearChampionships } =
      useChampionships()
    const {
      fetchRaces,
      fetchMovieInfo,
      targetRace,
      freePracticeList,
      qualifyingRaceList,
      sessionsWithOutRace,
      mainRace,
      contentsInfo,
      contentsInfos,
      angleMovieInfos,
      qualifyingRaceStartEventList,
      fetchParticipatingPlayers,
      participatingPlayers,
      clearRaces,
    } = useRaces()

    const { highlightsForPreLoad } = useHighlightData()

    const { fetchRaceRankData, getRankingCardData, clearRankData } = useRaceRank()
    const { fetchRacePointVideoData, racePointVideoData } = useRacePointVideo()
    const radioDataStore = useRadioData(targetRace)
    const { fetchVideoPlatformContents, videoPlatformContentsList } = useVideoPlatformContents()
    const { addPlayerDataToRadioData } = useAddInfoToRadioData()

    /**
     * Race Point Radio表示用データ
     */
    const racePointRadioData: Ref<RacePointRadioDataType[]> = ref([])

    /**
     * 車両番号のエイリアス
     * ※暫定的に定義
     */
    const carNoAlias = {
      16: 1,
      1: 16,
    }

    /**
     * 選択中のレース大会
     * return { ChannelDocument } 選択中のチャンネル情報
     */
    const currentChampionship = computed({
      get: (): ChampionshipDocument =>
        <ChampionshipDocument>(
          championships.value.find((item) => item.id === topPageState.currentChampionshipId)
        ),
      set: (championship: ChampionshipDocument) => {
        topPageState.currentChampionshipId = championship.id || ''
      },
    })

    const sortedChampionships = computed(() =>
      championships.value.sort((a, b) => {
        if (a.startDate === b.startDate) {
          return a.round === 0 ? 1 : -1
        }
        return (a.startDate ?? 0) < (b.startDate ?? 0) ? 1 : -1
      }),
    )

    // methods
    /**
     * 車両番号のエイリアスを取得する。
     * 暫定処理: 野尻選手の場合、車両番号16に対して、1のエイリアスを返す。
     * @param carNo 車両番号
     * @param startDate 開始日時（紐づいている大会マスタの開始日と同じ値をスコアリングで登録している）
     */
    const getCarNoAlias = (carNo: number, startDate: number | null) => {
      if (startDate && startDate >= new Date('2024-01-01T00:00:00+0900').getTime()) {
        // 2024年以降のマスタについては暫定処理を行わない
        return ''
      }
      if (Object.keys(carNoAlias)[carNo]) {
        return carNoAlias[carNo as keyof typeof carNoAlias]
      }
      return ''
    }

    /**
     * 再生位置へのリンクを生成する際の実際のデータの開始時刻の何秒前から再生するかを指定する
     */
    const startTimeBeforeMargin = 3

    const createCardData = (
      racePointRadio: VideoPlatformContentsDocument,
      player: PlayerDocument,
      radio: RadioDataDocument,
      race: RaceDocument,
    ) => {
      // 無線の開始時刻
      const radioStartTime = radio.clip_start_time
        ? radio.clip_start_time - startTimeBeforeMargin * 1000
        : 0

      // 無線データを作成
      const radioDataValue = radio
      radioDataValue.playerName = player.getPlayerName()
      radioDataValue.playerPictureImagePath = CloudFrontUtil.getSignedUrl(
        player.playerPictureImagePath,
      )
      // 省略表記は英語のみ利用する
      radioDataValue.abbreviation = {
        ja: player.playerShortName.en,
        en: player.playerShortName.en,
      }

      return {
        catch: {
          ja: racePointRadio.title.ja,
          en: racePointRadio.title.en,
        },
        text: {
          ja: racePointRadio.additionalData?.description?.ja ?? '',
          en: racePointRadio.additionalData?.description?.en ?? '',
        },
        to: `/race-video/${race.championshipMasterId}/${race.id}/${radioStartTime}/${player.playerId}`,
        raceRadioData: radioDataValue,
        videoId: racePointRadio.videoId ?? '',
      }
    }

    /**
     * Race Point Radioのコンテンツに登録された無線データに一致する選手情報を返す
     * @param targetRadio 無線情報
     * @returns PlayerDocument | undefined
     */
    const getTargetPlayer = (targetRadio: RadioDataDocument | undefined) => {
      if (!targetRadio) return undefined
      return participatingPlayers.value.find(
        (player) =>
          targetRadio.car_no === Number(player.squadNumber) ||
          getCarNoAlias(targetRadio.car_no, player.startDate ?? null) ===
            Number(player?.squadNumber),
      )
    }

    // API requests
    /**
     * レース選択画面で必要なデータを全て取得する。
     * @param raceYear データを取得する年度
     * @return データ取得を待機するためのPromise
     */
    const fetchTopPageData = async (raceYear?: number) => {
      const { getAvailableYears } = useAvailableYears()
      topPageState.yearList = await getAvailableYears()

      if (!topPageState.raceYear && !raceYear) {
        topPageState.raceYear = topPageState.yearList[topPageState.yearList.length - 1]
      } else if (raceYear) {
        topPageState.raceYear = raceYear
      }

      // Race Point Videoデータを取得
      fetchVideoPlatformContents()

      const result = await fetchChampionships(topPageState.raceYear)

      if (!result.isSuccess) {
        return [result]
      }
      if (!targetChampionship.value) {
        return [result]
      }
      if (!currentChampionship.value) {
        // 過去に大会を選択したことがない場合、取得した大会一覧の最初の大会を選択状態にする
        currentChampionship.value = targetChampionship.value
      }
      if (currentChampionship.value.id) {
        return fetchRaces(currentChampionship.value.id)
      }
      return [result]
    }

    /**
     * 指定した大会のRace Point Radioデータを取得する。
     * NOTE: 現状アプリでは予選の無線を表示させていないが、ユーザー間で無線機能が好評のため今後予選にも無線データを配信する可能性があるとのこと
     * 予選のレースに無線が追加された場合は、@param qualifyingRaces を追加して処理を追加する
     */
    const fetchRacePointRadioData = async () => {
      const results: RacePointRadioDataType[] = []
      const radioData: RadioDataDocument[] = []
      // Race Point Radioコンテンツ情報
      const racePointRadios = orderBy(
        videoPlatformContentsList.value,
        '_createdDate',
        'desc',
      ).filter((v) => v.videoPlatform === 'SFgo' && v.videoType === 'radio')
      // 決勝
      if (mainRace.value[0] && mainRace.value[0].id && mainRace.value[0].championshipMasterId) {
        // Race Point Radioのコンテンツデータを表示可能な形式にする
        if (racePointRadios.length > 0) {
          // レースに参加しているドライバー情報を取得
          await fetchParticipatingPlayers(
            mainRace.value[0].championshipMasterId,
            mainRace.value[0].id,
          )
          // eslint-disable-next-line
          for await (const racePointRadio of racePointRadios) {
            const lastModifiedDate = racePointRadio.additionalData?.lastModifiedDate
            if (lastModifiedDate && racePointRadio.videoId) {
              // 無線の重複をチェック
              const isDuplicated = results.some((r) => r.videoId === racePointRadio.videoId)
              if (!isDuplicated) {
                // 無線データを取得
                const resultRadioData = (
                  await radioDataStore.fetchRadios(
                    mainRace.value[0],
                    lastModifiedDate,
                    lastModifiedDate,
                  )
                ).data
                // Race Point RadioのadditionalData.carNoを使ってRace Point Radio表示対象の無線を特定
                let targetRacePointRadio = resultRadioData.find(
                  (v) => v.car_no === racePointRadio.additionalData?.carNo,
                )
                if (targetRacePointRadio) {
                  // 無線データにマッチするドライバー情報を取得
                  const targetPlayer = getTargetPlayer(targetRacePointRadio)
                  if (targetPlayer)
                    targetRacePointRadio = addPlayerDataToRadioData(
                      targetRacePointRadio,
                      targetPlayer,
                    )

                  if (targetPlayer) {
                    results.push(
                      createCardData(
                        racePointRadio,
                        targetPlayer,
                        targetRacePointRadio,
                        mainRace.value[0],
                      ),
                    )
                    radioData?.push(targetRacePointRadio)
                  }
                }
              }
            }
          }
        }
      }
      racePointRadioData.value = results

      // RACE POINT RADIO表示対象の無線データをCollectionModuleに保存する
      radioDataStore.setRadios(radioData)
    }

    /**
     * 表示している大会に紐づくレースのコンテンツ情報を取得する
     */
    const fetchDisplayedChampionshipMovieInfo = async () => {
      const raceIds = [...sessionsWithOutRace.value, ...mainRace.value]
        .map((race) => race.id)
        .filter((raceId): raceId is string => typeof raceId === 'string')

      return fetchMovieInfo(raceIds)
    }

    /**
     * 全てのセッションの表示/非表示を切り替える
     */
    const toggleTimetable = () => {
      topPageState.showTimetable = !topPageState.showTimetable
    }

    /**
     * レース選択画面のデータをクリアする
     */
    const clearTopPageData = () => {
      clearChampionships()
      clearRaces()
      radioDataStore.clearRadioData()
    }

    // watcher
    /**
     * 現在選択されている大会のID変更を監視し、変更時にローカルストレージに大会IDを保持する
     */
    watch(
      () => topPageState.currentChampionshipId,
      (currentId) => {
        LocalStorageAccessor.currentChampionshipId = currentId
      },
    )

    return {
      topPageState,
      racePointRadioData,
      fetchTopPageData,
      fetchRacePointRadioData,
      clearTopPageData,
      championships,
      sortedChampionships,
      currentChampionship,
      fetchRaces,
      freePracticeList,
      qualifyingRaceList,
      sessionsWithOutRace,
      mainRace,
      contentsInfo,
      contentsInfos,
      angleMovieInfos,
      qualifyingRaceStartEventList,
      highlightsForPreLoad,
      fetchRaceRankData,
      getRankingCardData,
      clearRankData,
      fetchRacePointVideoData,
      racePointVideoData,
      fetchDisplayedChampionshipMovieInfo,
      radioDataStore,
      toggleTimetable,
    }
  }
}

const value: ValueType<TopPageStore> = {}

export default {
  createStore: new TopPageStore().createStore,
  value: value as Required<typeof value>,
}
