









import { defineComponent, inject, onBeforeMount } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import VueRouter from 'vue-router'
import OneTimePassInputSection from '@/components/MypageOneTimePassInputPage/OneTimePassInputPane/OneTimePassInputSection.vue'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import useUser from '@/store/hook/useUser'

/**
 * マイページ: ワンタイムパス: 入力ペイン
 */
export default defineComponent({
  name: 'OneTimePassInputPane',
  components: {
    OneTimePassInputSection,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { oneTimePass, setOneTimePassPlan, getCouponPlan } = oneTimePassPageStore
    const { saveOneTimePassUsage } = useUser()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    /**
     * プラン登録なしのワンタイムパス利用時の処理
     * 利用するワンタイムパスをユーザー情報に保存する
     */
    const saveOneTimePassUsageOnUser = async (oneTimePassToBuUsed: CouponDocument) => {
      const loader = loading.show()

      // 利用中のワンタイムパスをユーザー情報に保存する
      const result = await saveOneTimePassUsage(
        UserStore.value.user.value,
        oneTimePassToBuUsed.couponCode ?? '',
      )

      if (!result.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('MypagePage.MypageOneTimePass.inputPage.errors.useOneTimePassError.title'),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.useOneTimePassError.message',
          ),
        })
        return
      }

      // グローバルストアに保持するユーザー情報を更新
      await UserStore.value.fetchUserData(LoginStore.value.loginId)
      loader.hide()

      // 取得したワンタイムパスをストアに格納
      oneTimePass.value = oneTimePassToBuUsed

      // 完了画面に遷移する
      router.replace({ name: 'MypageOneTimePassWithNoPlanCompletePage' })
    }

    onBeforeMount(() => {
      // 現在利用している契約情報を取得する
      oneTimePassPageStore.fetchContractInfo()
    })

    return {
      oneTimePass,
      setOneTimePassPlan,
      getCouponPlan,
      saveOneTimePassUsageOnUser,
    }
  },
  methods: {
    /**
     * ワンタイムパス登録ボタン押下時以下の処理を行う
     * 1. 取得したワンタイムパスのプラン情報をストアに保存
     * 2. 確認画面に遷移するための通知を親コンポーネントに伝える
     */
    async setOneTimePassPlanAndOnSubmit(oneTimePass: CouponDocument) {
      /** ワンタイムパスのプラン情報を取得 */
      const originalOneTimePassPlan = oneTimePass?.plans
        ? this.getCouponPlan(oneTimePass.plans)
        : null
      if (!originalOneTimePassPlan) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.message',
          ),
        })
        // ワンタイムパスのプラン情報が取得できない場合、画面遷移しない
        return
      }

      // 取得したワンタイムパスをストアに格納
      this.oneTimePass = oneTimePass
      // 取得したワンタイムパスのプラン情報を元に画面表示用のワンタイムパスプラン情報を作成し、ストアに保存
      this.setOneTimePassPlan(originalOneTimePassPlan, oneTimePass)

      /** 確認画面への遷移 */
      this.$emit('handlerSubmit')
    },
  },
})
