var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "membership-point-expiration-parts" }, [
    _c("p", { staticClass: "membership-point-expiration-parts__information" }, [
      _c("span", { staticClass: "membership-point-expiration-parts__point" }, [
        _vm._v(_vm._s(_vm.point.toLocaleString()) + "P"),
      ]),
      _c("span", { staticClass: "membership-point-expiration-parts__date" }, [
        _vm._v(
          "：" +
            _vm._s(_vm.$tc("MypagePage.MypageTopPage.point.expirationDate")) +
            " " +
            _vm._s(_vm.expirationDate)
        ),
      ]),
    ]),
    _c(
      "p",
      { staticClass: "membership-point-expiration-parts__detail" },
      [
        _c(
          "router-link",
          {
            staticClass: "membership-point-expiration-parts__detail-link",
            attrs: {
              to: "/mypage/point-expiration-date?backLink=" + _vm.backLink,
            },
          },
          [_vm._v(_vm._s(_vm.$tc("MypagePage.MypageTopPage.point.linkText")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }