var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-pane" },
    [
      _c(
        "vue-pull-refresh",
        {
          staticClass: "home-pane__pull-down",
          attrs: { "on-refresh": _vm.onRefresh, config: _vm.refreshConfig },
        },
        [
          _vm.headlineLinksData.length > 0
            ? _c("img-slider-section", {
                staticClass: "home-pane__img-slider",
                attrs: { "img-slide-links": _vm.headlineLinksData },
              })
            : _vm._e(),
          _vm.upComingEventData
            ? _c("countdown-section", {
                staticClass: "home-pane__countdown",
                attrs: {
                  countdownTargetData: _vm.upComingEventData,
                  hasDisplayedOnboarding: _vm.hasDisplayedOnboarding,
                  hasLiveRace: _vm.hasLiveRace,
                  liveRaceLink: _vm.liveRaceLink,
                },
                on: { onCloseOnboardingScreen: _vm.closeOnboardingScreen },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "home-pane__link-buttons" },
            [_c("home-link-buttons-section")],
            1
          ),
          _vm.youtubeLinksData.length > 0
            ? _c("youtube-list-section", {
                staticClass: "home-pane__youtube-list",
                attrs: {
                  title: _vm.$tc("HomePage.youtubeListTitle"),
                  videoLinks: _vm.youtubeLinksData,
                  enabledCarouselList: _vm.enabledCarouselList,
                  membershipType: _vm.membershipType,
                },
                on: { videoSelected: _vm.onYoutubeVideoCardSelected },
              })
            : _vm._e(),
          _vm.limitedVideoLinksData.length > 0
            ? _c("youtube-list-section", {
                staticClass: "home-pane__limited-videos",
                attrs: {
                  title: _vm.$tc("HomePage.limitedVideoListTitle"),
                  videoLinks: _vm.limitedVideoLinksData,
                  enabledCarouselList: _vm.enabledCarouselList,
                  membershipType: _vm.membershipType,
                },
                on: { videoSelected: _vm.onYoutubeVideoCardSelected },
              })
            : _vm._e(),
          _vm.motorSportYoutubeLinksData.length > 0
            ? _c("youtube-list-section", {
                staticClass: "home-pane__motor-sport-youtube-list",
                attrs: {
                  title: _vm.$tc("HomePage.motorSportYoutubeListTitle"),
                  videoLinks: _vm.motorSportYoutubeLinksData,
                  enabledCarouselList: _vm.enabledCarouselList,
                  membershipType: _vm.membershipType,
                },
                on: { videoSelected: _vm.onYoutubeVideoCardSelected },
              })
            : _vm._e(),
          _vm.newArrivalHighlightAndCommentData.length > 0
            ? _c("highlights-scene-section", {
                staticClass: "home-pane__highlights-comments",
                attrs: { enabledCarouselList: _vm.enabledCarouselList },
              })
            : _vm._e(),
          _c("sns-content-section", { staticClass: "home-pane__sns-list" }),
          _c("limited-video-alert-modal-section", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.limitedVideoAlertModalEnabled,
                expression: "limitedVideoAlertModalEnabled",
              },
            ],
            attrs: { link: _vm.aboutSfgoLink },
            on: { close: _vm.hideLimitedVideoAlertModal },
          }),
          _vm.videoModalEnabled && _vm.videoId
            ? _c("limited-video-modal-section", {
                attrs: { videoId: _vm.videoId },
                on: { close: _vm.hideVideoModal },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.hasDisplayedNewArrivals
        ? _c("notice-new-arrivals-modal-section", {
            attrs: { externalLink: _vm.newReleasePageUrl },
            on: { onClickOk: _vm.closeNewArrivalsModal },
          })
        : _vm._e(),
      _vm.newArrivalsRewards.length > 0
        ? _c("reward-notice-modal-section", {
            attrs: {
              reward: _vm.newArrivalsRewards[0],
              newArrivalsRewardsLength: _vm.newArrivalsRewards.length,
            },
            on: {
              onClickShowRewardPage: _vm.handleShowRewardPageClicked,
              onClickClose: _vm.handleNoticeModalCloseClicked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }