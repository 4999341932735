

















import { defineComponent, PropType } from '@vue/composition-api'
import MissionHexagonParts from '@/components/MissionPage/MissionListPane/parts/MissionHexagonParts.vue'
import { MissionProgressCardType } from '@/components/MissionPage/hook/useMissionProgressData'
import MissionProgressDocument from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'

/**
 * ミッション画面 ミッション達成状況 ハニカム8個パーツ
 */
export default defineComponent({
  name: 'MissionHoneycombEightParts',
  components: {
    MissionHexagonParts,
  },
  props: {
    mission: {
      type: Object as PropType<MissionProgressCardType>,
      required: true,
    },
    /**
     * 開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    checkInMissionsBeingHeld: {
      type: Array as PropType<MissionProgressDocument[]>,
      required: true,
    },
  },
  setup(props) {
    /**
     * 一つ前の達成条件を取得する
     */
    const getPrevCondition = (index: number) =>
      index > 0 ? props.mission.achievementCondition?.[index - 1] : 'noPreviousCondition'

    return {
      getPrevCondition,
    }
  },
})
