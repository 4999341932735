









































import {
  computed,
  defineComponent,
  ref,
  Ref,
  WritableComputedRef,
  onBeforeMount,
} from '@vue/composition-api'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import AttentionMessageSection, {
  AttentionMessageData,
} from '@/components/pc/MyPage/common/AttentionMessageSection.vue'
import PaymentCycleSection from '@/components/pc/MyPage/common/PaymentCycleSection.vue'
import StoreUtil from '@/store/StoreUtil'
import useContract from '@/components/hook/useContract'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useErrorHandling from '@/components/hook/useErrorHandling'
import useContractInfo from '@/store/hook/useContractInfo'
import useHistory from '@/store/hook/useHistory'
import Logger from '@/util/logger/Logger'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import I18n from '@/locales/I18n'

/**
 * 有料会員変更 確認画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'PaidMembershipChangeConfirmPane',
  components: {
    SignupHeaderSection,
    ActionButtonsSection,
    AtomInputButton,
    CurrentPlanCardSection,
    PaymentCycleSection,
    AttentionMessageSection,
  },
  setup() {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const userStore = StoreUtil.useStore('UserStore')
    const { user } = userStore
    const { createContract } = useContract()
    const { saveContractInfoHistory } = useHistory()

    /** 請求サイクル画像パス */
    const paymentCycleImgPath = computed(() => {
      const path = `SignupPage/confirmPage/payment_cycle_${signupPageStore.selectedPlanGroupId.value}_${I18n.locale}`
      return {
        pc: `${path}_pc.jpg`,
        sp: `${path}_sp.jpg`,
      }
    })
    /** 注意事項テキスト */
    const attentionMessage: Ref<AttentionMessageData | null> = ref(null)
    /** クーポン利用してるかのフラグ */
    const isCouponUsed: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      if (
        signupPageStore.getSelectedContractPlan.value?.planGroupId === 'limitedTimePaidPlan' ||
        signupPageStore.getSelectedContractPlan.value?.planGroupId ===
          'limitedTimePaidPlanForAnnual'
      ) {
        /** クーポン期限切れ前に通知する設定をしてるかのフラグ */
        const isNeedToNotify = signupPageStore.coupons.value[0]?.notifyBeforeExpires
        const attentionMessageNote = isNeedToNotify
          ? I18n.tc('PaidMembershipChange.ConfirmPage.couponPlan.attentionNote')
          : null

        /** 注意事項テキストをセット */
        attentionMessage.value = {
          message: I18n.tc('PaidMembershipChange.ConfirmPage.couponPlan.attentionMessage'),
          note: attentionMessageNote,
        }
        /** クーポン利用かのフラグをセット */
        isCouponUsed.value = true
      }
    })

    return {
      plan: signupPageStore.getSelectedContractPlan as Ref<ContractPlanDocument>,
      selectedPlanGroupId:
        signupPageStore.selectedPlanGroupId as WritableComputedRef<PaidPlanGroupType>,
      memberType: signupPageStore.memberType,
      paymentCycleImgPath,
      user,
      isCouponUsed,
      attentionMessage,
      createContract,
      saveContractInfoHistory,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const { createContractError } = useErrorHandling()
      const { fetchContractInfo, ownContractInfo } = useContractInfo()
      const errorStore = StoreUtil.useStore('ErrorStore')

      const loader = this.$loading.show()
      /** 契約情報契約プランを登録 */
      const createContractResult = await this.createContract(this.selectedPlanGroupId)

      if (!createContractResult.isSuccess) {
        loader.hide()
        const response = await createContractError(createContractResult.response?.data)
        if (!response.isMoveToErrorPage) {
          // プラン選択画面に遷移する
          this.$emit('handlerCancel')
        } else {
          // エラーページに遷移する
          errorStore.setConfig('createContractError')
          await this.$router.replace({ name: 'ErrorPage' })
        }
        return
      }

      /**
       * 契約情報を取得する
       * プラン変更完了画面の請求情報表示に影響が出るため、Pusher通知が動かない可能性を考慮し、ここで一応グローバルストアの契約情報を最新化する
       */
      await Promise.all([fetchContractInfo(), ContractInfoStore.value.fetchUserContractInfo()])

      const { contractInfoId } = ownContractInfo.value
      if (contractInfoId) {
        /** 契約情報変更履歴を登録する */
        const apiResult = await this.saveContractInfoHistory(contractInfoId, this.user)
        Logger.debug(`ContractInfoHistory: ${apiResult.isSuccess}`)
      }

      this.$emit('handlerSubmit')
      loader.hide()
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
  },
})
