import { StoreBase, ValueType } from '@/store/StoreBase'
import useUser from '@/store/hook/useUser'
import useBillingInfo from '@/store/hook/useBillingInfo'

/**
 * ユーザーデータを扱うStore
 * 本ストアはGlobalStoreとする
 */
/* eslint-disable class-methods-use-this */
class UserStore implements StoreBase {
  createStore() {
    // hook
    const { fetchUser, user, clearUsers } = useUser()
    const { fetchFixedBillingInfo, ownBillingInfo, clearBillingInfo } = useBillingInfo()

    /**
     * ユーザー情報を取得する
     */
    const fetchUserData = async (loginId?: string) => {
      if (!loginId) {
        return
      }
      await fetchUser(loginId)
    }

    /** 初回ログインかどうかを判定
     * 必須入力となっている名前(漢字)の姓が未入力の場合は初回ログインと判断する
     */
    const isInitLogin = () => !user.value?.additionalData?.familyName

    /**
     * ユーザー情報をクリアする
     */
    const clearUserData = () => {
      clearUsers()
      clearBillingInfo()
    }

    return {
      fetchFixedBillingInfo,
      ownBillingInfo,
      fetchUserData,
      user,
      clearUserData,
      isInitLogin,
    }
  }
}
const value: ValueType<UserStore> = {}

export default {
  createStore: new UserStore().createStore,
  value: value as Required<typeof value>,
}
