var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-input-pane" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("SignupPage.Steps.Step2") },
      }),
      _c(
        "div",
        { staticClass: "app-signup-input-pane__main" },
        [
          _c("fieldset-email-parts", { attrs: { required: false } }),
          _c("fieldset-password-parts", { attrs: { required: true } }),
          _c(
            "ValidationObserver",
            { attrs: { slim: "" } },
            [
              _c(
                "div",
                { staticClass: "app-signup-input-pane__item" },
                [_c("fieldset-name-parts", { attrs: { required: true } })],
                1
              ),
              _c(
                "ValidationProvider",
                {
                  attrs: {
                    slim: "",
                    name: "birthDay",
                    rules: "required|validBirthDay",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "app-signup-input-pane__item" },
                    [
                      _c("fieldset-birthday-parts", {
                        attrs: { required: true },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "ValidationProvider",
                { attrs: { slim: "", name: "country", rules: "required" } },
                [
                  _c(
                    "div",
                    { staticClass: "app-signup-input-pane__item" },
                    [
                      _c("fieldset-country-parts", {
                        attrs: { required: true },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "app-signup-input-pane__term" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc("SignupPage.FormInput.TermLinkText")
                    ),
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "app-signup-input-pane__agreement" },
                [
                  _c("fieldset-checkbox-parts", {
                    attrs: {
                      value: false,
                      label: _vm.$tc("SignupPage.FormInput.Agreement"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "action-buttons-section",
                {
                  attrs: {
                    scrollTargetSelector: ".app-signup-input-pane",
                    visible: true,
                  },
                },
                [
                  _c("atom-input-button", {
                    attrs: { linkText: _vm.$tc("common.registration") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }