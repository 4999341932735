



















import { defineComponent, PropType } from '@vue/composition-api'
import NotificationPointIcon from '@/components/NotificationPane/NotificationPane/parts/icons/NotificationPointIcon.vue'

export interface PointData {
  id: string
  date: string
  title: string
  expirationDate: string
}

export default defineComponent({
  name: 'NotificationPointItemParts',
  components: { NotificationPointIcon },
  props: {
    itemData: {
      type: Object as PropType<PointData>,
      required: true,
    },
  },
})
