





































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import AtomLink from '@/components/atoms/AtomLink.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import i18n from '@/locales/I18n'
import Const from '@/util/Const'
import ModalCouponCodeInputParts from '@/components/common/modal/parts/ModalCouponCodeInputParts.vue'
import { LocationInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'

export default defineComponent({
  name: 'LocationErrorSection',
  components: { ModalCouponCodeInputParts, ModalMessageParts, AtomLink, ModalTitleParts },
  props: {
    /**
     * 現在地情報
     */
    currentLocationInfo: {
      type: Object as PropType<LocationInfoType | null>,
      default: null,
    },
    /**
     * 店舗名
     */
    storeName: {
      type: String,
      default: null,
    },
    /**
     * 入力されたワンタイムパスコード
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * エラーかどうか
     */
    hasError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
    /**
     * 入力フォームを表示するかどうか
     */
    isShowInput: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    /**
     * text
     * @param {string} val
     */
    const text = computed({
      get: () => props.value,
      set: (val: string) => emit('input', val),
    })

    // 位置情報エラーのメッセージ
    const locationErrorMessage = computed(() =>
      i18n.tc('common.errors.locationError.error', 1, [props.storeName]),
    )

    // 現在地のGoogle Mapのリンク
    const currentLocationGoogleMapUrl = computed(() => {
      const googleMapUrl =
        i18n.locale === 'ja' ? Const.EXTERNAL_LINKS.GOOGLE_MP.JA : Const.EXTERNAL_LINKS.GOOGLE_MP.EN
      return `${googleMapUrl}&ll=${props.currentLocationInfo?.lat},${props.currentLocationInfo?.lng}`
    })

    // 現在地の緯度経度情報を返す
    const displayCurrentLocation = computed(() =>
      i18n
        .t('common.errors.locationError.currentLocation', {
          lat: props.currentLocationInfo?.lat,
          lng: props.currentLocationInfo?.lng,
        })
        .toString(),
    )

    /**
     * もう一度ワンタイムパスを使用するボタン押下時の処理
     */
    const onCLickReUse = () => {
      emit('onCLickReUse')
    }

    return {
      text,
      locationErrorMessage,
      currentLocationGoogleMapUrl,
      displayCurrentLocation,
      onCLickReUse,
    }
  },
})
