


























import { computed, defineComponent, PropType } from '@vue/composition-api'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import I18n from '@/locales/I18n'

export default defineComponent({
  name: 'ModalCouponPlanCardParts',
  props: {
    /**
     * 表示するプラン
     */
    plan: {
      type: Object as PropType<ContractPlanDocument | null>,
      default: () => ({}),
    },
  },
  setup(props) {
    /** 金額の単位（現状は円のみ） */
    const planPriceUnit = I18n.tc('SignupPage.SelectPlanPage.plan.priceUnit')

    /** 表示する金額（円） */
    const priceJpy = computed(() => `${props.plan?.price?.JPY.toLocaleString()}${planPriceUnit}`)

    return {
      priceJpy,
    }
  },
})
