









import { defineComponent } from '@vue/composition-api'
import AppSignupTopPane from '@/components/AppSignupPage/AppSignupTopPane.vue'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'

export default defineComponent({
  name: 'AppSignupTopPage',
  components: { HeaderPane, AppSignupTopPane },
})
