






















import { defineComponent, ref } from '@vue/composition-api'
import MypagePointMonthlySection from '@/components/MypagePointHistoryPage/MypagePointHistoryPane/MypagePointMonthlySection.vue'
import AtomSelectYear from '@/components/atoms/AtomSelectYear.vue'

export default defineComponent({
  name: 'MypagePointHistoryPane',
  components: { AtomSelectYear, MypagePointMonthlySection },
  setup() {
    const pointHistoryData = [
      {
        month: '2025年 1月',
        pointList: [
          {
            id: '1',
            point: '1000',
            status: 'lost',
            date: '2025/01/31',
            description: '',
          },
          {
            id: '2',
            point: '10',
            status: 'get',
            date: '2025/01/22',
            description: '「イベントチェックイン」で獲得',
          },
          {
            id: '3',
            point: '10',
            status: 'get',
            date: '2025/01/22',
            description: '「SNSへ投稿」で獲得',
          },
          {
            id: '4',
            point: '500',
            status: 'get',
            date: '2025/01/22',
            description: '「シーズンラウンドチェックイン」で獲得',
          },
        ],
      },
      {
        month: '2024年 12月',
        pointList: [
          {
            id: '5',
            point: '10',
            status: 'get',
            date: '2024/12/31',
            description: '「イベントチェックイン」で獲得',
          },
          {
            id: '6',
            point: '150',
            status: 'get',
            date: '2024/01/22',
            description: '「イベントチェックイン」で獲得',
          },
          {
            id: '7',
            point: '10',
            status: 'get',
            date: '2024/12/19',
            description: '「SNSへ投稿」で獲得',
          },
          {
            id: '8',
            point: '20',
            status: 'get',
            date: '2024/12/16',
            description: '「お気に入り登録」で獲得',
          },
          {
            id: '9',
            point: '20',
            status: 'get',
            date: '2024/12/16',
            description: '「プロフィール登録」で獲得',
          },
        ],
      },
    ]

    // 現在選択中の年
    const currentYear = ref(2025)

    // 年リスト
    const yearList = [2025, 2024, 2023]

    /**
     * 年が変更されたときの処理
     * @param {Number} item
     */
    const handleYearChange = (item: number) => {
      currentYear.value = item
    }

    return {
      pointHistoryData,
      currentYear,
      yearList,
      handleYearChange,
    }
  },
})
