










import { defineComponent } from '@vue/composition-api'
import ConfirmationSentSection from '@/components/AppSignupPage/AppConfirmationSentPane/ConfirmationSentSection.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'

export default defineComponent({
  name: 'AppConfirmationSentPane',
  components: { SubHeaderSection, ConfirmationSentSection },
})
