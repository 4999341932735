


















import { defineComponent, PropType } from '@vue/composition-api'
import { PointExpirationBackLinkType } from '@/views/MypagePage/MypagePointExpirationDatePage.vue'

export default defineComponent({
  name: 'MembershipPointExpirationParts',
  props: {
    /**
     * 表示するポイント
     */
    point: {
      type: Number,
    },
    /**
     * 表示する失効日
     */
    expirationDate: {
      type: String,
    },
    /**
     * 戻るボタンで戻る画面を指定
     */
    backLink: {
      type: String as PropType<PointExpirationBackLinkType>,
      default: 'mypage',
    },
  },
})
