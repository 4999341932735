



















































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import { orderBy } from 'lodash'
import SceneCardUserImagesParts from '@/components/HomePage/HomePane/parts/SceneCardUserImagesParts.vue'
import { NewArrivalHighlightAdditionalType } from '@/store/stores/pageStore/HomePage/HomePageStore'
import { UserRetrieveNameResponseType } from '@/store/stores/collectionModule/documents/user/UserRetrieveNameDocument'
import HighlightCommentDocument from '@/store/stores/collectionModule/documents/highlight/HighlightCommentDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import type { UserType } from '@/store/stores/pageStore/common/UserType'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import useUserTypeRoles from '@/components/hook/useUserTypeRoles'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultIcon = require('@/assets/img/icon/icon_user_default.png')

/**
 * 新着ハイライトカード コメントユーザーのデータ型
 */
export type CreateCommentUserType = {
  id: string
  userDisplayName: string
  userPicture: string
  userType: UserType
}

/**
 * 新着ハイライトカード その他直近コメントユーザーのデータ型
 */
export type OtherCommentUserType = Omit<CreateCommentUserType, 'userType'>

/**
 * 新着ハイライトカード（コメント）のデータ型
 */
type HomeHighlightCommentCardType = {
  highlight: {
    highlightId: string
    thumbnailPath: string
    round: string
    lap: string
    sector: string
    createdUserName: string
  }
  highlightComment: {
    comment: string
    createCommentUser: CreateCommentUserType
    otherCommentUsers?: Array<OtherCommentUserType>
    commentCount: number
  }
  link: string
}

/**
 * HOME ハイライトシーンリスト カードパーツ
 */
export default defineComponent({
  name: 'SceneListCardParts',
  components: {
    SceneCardUserImagesParts,
  },
  props: {
    /**
     * 大会データ
     */
    championships: {
      type: Array as PropType<Array<ChampionshipDocument>>,
      required: true,
    },
    /**
     * レースデータ
     */
    raceList: {
      type: Array as PropType<Array<RaceDocument>>,
      required: true,
    },
    /**
     * ハイライトコメントデータ
     */
    highlightComment: {
      type: Object as PropType<HighlightCommentDocument>,
      required: true,
    },
    /**
     * ハイライト追加データ
     */
    highlightAdditional: {
      type: Object as PropType<NewArrivalHighlightAdditionalType>,
      required: true,
    },
    /**
     * ハイライトIDごとに分類されたハイライトデータ
     */
    highlightsById: {
      type: Object as PropType<Record<string, HighlightDocument>>,
      required: true,
    },
    /**
     * ハイライトIDごとに分類されたハイライトコメントデータ
     */
    commentsByHighlightId: {
      type: Object as PropType<Record<string, Array<HighlightCommentDocument>>>,
      required: true,
    },
    /**
     * 新着ハイライトで利用するユーザー情報
     */
    retrieveNameUsersByUserId: {
      type: Object as PropType<Record<string, UserRetrieveNameResponseType>>,
      required: true,
    },
  },
  setup(props) {
    const { getUserTypeRoles } = useUserTypeRoles()

    /**
     * 新着ハイライトコメントカードのデータ
     */
    const card = computed((): HomeHighlightCommentCardType => {
      const propsHighlightComment = props.highlightComment
      const propsHighlightAdditional = props.highlightAdditional
      const propsUsersByUserId = props.retrieveNameUsersByUserId

      // コメントカードに紐づくハイライト
      const targetHighlight = props.highlightsById?.[propsHighlightComment.highlightId ?? '']
        ? props.highlightsById?.[propsHighlightComment.highlightId ?? '']
        : undefined
      // 対象のレース
      const targetRace = props.raceList.find((race) => race.id === targetHighlight?.matchId)
      // 対象の大会
      const targetChampionship = props.championships.find(
        (championship) => championship.id === targetRace?.championshipMasterId,
      )
      // ハイライトを作成したユーザー
      const createHighlightUser = propsUsersByUserId?.[targetHighlight?._createdBy ?? '']
        ? propsUsersByUserId?.[targetHighlight?._createdBy ?? '']
        : undefined
      // 対象のハイライトコメントカードを作成したユーザー
      const createDisplayCommentCardUser = propsUsersByUserId?.[
        propsHighlightComment._createdBy ?? ''
      ]
        ? propsUsersByUserId?.[propsHighlightComment._createdBy ?? '']
        : undefined

      // ユーザーの種類
      const userTypeRoles = getUserTypeRoles(createDisplayCommentCardUser?.roles)
      const userRole = userTypeRoles?.find((role) => Const.SFGO_ROLES_FOR_USER_TYPE.includes(role))
      const contractPlan = createDisplayCommentCardUser?.contract?.planGroupId.includes('freePlan')
        ? 'free-user'
        : 'paid-user'

      const targetComments = props.commentsByHighlightId?.[targetHighlight?.highlightId ?? '']
        ? props.commentsByHighlightId?.[targetHighlight?.highlightId ?? '']
        : undefined

      // このカードのコメント以外で直近でコメントしたユーザー（3件）
      const otherRecentCommentUsers = orderBy(targetComments, '_createdDate')
        ?.filter((comment) => comment.id !== propsHighlightComment.id)
        .slice(-3)
        .map((filteredComment) => {
          const uuidForOtherComments = uuidv4()
          const targetUser = propsUsersByUserId?.[filteredComment._createdBy ?? '']
            ? propsUsersByUserId?.[filteredComment._createdBy ?? '']
            : undefined

          return {
            id: uuidForOtherComments,
            userDisplayName: targetUser?.additionalData?.userDisplayName ?? '',
            userPicture: targetUser?.userPicture
              ? CloudFrontUtil.getSignedUrl(targetUser?.userPicture)
              : defaultIcon,
          }
        })

      const uuidForCreateComment = uuidv4()
      return {
        highlight: {
          highlightId: targetHighlight?.highlightId ?? '',
          thumbnailPath: targetHighlight?.thumbnailUri
            ? CloudFrontUtil.getSignedUrl(targetHighlight.thumbnailUri)
            : '',
          lap: targetHighlight?.additionalData?.lap || '-',
          sector: targetHighlight?.additionalData?.sector ?? '--',
          round: targetChampionship?.round
            ? `Round${targetChampionship?.round}`
            : targetChampionship?.circuitName[I18n.locale] ?? '',
          // FL-UXで作成したハイライトの場合、SFgo公式を表示する
          createdUserName:
            targetHighlight?._organization === process.env.VUE_APP_SFGO_PARENT_ORG_ID
              ? I18n.tc('HomePage.highlights.card.officialUserName')
              : createHighlightUser?.additionalData?.userDisplayName ?? '',
        },
        highlightComment: {
          comment: propsHighlightComment.comment ?? '',
          createCommentUser: {
            id: uuidForCreateComment,
            userDisplayName: createDisplayCommentCardUser?.additionalData?.userDisplayName ?? '',
            userPicture: createDisplayCommentCardUser?.userPicture
              ? CloudFrontUtil.getSignedUrl(createDisplayCommentCardUser?.userPicture)
              : defaultIcon,
            userType: userRole ?? contractPlan,
          },
          otherCommentUsers: otherRecentCommentUsers,
          commentCount: propsHighlightAdditional.count ?? 0,
        },
        link: targetRace
          ? `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${targetHighlight?.highlightId}/?playType=highlight&highlightId=${targetHighlight?.highlightId}`
          : '',
      }
    })

    return {
      card,
    }
  },
  methods: {
    /**
     * モーダル表示 通知
     */
    emitClick() {
      this.$emit('click')
    },
  },
})
