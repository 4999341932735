
























import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MailInputSection from '@/components/SignupPage/EmailAuthenticationPane/MailInputSection.vue'
import ServiceButtonListParts from '@/components/AppSignupPage/AppSignupTopPane/parts/ServiceButtonListParts.vue'

export default defineComponent({
  name: 'AppSignupTopPane',
  components: { ServiceButtonListParts, MailInputSection, SubHeaderSection },
})
