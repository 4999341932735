








































































import { defineComponent, inject } from '@vue/composition-api'
import VueRouter from 'vue-router'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import StoreUtil from '@/store/StoreUtil'
import useMissionTerm from '@/store/hook/mission/useMissionTerm'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { PointExpirationBackLinkType } from '@/views/MypagePage/MypagePointExpirationDatePage.vue'

type MissionPointExpirationInfoType = {
  expirationDate: string
  expirationPoint: number
  grantedSeasonYear: number
}

/**
 * ミッション画面 ポイント有効期限のお知らせモーダル
 */
export default defineComponent({
  name: 'MissionPointExpirationModalSection',
  components: {
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomInputButton,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { userPointsWithExpirationByExpirationDate, expirationUserPoints } = mypagePageStore
    const { getMissionEndDateMMDD, getTargetSeasonYear } = useMissionTerm()
    const { getDisplayDate } = useDisplayDependingOnLang()

    const router = inject('router') as VueRouter

    // 遷移先の画面から戻るボタンを押した時に戻ってくる画面を指定
    const backLink: PointExpirationBackLinkType = 'mission'

    // 2シーズン後に失効するミッションポイント
    const thisSeasonUserPointsWithExpirationByExpirationDate = [
      ...userPointsWithExpirationByExpirationDate.value.entries(),
    ].find(([date]) => getDisplayDate(date, 'MM/DD', 'MMM DD') === getMissionEndDateMMDD())

    // 今シーズン失効予定の情報
    const thisSeasonMissionPointExpirationInfo: MissionPointExpirationInfoType | null =
      thisSeasonUserPointsWithExpirationByExpirationDate
        ? {
            expirationDate: thisSeasonUserPointsWithExpirationByExpirationDate[0],
            expirationPoint: thisSeasonUserPointsWithExpirationByExpirationDate[1].point,
            grantedSeasonYear:
              thisSeasonUserPointsWithExpirationByExpirationDate[1].grantedSeasonYear,
          }
        : null

    // 先シーズンに失効したポイントの情報
    const previousSeasonMissionPointExpirationInfo: MissionPointExpirationInfoType | null =
      expirationUserPoints.value.length > 0
        ? {
            expirationDate: getDisplayDate(expirationUserPoints.value[0].pointExpirationDate ?? 0),
            expirationPoint: expirationUserPoints.value.reduce(
              (acc, cur) => acc + (cur.point ?? 0),
              0,
            ),
            grantedSeasonYear: getTargetSeasonYear(
              expirationUserPoints.value[0].getGrantedDate ?? 0,
            ),
          }
        : null

    /**
     * ポイント有効期限画面に遷移する
     */
    const onMoveToMypagePointExpirationDatePage = () => {
      router.push({ path: '/mypage/point-expiration-date', query: { backLink } })
      // モーダルを閉じる
      emit('close')
    }

    return {
      backLink,
      thisSeasonMissionPointExpirationInfo,
      previousSeasonMissionPointExpirationInfo,
      onMoveToMypagePointExpirationDatePage,
    }
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
