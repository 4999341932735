var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-point-expiration-date-page" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc(
            "MypagePage.MypagePointExpirationDatePage.pageTitle"
          ),
          backLink: "/" + _vm.backLink,
        },
      }),
      _c(
        "div",
        { staticClass: "mypage-point-expiration-date-page__body" },
        [_c("mypage-point-expiration-date-pane")],
        1
      ),
      _c("global-navigation-pane", { attrs: { current: "mypage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }