















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import OneTimePassWithNoPlanCompletePane from '@/components/MypageOneTimePassWithNoPlanCompletePage/OneTimePassWithNoPlanCompletePane.vue'

/**
 * マイページ: 優待コード: 登録完了ページ
 */
export default defineComponent({
  name: 'MypageOneTimePassWithNoPlanCompletePage',
  components: {
    OneTimePassWithNoPlanCompletePane,
    SubHeaderSection,
    GlobalNavigationPane,
  },
})
