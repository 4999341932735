

















import { computed, defineComponent } from '@vue/composition-api'
import FieldsetTextParts from '@/components/common/form/FieldsetTextParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'ModalCouponCodeInputParts',
  components: { AtomButton, FieldsetTextParts },
  props: {
    value: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    /**
     * text
     * @param {string} val
     */
    const text = computed({
      get: () => props.value,
      set: (val: string) => emit('input', val),
    })

    const handleUseClicked = () => {
      emit('onCLickUse')
    }

    return {
      text,
      handleUseClicked,
    }
  },
})
