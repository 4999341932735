var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notification-tabs-parts",
      class: { "notification-tabs-parts--is-pc": _vm.isPc },
    },
    [
      _c("ul", { staticClass: "notification-tabs-parts__tabs" }, [
        _c("li", { staticClass: "notification-tabs-parts__tab" }, [
          _c(
            "button",
            {
              staticClass: "notification-tabs-parts__button",
              class: {
                "notification-tabs-parts__button--selected":
                  _vm.selectedTabId === "news",
              },
              on: {
                click: function ($event) {
                  return _vm.handleClick("news")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "notification-tabs-parts__button__icon" },
                [_c("notification-news-icon")],
                1
              ),
              _c(
                "span",
                { staticClass: "notification-tabs-parts__button__label" },
                [_vm._v(_vm._s(_vm.$tc("NotificationPage.tabs.news")))]
              ),
            ]
          ),
        ]),
        _c("li", { staticClass: "notification-tabs-parts__tab" }, [
          _c(
            "button",
            {
              staticClass: "notification-tabs-parts__button",
              class: {
                "notification-tabs-parts__button--selected":
                  _vm.selectedTabId === "point",
              },
              on: {
                click: function ($event) {
                  return _vm.handleClick("point")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "notification-tabs-parts__button__icon" },
                [_c("notification-point-icon")],
                1
              ),
              _c(
                "span",
                { staticClass: "notification-tabs-parts__button__label" },
                [_vm._v(_vm._s(_vm.$tc("NotificationPage.tabs.point")))]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }