var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "mission-point-expiration-modal-section",
    attrs: { hasLinks: true },
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("modal-title-parts", {
              attrs: {
                title: _vm.$tc("MissionPage.pointExpirationModal.title"),
              },
            }),
            _c("modal-message-parts", {
              attrs: {
                message: _vm.$tc("MissionPage.pointExpirationModal.message"),
              },
            }),
            _c("br"),
            _c(
              "div",
              {
                staticClass:
                  "mission-point-expiration-modal-section__expiration",
              },
              [
                _vm.previousSeasonMissionPointExpirationInfo
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mission-point-expiration-modal-section__expiration__item",
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "MissionPage.pointExpirationModal.expired",
                                  {
                                    point:
                                      _vm.previousSeasonMissionPointExpirationInfo.expirationPoint.toLocaleString(),
                                    seasonYear:
                                      _vm
                                        .previousSeasonMissionPointExpirationInfo
                                        .grantedSeasonYear,
                                  }
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " (" +
                            _vm._s(
                              _vm.previousSeasonMissionPointExpirationInfo
                                .expirationDate
                            ) +
                            ") "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.thisSeasonMissionPointExpirationInfo
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mission-point-expiration-modal-section__expiration__item",
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "MissionPage.pointExpirationModal.expirationMessage.grantedSeason",
                                  {
                                    seasonYear:
                                      _vm.thisSeasonMissionPointExpirationInfo
                                        .grantedSeasonYear,
                                  }
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "MissionPage.pointExpirationModal.expirationMessage.expiration",
                                {
                                  date: _vm.thisSeasonMissionPointExpirationInfo
                                    .expirationDate,
                                  seasonYear:
                                    _vm.thisSeasonMissionPointExpirationInfo
                                      .grantedSeasonYear,
                                }
                              )
                            ) +
                            " "
                        ),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.thisSeasonMissionPointExpirationInfo.expirationPoint.toLocaleString()
                              ) +
                              "P "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.$tc("common.ok"),
                colorType: "secondary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "links",
        fn: function () {
          return [
            _c(
              "router-link",
              {
                staticClass: "mission-point-expiration-modal-section__link",
                attrs: { to: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onMoveToMypagePointExpirationDatePage.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$tc("MissionPage.pointExpirationModal.detailLinkLabel")
                  )
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }