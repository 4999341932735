import { computed } from '@vue/composition-api'
import { groupBy } from 'lodash'
import dayjs from 'dayjs'
import Logger from '@/util/logger/Logger'
import useLiveTimingAggregationData from '@/store/hook/useLiveTimingAggregationData'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import RankingData, {
  RankingDataType,
} from '@/store/stores/collectionModule/documents/liveTiming/RankingData'
import {
  PlayerLinksData,
  PlayerRankData,
  RankingCardData,
  RankingCardDataTitle,
} from '@/components/RaceListPage/RaceListPane/parts/RankingCardParts.vue'
import RankingRecord from '@/store/stores/collectionModule/documents/liveTiming/RankingRecord'
import { RaceType } from '@/store/stores/collectionModule/documents/liveTiming/types/RaceType'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import useRadioData from '@/store/hook/useRadioData'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'

/**
 * レースのInformation画面のRace Rank機能を提供する。
 */
export default function useRaceRank() {
  const {
    fetchLiveTimingAggregationData,
    clearLiveTimingAggregationData,
    liveTimingAggregationDataList,
    fetchPlayers,
    players,
  } = useLiveTimingAggregationData()

  /** 決勝のレース情報 */
  let targetMainRace: RaceDocument | null
  /** 予選のレース情報 */
  let targetQualifyingRace: RaceDocument | null

  /**
   * 対象の大会の決勝のレース情報を設定する。
   *
   * @param mainRace 決勝のレース情報
   */
  const setMainRace = (mainRace: RaceDocument | null) => {
    targetMainRace = mainRace
  }

  /**
   * 無線交信データのストア
   */
  const radioDataStore = useRadioData(computed(() => targetMainRace))

  /**
   * 対象の大会の予選のレース情報を設定する。
   *
   * @param qualifyingRace 予選のレース情報
   */
  const setQualifyingRace = (qualifyingRace: RaceDocument | null) => {
    targetQualifyingRace = qualifyingRace
  }
  /**
   * 指定した大会のRace Rankデータを取得する。
   * @param championship 大会
   * @param mainRace 決勝のレース情報
   * @param qualifyingRace 予選のレース情報
   */
  const fetchRaceRankData = async (
    championship: ChampionshipDocument,
    mainRace: RaceDocument,
    qualifyingRace: RaceDocument,
  ) => {
    if (!championship.id || !championship.startDate || !championship.endDate) {
      return {
        isSuccess: false,
        response: null,
      }
    }
    // 指定された大会の期間に登録されているランキング集計データを取得する
    // その大会の期間内に実施された予選(Q2)と、決勝のランキングデータが取得される
    const year = dayjs(championship.startDate).format('YYYY')
    const from = dayjs(championship.startDate).startOf('day').valueOf()
    const to = dayjs(championship.endDate).endOf('day').valueOf()

    setMainRace(mainRace)
    setQualifyingRace(qualifyingRace)

    const results = await Promise.all([fetchPlayers(championship.id)])

    // Race Rank の WINNER をマスクするため、無線交信データの取得処理も行わないようにする。
    // WINNER に対応する際は、以下の取得処理に置き換える。

    // const results = await Promise.all([
    //   fetchPlayers(championship.id),
    //   mainRace && mainRace.endDate
    //     ? radioDataStore.fetchRadios(
    //         mainRace,
    //         dayjs(mainRace.scheduleDate).startOf('day').valueOf(),
    //         dayjs(mainRace.endDate).endOf('day').valueOf(),
    //       )
    //     : Promise.resolve({ isSuccess: true }),
    // ])

    const failed = results.find((result) => !result.isSuccess)
    if (failed) {
      Logger.info(`Failed to find relevant data for rank data. result: ${failed}`)
      return failed
    }

    const result2 = await fetchLiveTimingAggregationData(`SuperFormula_${year}`, from, to)
    return result2
  }

  /**
   * 予選・決勝のレースランキングデータ
   */
  const getRaceRankData = computed(() =>
    groupBy(
      liveTimingAggregationDataList.value,
      (liveTimingAggregationData) => liveTimingAggregationData.raceType,
    ),
  )

  /**
   * 予選/決勝がスタートした実時間を取得する。UnixTime(ミリ秒)。
   * 取得できなかった場合、0 を返却する
   */
  const getRaceStartTime = (raceType: RaceType) => {
    const raceLiveTimingAggregationData = getRaceRankData.value?.[raceType]?.[0]
    if (raceLiveTimingAggregationData) {
      return raceLiveTimingAggregationData.raceStartDateTime ?? 0
    }
    return 0
  }

  /**
   * 予選・決勝の指定したランキングのデータを取得する
   *
   * @param raceType レース種別
   * @param rankingDataType ランキング
   */
  const getRanking = (
    raceType: RaceType,
    rankingDataType: RankingDataType,
  ): RankingData | undefined => {
    const liveTimingAggregationData = getRaceRankData.value[raceType]?.[0]
    return liveTimingAggregationData?.rankingDataSet?.find(
      (rankingData) => rankingData.rankingDataType === rankingDataType,
    )
  }

  /**
   * Race Rankカードのタイトルに表示するた文字列を取得する。
   *
   * @param raceType レース種別
   * @param rankingDataType レースランキング種別
   */
  const getRankingName = (
    raceType: RaceType | null,
    rankingDataType: RankingDataType | null,
  ): RankingCardDataTitle | undefined => {
    switch (rankingDataType) {
      // WINNER / POLE POSITION をマスクするため、タイトル取得処理をコメントアウトしている。
      // WINNER / POLE POSITIONに対応する際は、以下のコメントアウトを外すことで、Race Rankにカードが表示される。
      // case 'STANDINGS':
      //   return raceType === 'B' ? 'POLE POSITION' : 'WINNER'
      case 'TOP_SPEED':
        return 'TOP SPEED'
      case 'FASTEST_LAP':
        return 'BEST LAP'
      case 'FASTEST_SECTOR1':
        return 'SECTOR 1 BEST TIME'
      case 'FASTEST_SECTOR2':
        return 'SECTOR 2 BEST TIME'
      case 'FASTEST_SECTOR3':
        return 'SECTOR 3 BEST TIME'
      case 'FASTEST_SECTOR4':
        return 'SECTOR 4 BEST TIME'
      default:
        return undefined
    }
  }

  /**
   * 再生位置へのリンクを生成する際の実際のデータの開始時刻の何秒前から再生するかを指定する
   */
  const startTimeBeforeMargin = 3

  /**
   * ドライバーの各映像の再生位置へのリンク、または、無線再生のためのリンクを生成する。
   *
   * @param raceType レース種別
   * @param rankingDataType レース種別
   * @param targetPlayer ドライバー情報
   * @param rankingRecord ドライバーのランキング記録データ
   */
  const convertToPlayerLinksData = (
    raceType: RaceType,
    rankingDataType: RankingDataType,
    targetPlayer: PlayerDocument,
    rankingRecord: RankingRecord,
  ): PlayerLinksData[] => {
    const targetRace = raceType === 'R' ? targetMainRace : targetQualifyingRace
    if (!targetRace) {
      return []
    }

    if (rankingDataType === 'STANDINGS') {
      // STANDINGSの場合、POLE LAP/ FIRST LAP を再生するためのリンク、または、RADIO のリンクを返す

      // レコードが記録されたラップの周回を開始した時刻
      const startLapTimeAtRecorded = rankingRecord.startLapTimeAtRecorded
        ? rankingRecord.startLapTimeAtRecorded - startTimeBeforeMargin * 1000
        : 0
      // レースの開始時刻
      let raceStartTime = getRaceStartTime(raceType)
      raceStartTime = raceStartTime ? raceStartTime - startTimeBeforeMargin * 1000 : 0

      const playLinks: PlayerLinksData[] = [
        {
          type: 'poleLap',
          link: `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${startLapTimeAtRecorded}/${targetPlayer.playerId}`,
        },
        {
          type: 'firstLap',
          link: `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${raceStartTime}/${targetPlayer.playerId}`,
        },
      ]
      // 対象の無線交信データを検索する
      const targetRadio = rankingRecord.recordedTime
        ? radioDataStore.getPlayerRadioDataClosestTime(
            targetPlayer.getDisplayCarNoNumeric,
            rankingRecord.recordedTime,
          )
        : null

      if (targetRadio) {
        // 決勝の場合のみ無線データを表示する

        // 無線の開始時刻
        const radioStartTime = targetRadio.clip_start_time
          ? targetRadio.clip_start_time - startTimeBeforeMargin * 1000
          : 0
        playLinks.push({
          type: 'radio',
          link: `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${radioStartTime}/${targetPlayer.playerId}`,
        })
      }
      return playLinks
    }

    // それ以外の場合、そのレコードを記録したラップを再生するためのリンクを返す
    return [
      {
        type: 'thisLap',
        link: `/race-video/${targetRace.championshipMasterId}/${targetRace.id}/${rankingRecord.startLapTimeAtRecorded}/${targetPlayer.playerId}`,
      },
    ]
  }

  /**
   * ライブタイミング集計結果のランキングデータ内の各ドライバーのランキング記録データを、
   * Race Rankカード内の各ドライバーのランキングを表現するためのPlayerRankDataに変換する。
   *
   * @param season レースシーズン
   * @param raceType レース種別
   * @param rankingDataType ランキングデータ種別
   * @param rankingRecord ドライバーのランキング記録データ
   */
  const convertToPlayerRankData = (
    season: string,
    raceType: RaceType,
    rankingDataType: RankingDataType,
    rankingRecord: RankingRecord,
  ) => {
    if (!season) {
      return null
    }
    const targetPlayer = players.value.find(
      (player) => player.getDisplayCarNo() === rankingRecord.carNo,
    )
    if (!targetPlayer) {
      return null
    }

    const playerLinksData = convertToPlayerLinksData(
      raceType,
      rankingDataType,
      targetPlayer,
      rankingRecord,
    )

    let valueDispNum = null
    if (rankingRecord.valueDisp) {
      /**
       * 順位比較用のデータ
       * valueDispの数値、小数点以外を削除する
       */
      valueDispNum = parseFloat(rankingRecord.valueDisp.replace(/[^0-9.]/g, ''))
    }

    const playerRankData: PlayerRankData = {
      number: targetPlayer.getDisplayCarNoNumeric,
      name: targetPlayer.getPlayerName(),
      img: CloudFrontUtil.getSignedUrl(targetPlayer.additionalData?.raceRank?.playerImagePath),
      bestData: rankingRecord.valueDisp ?? '---',
      dispRank: null,
      bestDataNum: valueDispNum ?? 99999,
      links: playerLinksData,
    }
    return playerRankData
  }

  /**
   * ライブタイミング集計結果のランキングデータを、Race Rankカードのモデルデータ(RankingCardData)に変換する。
   *
   * @param rankingData ランキングデータ
   */
  const convertToRankingCardData = (rankingData: RankingData) => {
    const rankingDataType = rankingData.rankingDataType ? rankingData.rankingDataType : null
    if (!rankingDataType) {
      return null
    }
    const title = getRankingName(rankingData.raceType, rankingData.rankingDataType)
    if (!title) {
      return null
    }
    const playerData: PlayerRankData[] = []
    rankingData.rankings.forEach((rankingRecord) => {
      if (!rankingData.season || !rankingData.raceType || !rankingRecord.valueDisp) {
        return
      }
      const playerRankData = convertToPlayerRankData(
        rankingData.season,
        rankingData.raceType,
        rankingDataType,
        rankingRecord,
      )
      if (playerRankData) {
        playerData.push(playerRankData)
      } else {
        Logger.info(`Failed to convert ranking record. ${rankingRecord}`)
      }
    })
    return {
      title,
      playerData,
    }
  }

  /**
   * Race Rankカードのモデルデータのリストを取得するための算出プロパティ。
   * ライブタイミング集計結果をRace Rankカードのデータに変換する。
   */
  const getRankingCardData = computed((): RankingCardData[] => {
    const rankingCardDataList: RankingCardData[] = []

    // ポールポジションのRace Rankカード用のRankingCardDataを取得
    const polePosition = getRanking('B', 'STANDINGS')
    if (polePosition && polePosition.season) {
      const rankingCardData = convertToRankingCardData(polePosition)
      if (rankingCardData) {
        rankingCardDataList.push(rankingCardData)
      }
    }
    // 決勝の順位/最高速度/最速ラップタイム/各セクターの最速タイムのRace Rankカード用のRankingCardDataを取得
    getRaceRankData.value?.R?.forEach((liveTimingAggregationData) => {
      liveTimingAggregationData.rankingDataSet?.forEach((rankingData) => {
        if (!liveTimingAggregationData.season) {
          return
        }
        const rankingCardData = convertToRankingCardData(rankingData)
        if (rankingCardData && rankingCardData.playerData.length > 0) {
          rankingCardDataList.push(rankingCardData)
        }
      })
    })
    return rankingCardDataList
  })

  /**
   * 取得したデータをクリアする。
   */
  const clearRankData = () => {
    clearLiveTimingAggregationData()
  }

  return {
    fetchRaceRankData,
    getRanking,
    clearRankData,
    getRankingCardData,
    setMainRace,
    setQualifyingRace,
  }
}
