










































import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import FieldsetPasswordParts from '@/components/common/form/FieldsetPasswordParts.vue'
import FieldsetNameParts from '@/components/common/form/FieldsetNameParts.vue'
import FieldsetBirthdayParts from '@/components/common/form/FieldsetBirthdayParts.vue'
import FieldsetCountryParts from '@/components/common/form/FieldsetCountryParts.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FieldsetCheckboxParts from '@/components/common/form/FieldsetCheckboxParts.vue'

export default defineComponent({
  name: 'AppSignupInputPane',
  components: {
    FieldsetCheckboxParts,
    AtomInputButton,
    ActionButtonsSection,
    FieldsetCountryParts,
    FieldsetBirthdayParts,
    FieldsetNameParts,
    FieldsetPasswordParts,
    FieldsetEmailParts,
    SubHeaderSection,
  },
  setup() {
    return {}
  },
})
