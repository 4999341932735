


































import { defineComponent, inject, onBeforeMount, reactive } from '@vue/composition-api'
import VueRouter from 'vue-router'
import I18n from '@/locales/I18n'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import CurrentCardNumberSection from '@/components/pc/MyPage/PaymentPage/PaymentEditPane/CurrentCardNumberSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import CreditCardInputSection from '@/components/common/payment/CreditCardInputSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useContractInfo from '@/store/hook/useContractInfo'
import { getDeclineInfo } from '@/util/StripeCodeGetter'
import useContractInfoCardRequest from '@/store/hook/useContractInfoCardRequest'
import ContractInfoCreditCardDocument from '@/store/stores/collectionModule/documents/contractInfo/ContractInfoCreditCardDocument'

/**
 * クレジットカード情報変更 ペイン
 */
export default defineComponent({
  name: 'PaymentEditPane',
  components: {
    ActionButtonsSection,
    AtomInputButton,
    CurrentCardNumberSection,
    CreditCardInputSection,
  },
  inject: ['router'],
  setup() {
    const router = inject('router') as VueRouter

    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const { updateContractInfo } = useContractInfo()
    const { fetchContractInfo, fetchTargetContractInfo, ownContractInfo } = useContractInfo()
    const { contractInfoCardRequest } = useContractInfoCardRequest()

    const state = reactive({
      /** 「確認画面へ」ボタン有効化判定 */
      isConfirmBtnDisabled: false,
      /** クレジットカード番号下4桁 */
      cardLast4Digits: '' as string | null | undefined,
      /** クレジットカード入力フォームを再描画するためのkey */
      stripeElementResetKey: 0,
      /** クライアントシークレット */
      clientSecret: null as string | null,
    })

    /**
     * 「確認画面へ」ボタン活性/非活性状態更新
     */
    const updateHasCardNameErrors = (isDisabled: boolean) => {
      state.isConfirmBtnDisabled = isDisabled
    }

    /**
     * fetchTargetContractInfoでクレジットカードの下四桁が取れる
     * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/828473474/API
     */
    onBeforeMount(async () => {
      await fetchContractInfo()
      await fetchTargetContractInfo()
      state.cardLast4Digits = ownContractInfo.value.payment?.paymentService?._cardLast4Digits
      // クライアントシークレットを取得
      state.clientSecret = (await contractInfoCardRequest()).data?.clientSecret || null
      if (!state.clientSecret) {
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.title'),
          message: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.message'),
        })
        await router.replace({ name: 'MypageTopPage' })
      }
    })

    return {
      state,
      stripe: signupPageStore.stripe,
      ownContractInfo,
      contractInfoCardRequest,
      updateHasCardNameErrors,
      updateContractInfo,
      updateCreditCard: signupPageStore.updateCreditCard,
    }
  },
  methods: {
    /**
     * クレジットカード入力フォームを再描画するためのkeyを変更
     */
    addStripeElementResetKey() {
      this.state.stripeElementResetKey += 1
    },
    /**
     * クレジットカード情報入力フォーム初期化
     */
    async initCreditCardInputForm() {
      // 一度検証（confirmCardSetup）した後、確認画面に遷移しなかったとしても再検証できるよう、新しいクライアントシークレットを取得する（基本的には確認画面へ遷移する想定）
      this.state.clientSecret = (await this.contractInfoCardRequest()).data?.clientSecret || null
      if (!this.state.clientSecret) {
        await MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.title'),
          message: I18n.tc('SignupPage.SelectPlanPage.errors.failureGetClientSecret.message'),
        })
        await this.$router.replace({ name: 'MypageTopPage' })
      }

      /** クレジットカード入力フォームを再描画するためのkey変更 */
      this.addStripeElementResetKey()
    },
    /**
     * 実行している処理
     * （１）支払い方法の取得
     * （２）契約情報の更新
     */
    async execUpdateContractInfo() {
      const loader = this.$loading.show()
      // （１）クレジットカードの検証を行い、支払い方法を取得する
      const { paymentMethod, error } = await this.stripe.getPaymentMethod()

      if (error) {
        loader.hide()
        // Stripeからエラーが返ってくる場合は、Stripeのエラーメッセージを表示する
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.title'),
          message: error?.message,
        })

        // クレジットカード情報を再入力してもらうため、クレジットカード入力フォームを初期化する
        await this.initCreditCardInputForm()
        return
      }

      if (
        !(
          (typeof paymentMethod === 'object' && typeof paymentMethod?.id === 'string') ||
          typeof paymentMethod === 'string'
        )
      ) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.title'),
          message: this.$tc('SignupPage.SelectPlanPage.errors.failureGetPaymentMethod.message'),
        })

        // クレジットカード情報を再入力してもらうため、クレジットカード入力フォームを初期化する
        await this.initCreditCardInputForm()
        return
      }

      // （２）クレジットカード情報を更新
      const updateCreditCardResult = await this.updateCreditCard(
        new ContractInfoCreditCardDocument({
          contractInfoId: this.ownContractInfo.contractInfoId ?? '',
          paymentMethodId: typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id,
        }),
      )
      loader.hide()
      if (!updateCreditCardResult.isSuccess) {
        switch (updateCreditCardResult.response?.data.error_code) {
          case '400_0055':
            // クレジットカード決済エラーの場合
            if (updateCreditCardResult.response?.data.payment_service?.decline_code) {
              const declineCodeResult = getDeclineInfo(
                updateCreditCardResult.response?.data.payment_service.decline_code,
              )
              await MessageDialogStore.value.open({
                title: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.title'),
                message: I18n.tc('SignupPage.RegistrationPage.errors.createStripeError.message'),
                declineCode: updateCreditCardResult.response?.data.payment_service.decline_code,
                description: declineCodeResult?.description || '',
                nextUserAction: I18n.tc(
                  'SignupPage.RegistrationPage.errors.createStripeError.nextUserAction',
                ),
              })
            } else {
              // payment_service、decline_codeが返却されない場合の考慮も一応入れておく
              await MessageDialogStore.value.open({
                title: I18n.tc('SignupPage.RegistrationPage.errors.createCreditCardError.title'),
                message: I18n.tc(
                  'SignupPage.RegistrationPage.errors.createCreditCardError.message',
                ),
              })
            }
            break
          default:
            await MessageDialogStore.value.open({
              title: I18n.tc('SignupPage.RegistrationPage.errors.createCreditCardError.title'),
              message: I18n.tc('SignupPage.RegistrationPage.errors.createCreditCardError.message'),
            })
            break
        }

        // クレジットカード情報を再入力してもらうため、クレジットカード入力フォームを初期化する
        await this.initCreditCardInputForm()
        return
      }
      await this.$router.replace({ name: 'PaymentCompletePage' })
    },
    /**
     * 「登録する」押下時の処理
     */
    async handlerSubmit() {
      await this.execUpdateContractInfo()
    },
    /**
     * 「キャンセル」押下時の処理
     */
    handlerCancel() {
      this.$router.replace({ name: 'MypageTopPage' })
    },
  },
})
