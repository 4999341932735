

























import { defineComponent } from '@vue/composition-api'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'

export default defineComponent({
  name: 'ServiceButtonListParts',
  components: { AtomIconRouterButton },
})
